import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-chart-with-circle',
  templateUrl: './chart-with-circle.component.html',
  styleUrls: ['./chart-with-circle.component.scss']
})
export class ChartWithCircleComponent {
  chart: any;
  canvas : any;
  @Input() id:any;
  @Input() Title:any;
  
  @Input() data:any = {};
  circleValues:any = {
    tina:0,
    manual:0,
    whatsapp:0
  }
  ngAfterViewInit(): void {


    const canvasd = <HTMLCanvasElement> document.getElementById(this.id);
    const ctx2:any = canvasd.getContext('2d');

    let chartStatus = Chart.getChart(this.id); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    const gradient = ctx2.createLinearGradient(0, 0, 0, 240);
    gradient.addColorStop(1, "rgba(18 50 63 / 15%)");
    gradient.addColorStop(0, "rgba(18 50 63 / 52%)");

    const gradient2 = ctx2.createLinearGradient(0, 0, 0, 240);
    gradient2.addColorStop(1, "rgba(10 105 135 / 15%)");
    gradient2.addColorStop(0, "rgba(10 105 135 / 52%)");
    var months = [];
    var valuesTina:any = [];
    var WhatsappValue:any = [];
    var ManualValue:any = [];
    var totalAppointmentsTina = 0;
    var totalAppointmentsWhatsapp = 0;
    var totalAppointmentsManuel = 0;
    if(this.data)
    {
      
   
      for (var key in this.sortData(this.data['tina'])) {
          months.push(key);
          valuesTina.push(this.data['tina'][key])
          totalAppointmentsTina = totalAppointmentsTina + parseInt(this.data['tina'][key]);
      }
      let i = 0;
      for (var key in this.sortData(this.data['whatsapp'])) {
          WhatsappValue.push(this.data['whatsapp'][key])
          totalAppointmentsWhatsapp = totalAppointmentsWhatsapp + parseInt(this.data['whatsapp'][key]);
      }

      for (var key in this.sortData(this.data['manual'])) {
        //let month = this.monthArray[key];
        ManualValue.push(this.data['manual'][key])
        totalAppointmentsManuel = totalAppointmentsManuel + parseInt(this.data['manual'][key]);
      }
   
     let finalCount = totalAppointmentsManuel + totalAppointmentsTina + totalAppointmentsWhatsapp;
      this.circleValues.tina = (totalAppointmentsTina / finalCount) * 100
      //this.circleValues.tina = 34
      this.circleValues.whatsapp = (totalAppointmentsWhatsapp / finalCount) * 100
     // this.circleValues.whatsapp = 15
      this.circleValues.manual = (totalAppointmentsManuel / finalCount) * 100
     //this.circleValues.manual = 60

      this.chart = new Chart(ctx2, {
        type: 'line', //this denotes tha type of chart
  
        data: {// values on X-Axis
          labels: months,
          
           datasets: [
            {
              label:"Tina",
              data:valuesTina,
              pointRadius: 1,
             // tension:0.5,
              borderColor:"#0099FF",
              borderWidth:1,
              backgroundColor:gradient,
             // fill: true
            },
            {
              label:"Whatsapp",
              data:WhatsappValue,
              pointRadius: 1,
           // tension:0.5,
              borderColor:"#77DC75",
              borderWidth:1,
              backgroundColor:gradient2,
              // fill: true
            },
            {
              label:"Manual",
              data:ManualValue,
              pointRadius: 1,
              // tension:0.5,
              borderColor:"#F0950C",
              borderWidth:1,
              backgroundColor:gradient2,
              // fill: true
            }
         
            
          
          ]
        },
        options: {
     
          plugins: {
            filler: {
              propagate: false
            },
            legend: {
                display: false
            },
        },
          aspectRatio:4.5,
          scales: {
            y: {
              grid: {
                display:true,
              },
              stacked: true
            },
            x: {
              grid: {
                display:true,
              }
            }
          },
          interaction: {
            intersect: false,
          },
        }
        
      });
    }


   

  


  }

  sortData(object:Object)
  {
    const monthsOrder = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const sortedData = Object.fromEntries(
      Object.entries(object)
        .sort(([monthA], [monthB]) => monthsOrder.indexOf(monthA) - monthsOrder.indexOf(monthB))
    );
    return sortedData
  }
  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
