import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OpdchartService } from 'src/app/private/services/opdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import db from '../../../../../db/medicine_db.json';
import { Subject, debounceTime } from 'rxjs';
import Prescription from 'src/app/utils/printLayout/GeneratePrescription';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-medications',
  templateUrl: './medications.component.html',
  styleUrls: ['./medications.component.scss']
})
export class MedicationsComponent {

  @Input() app_data:any
  @Input() patient:any
  @Input() titleOfHead: any = {
    title: 'OPD',
    root: 'Medications'
  };
  id:any;
  medicineDb:any = [];
  btnSaveLoading:boolean = false;
  saveToTemplate:boolean = false;
  medicationListData:any = []
  count:any = 0;
  drugList:any = [];
  tempDrugList:any = []
  templateDugList:any = [];
  templateName:any = ""
  templateSaveButton:boolean =false
  filteredMedicines:any = []
  tempMedicine:any = [];
  searchLoading = false;
  printButtonLoading:boolean = false;
  printPrescriptionObject:any;
  drugSearch = new Subject<string>();
  print_option:any = ['primary_checkups','medications'];
  diagnosisTemplateList:any = []
  currentTab:any;
  templateDiagnosis:any = [];
  loading:boolean = false;
  activeIndex: number[] = [];

  medicineArrayObject:any = {
    diagnosis:[],
  }
  medicineObject:any = {
    drug_type:"",
    drug_name:"",
    duration_count:"1",
    quantity:"1",
    duration:"day(s)",
    frequency:"1-0-1",
  }
  layoutLoading:boolean = false;
  current_app_id:any;
  current_patient_id:any
  selectOrgan:any = "";
  tempName:any = "";
  showDiagnosisPopup:boolean = false;
  constructor(public route:ActivatedRoute,private opdChartAPI:OpdchartService, private toast:ToastrService, public dialog: MatDialog,private modalService: NgbModal,private api:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.current_app_id = params['app_id']
      })
   this.medicineDb = db;

  this.drugSearch.pipe(
    debounceTime(300)
).subscribe(value => {
  if(typeof value == "string")
  {
    //console.log(value.toLowerCase())
    this.filteredMedicines =  this.tempMedicine.filter(
      (item:any) => item.medicine_name.toLowerCase().includes(value.toLowerCase())
    )
  }
 

})

  }

 
  ngAfterViewInit()
{
  this.getPatientMedicationData();
}
addDiagnosis(item:any,organ:any,index:any)
{

  let existingArray = []
  let value:any = organ+" "+item
  this.tempName += item+",";
  this.templateName = this.removeLastComma(this.tempName);
  this.templateDiagnosis.push(item);
   existingArray = this.medicineArrayObject.diagnosis || [];
   this.medicineArrayObject.diagnosis = existingArray.concat(value);

   console.log( this.medicineArrayObject.diagnosis)
  //  this.showDiagnosisPopup = false;
   this.getDiagnosisMedicineTemplate();

   if(index!=-1){
     this.activeIndex.push(index)
   }
this.closeTemplate()
   console.log(this.activeIndex)

}



// addDiagnosisCheck(event:any, item:any,organ:any, index:any)
// {
//   console.log(event.target.checked)
//   if(event.target.checked){
//     console.log(true)
//     this.addDiagnosis(item,organ)
//     this.activeIndex.push(index)
//   }else{    
//     this.medicineArrayObject.diagnosis.splice(index, 1)
//     this.activeIndex.splice(index, 1)
//     console.log( this.medicineArrayObject.diagnosis)
//   }

// }

// removeTag(): void {
//   this.medicineArrayObject.diagnosis.splice(-1);
//   this.templateDiagnosis.splice(-1)
//   this.templateName = "";
//   this.templateDiagnosis.forEach((element:any) => {
//     this.tempName += element+",";
//   });
//   this.templateName = this.removeLastComma(this.tempName);
// }


removeLastComma(str:any) {
  return str.replace(/,\s*$/, "");
}
openDiagnosisTemplate()
{

  this.showDiagnosisPopup = true;
  this.getDiagnosisTemplate()
}
closeTemplate()
{
  this.showDiagnosisPopup = false;
}
selectOrganFn(event:any)
{
  this.selectOrgan = event.target.value;
}
onKeyUp(event: any): void {
  const inputValue: string = event.target.value;
  if (event.code === 'Backspace' && !inputValue) {
    this.removeTag(-1);
    return;
  } else {
    if (event.code === 'Comma' || event.code === 'Enter') {
      this.addDiagnosis(inputValue,"",-1);
      event.target.value = ""
    }
  }
}
removeTag(index:any): void {
  this.medicineArrayObject.diagnosis.splice(-1);
  this.templateDiagnosis.splice(-1)
  this.templateName = "";
  this.templateDiagnosis.forEach((element:any) => {
    this.tempName += element+",";
  });
  this.templateName = this.removeLastComma(this.tempName);

  if(index!=-1){
    this.activeIndex.splice(index, 1)
  }
}

  displayWith(value: any) {
    return value?.medicine_name;
  }

  OpenNewMedication(content:any)
  {
    this.getMedicationTemplate()
    this.modalService.open(content,  {  size:'xl', scrollable: false,ariaLabelledBy: 'modal-basic-title' });

  }
  clearData()
  {
    this.modalService.dismissAll();
    this.medicineObject = {
      drug_type:"",
      drug_name:"",
      quantity:"1",
      duration_count:"1",
      duration:"day(s)",
      frequency:"1-0-1",
    }
    this.saveToTemplate = false;
  }
  fnOnDrugSearch(event:any)
  {
    this.drugSearch.next(event);
  }
  copyTemplate(item:any)
  {
    this.drugList =  [...this.drugList , ...item.data] ;
  
  }
  addDrug()
  {

  if(this.medicineObject.drug_type=="")
  {
   this.toast.error("Select Drug Type")
  }
  else if(this.medicineObject.drug_name=="")
  {
   this.toast.error("Select Drug Name")
  }
  else
  {
    console.log(this.medicineObject.drug_name)
    if(typeof this.medicineObject.drug_name == 'string'){
      this.medicineObject.drug_name = {medicine_name: this.medicineObject.drug_name,composition: ""}}

      if(this.medicineObject.drug_type==="CAPSULE" || this.medicineObject.drug_type==="TABLET" || this.medicineObject.drug_type==="INJECTION")
      {

      let repetition = (this.medicineObject.frequency.match(/1/g) || []).length;
      let days = this.countOccurrences(this.medicineObject.duration)
      let qty = repetition * days * this.medicineObject.duration_count;
      this.medicineObject.quantity = qty;
      }
  

      console.log(this.medicineObject)
    this.drugList.push(this.medicineObject)
    this.medicineObject = {
     drug_type:this.medicineObject.drug_type,
     drug_name:"",
     quantity:"1",
     duration_count:"1",
     duration:"day(s)",
     frequency:"1-0-1",
   }
  }
 
  }
   countOccurrences(str:any) {
    let count = 0;
    if(str === "day(s)"){
      count = 1
    }
    else if(str === "weeks(s)"){
      count = 7
    }
    else if(str === "month(s)"){
      count = moment().daysInMonth()
    }
    return count;
  }
  FilterByDrugType(obj:any)
{
  let value = obj.target.value;
  this.filteredMedicines = [];
  this.filteredMedicines = this.medicineDb.filter(
    (item:any) => item.medicine_name.toLowerCase().match(value.toLowerCase())
  );
  this.tempMedicine = [];
  this.tempMedicine =  this.filteredMedicines;
 
}

  removeDrug(i:any)
  {
    this.drugList.splice(i, 1)
    
  }
  saveMedication()
  {
    if(this.id)
    {
     this.btnSaveLoading = true;
     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       id:this.id,
       medications:this.drugList,
       diagnosis:this.medicineArrayObject.diagnosis
       }
 
 
      this.opdChartAPI.updateOpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnSaveLoading = false;
           this.toast.success("Data Saved successfully...")
           this.getPatientMedicationData();
           if(this.saveToTemplate)
           {
            this.SaveMedicationTemplate();
           }
        
          this.clearData()
         }
       },error:(e)=>{
         this.btnSaveLoading = false;
       }
      })
    }
    else{
      this.btnSaveLoading = true;
     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       patient_id:this.app_data.id,
       app_id:this.app_data.appointment.app_id,
       medications:this.drugList,
       diagnosis:this.medicineArrayObject.diagnosis
       }
       
   
      this.opdChartAPI.createOpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnSaveLoading = false;
           this.toast.success("Data Saved successfully...")
           this.clearData()
           this.getPatientMedicationData();
           this.SaveMedicationTemplate();
         
         }
       },error:(e)=>{
         this.btnSaveLoading = false;
       }
      })
    }
   
  }
  getMedicationTemplate()
  {
    let param = {
      where:{
      specialty: this.localStorage.getSpecialty(),
      }
    }
    this.opdChartAPI.getMedicationTemplate(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.templateDugList = res;
        }
      },
      error:(e)=>{

      }
     })
  }
  SaveMedicationTemplate()
  {
     if(this.templateName)
     {
      let param = {
        title:this.templateName,
        data:this.drugList,
        diagnosis:this.templateDiagnosis,
        hospital_id:this.localStorage.getHospitalId(),
        specialty: this.localStorage.getSpecialty(),
      }
      this.templateSaveButton = true;
         this.opdChartAPI.createMedicationTemplate(param).subscribe({
          next:(res)=>{
            if(res)
            {
              //this.toast.success("Template Added Successfully")
              this.templateSaveButton = false;
              //this.  getMedicationTemplate()
            }
          },
          error:(e)=>{
            this.templateSaveButton = false;
          }
         })
     }
     else
     {
      //this.toast.error("Template should not blank")
     }

  }

  getDiagnosisTemplate()
  {

    let param = {
      where:{
        specialty:this.localStorage.getSpecialty()
      }
    }
    this.api.getDiagnosisTemplate(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.diagnosisTemplateList = res;
        }
      },
      error:(e)=>{

      }
    })
  }

  getPatientMedicationData()
  {
  // console.log(this.app_data)
  this.loading = true;
   let param = {
     where:{
    hospital_id:this.localStorage.getHospitalId(),
     center_id:this.localStorage.getCenterId(),
     patient_id:this.current_patient_id,
     },
     attributes:['id','app_id','hospital_id','patient_id','center_id','medications','diagnosis','createdAt']
   }
   this.opdChartAPI.getAllOpdChartData(param).subscribe({
     next:(res)=>{
      if(res)
      {
        this.count = res.filter((item:any)=>{return item.medications}).length;
    
     
        this.medicationListData = res.filter((item:any)=>{return item.app_id != this.app_data.appointment.app_id && item.medications });
        let existingObject  = res.filter((item:any)=>{return item.app_id == this.app_data.appointment.app_id })[0]
        if(existingObject!=undefined)
        {
          this.id =  existingObject.id || undefined;
          this.medicineArrayObject.diagnosis = existingObject.diagnosis || [] 
          if(existingObject.medications){
  
          this.currentTab = existingObject;
          }
        }

        this.loading = false;
        this.layoutLoading = true;
      
      }
  
     },
     error:(e)=>{
      this.loading = false;
     }
   })
  }
  getDiagnosisMedicineTemplate()
  {
    let param = {
      hospital_id:this.localStorage.getHospitalId(),
      diagnosis:this.templateDiagnosis
    }
    this.api.getMedicinesDiagnosisTemplate(param).subscribe({
      next:(res) =>
      {
        if(res)
        {
          this.drugList = res.data;
        }
      }
    })
  }
  editMedication(content:any,item:any)
  {
    this.getMedicationTemplate()
    this.modalService.open(content,  {  size:'xl', centered:true, scrollable: false,ariaLabelledBy: 'modal-basic-title' });
    this.drugList = item; 
  }
  createPrescription(prescription:any,item:any)
  {
    this.modalService.open(prescription,  {  size:'lg', scrollable: false,ariaLabelledBy: 'modal-basic-title' });
    this.printPrescriptionObject = item;
  }
   addPrintOption(event:any){
    this.print_option.push(event.target.value)
  }
  printPrescription(){
   // console.log(this.print_option)
    let param = {
      print_option:this.print_option,
      patientInfo:this.app_data,
      prescription:this.printPrescriptionObject,
    }
  //  console.log(param)
    Prescription(param)
  }
}
