import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';
import { MasterService } from 'src/app/private/services/master.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-search-patient',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.scss']
})
export class SearchPatientComponent {
  searchQuery:any;
  showResult:boolean = false;
  searchResult:any = [];
  loading:boolean = false;
  noResult:boolean = false;
  @Input() PLACEHOLDER:any = "Search Patient";
  currentIndex = 0;
  @Output("searchResultRecord") searchResultRecord: EventEmitter<any> = new EventEmitter();

  txtQueryChanged: Subject<string> = new Subject<string>();
  constructor(private searchApi:MasterService, private localStorage:LocalStorageService)
  {
     this.txtQueryChanged.pipe(debounceTime(1000)).subscribe(query=>{
       // console.log(query)


        let param = {
          where:{
                hospital_id:this.localStorage.getHospitalId(),
                center_id:this.localStorage.getCenterId(),
          },
          filter:{
            'fname':query,
            'lname':query,
            'mobile':query,
            'email':query,
            'pId':query,
            'old_patient_record_number':query,
          }
        }
        if(query)
        {
          this.searchApi.getPatientBySearch(param).subscribe({
            next:(res)=>{
                 if(res && res.length > 0){
                  this.searchResult = res;
                  this.showResult = true;
                  this.noResult = false;
                  this.loading = false;
                 }else
                 {
                  this.searchResult = []
                  this.noResult = true;
                  this.loading = false;
                  setTimeout(() => {
                    this.noResult = false;
                    this.showResult = false
                  }, 1000);
                 }
            },
            error:(e)=>{
              this.noResult = true;
              this.loading = false;
              setTimeout(() => {
                this.noResult = false;
                this.showResult = false
              }, 1000);
            }
          })

        }
        else
        {
          this.searchResult = []
          this.noResult = true;
          this.loading = false;
          setTimeout(() => {
            this.noResult = false;
            this.showResult = false
          }, 1000);
        }

     })
  }
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp':
        this.moveSelection(-1);
        break;
      case 'ArrowDown':
        this.moveSelection(1);
        break;
        case 'Enter':
        this.handleEnterKey();
        break;
    }
  }
  handleEnterKey(): void {
    // Perform your action here
    this.searchResultRecord.emit(this.searchResult[this.currentIndex])
    this.showResult = false;
    this.searchQuery = "";
  }
  moveSelection(step: number): void {
    const newIndex = this.currentIndex + step;

    if (newIndex >= 0 && newIndex < this.searchResult.length) {
      this.currentIndex = newIndex;
    }
  }
  searchRecord(query:any){
    this.loading = true;
    this.txtQueryChanged.next(query);
  }
  selectRecord(item:any){
   this.searchResultRecord.emit(item)
   this.showResult = false;
   this.searchQuery = "";
  }
  onKeyDown(event:any,i:any)
  {
    console.log(event)
  }

}
