import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OpdchartService } from 'src/app/private/services/opdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-primary-checkup',
  templateUrl: './primary-checkup.component.html',
  styleUrls: ['./primary-checkup.component.scss']
})
export class PrimaryCheckupComponent {
  @Input() titleOfHead: any = {
    title: 'OPD',
    root: 'Primary checkups'
  };
  loading:boolean = false;
  currentDate = new Date();
  primary_checkups:any = [];
  templateData:any;
  selectedValues:any = [];
  selectedCheckup:any;
  valueHoldArray:any = [];
  newTemplate:any = "";
  id:any;
  selectedDialogStatus:any = 0;
  selectedDialog:any = {title:'',eye:'',range:'',duration:''};
  btnSaveLoading:boolean = false;
  checkupListData:any = [];
  count:any = 0
  action:any = "";
  currentTab:any;
  getCheckupData:any = {
    primary_checkups:{}
  };
  layoutLoading:boolean = false;
  @Input() patient:any;
  @Input() app_data:any
  @ViewChild('tagInput')
  tagInputRef!: ElementRef;
  current_app_id:any;
  current_patient_id:any
  currentSelectedValue:any;
  constructor(public route:ActivatedRoute,private opdChartAPI:OpdchartService, private toast:ToastrService, public dialog: MatDialog,private modalService: NgbModal,private api:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.current_app_id = params['app_id']
      })
  }
  openDialog(data:any): void {
    this.selectedDialog.title = data
    this.selectedDialogStatus = 1;
    console.log(data)
    this.currentSelectedValue = data;
    //alert(this.selectedDialogStatus)
  }
  ngAfterViewInit()
  {
    this.getAllPrimaryCheckups();
  }
  addNewTemplate()
  {
   console.log(this.selectedCheckup)
    if(this.newTemplate)
    {
      this.selectedCheckup.data.push(this.newTemplate)
      this.newTemplate = ""

      let param = {
        id: this.selectedCheckup.id,
        data: this.selectedCheckup.data
      }
      this.api.updatePrimaryCheckupTemplate(param).subscribe({
        next:(res)=>{
          if(res)
          {
            this.toast.success("Templated added successfully")
          }
        },
        error:(e)=>{
        }
      })
    }
    else
    {
     this.toast.error("Invalid template data")
    }
  }

  removeTag(slug: string): void {
    this.getCheckupData.primary_checkups[slug].splice(-1);
  }
  focusTagInput(): void {
   // this.tagInputRef.nativeElement.focus();
  }
  onKeyUp(event: any,slug:any): void {
    const inputValue: string = event.target.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag(slug);
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Enter') {
        this.addTag(inputValue,slug);
        event.target.value = ""
      }
    }
  }
  addTag(tag: string,slug:any): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0) {
     // this.selectedValues.push(tag);
     let existingArray = []
     let value:any = this.selectedDialog.eye+" "+tag+" "+this.selectedDialog.range+" "+this.selectedDialog.duration
      existingArray = this.getCheckupData.primary_checkups[slug] || [];
      this.getCheckupData.primary_checkups[slug] = existingArray.concat(value);
      this.selectedDialog  = {title:'',eye:'',range:'',duration:''};
      this.selectedDialogStatus = 0
    }
    this.currentSelectedValue = ""

  }
   removeDuplicates(arr:any) {
    return arr.filter(((item:any,index:Number) => arr.indexOf(item) === index));
}

 getAllPrimaryCheckups()
 {
  //console.log(this.app_data)

  let param = {
    where:{hospital_id:this.localStorage.getHospitalId(),
    center_id:this.localStorage.getCenterId(),
    patient_id:this.current_patient_id,
    }
    ,
    attributes:['id','app_id','hospital_id','patient_id','center_id','primary_checkups','createdAt']
  }
  this.loading = true;
  this.opdChartAPI.getAllOpdChartData(param).subscribe({
    next:(res)=>{
      this.count =  res.filter((item:any)=>{return  item.primary_checkups}).length
      this.checkupListData = res.filter((item:any)=>{return item.app_id != this.app_data.appointment.app_id &&  item.primary_checkups})
      let existingObject  = res.filter((item:any)=>{return item.app_id == this.app_data.appointment.app_id})[0]
       if(existingObject!=undefined)
        {
          this.id =  existingObject.id || undefined;
          if(existingObject.primary_checkups){
            this.currentTab = existingObject;
            // this.currentTab.createdAt = existingObject.createdAt
          }
        }
        this.layoutLoading = true
        this.loading = false;

      //console.log(this.currentTab)
    },
    error:(e)=>{
      this.layoutLoading = true
      this.loading = false;

    }
  })
 }
 replaceWithSpace(value:any)
 {
  return value.replaceAll("_"," ")
 }

 checkIfExist(id:any)
 {
  return this.checkupListData.filter((item:any)=>{ return (item.app_id == id) ? true : false})
 }
  savePrimaryCheckup()
  {

   //console.log(this.getCheckupData['primary_checkups'])
    if(this.getCheckupData['primary_checkups'])
    {
      this.btnSaveLoading = true;
      let param = {
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        patient_id:this.app_data.id,
        app_id:this.app_data.appointment.app_id,
        opd_number:this.app_data.appointment.opd_number,
        primary_checkups:this.getCheckupData['primary_checkups']
        }
      //  console.log(this.getCheckupData['primary_checkups'])
      // console.log(param)
      if(this.id == undefined)
      {
      //  alert(this.action)
        this.opdChartAPI.createOpdChartData(param).subscribe({
          next:(res)=>{
                if(res)
                {
                  this.toast.success("Primary checkup detail added successfully")
                  this.btnSaveLoading = false;
                  this.modalService.dismissAll();
                  this.getAllPrimaryCheckups();
                  this.getCheckupData =  {
                    primary_checkups:{}
                  };

                }
          },
          error:(e)=>{
            this.btnSaveLoading = false;
          }
        })
      }
      if(this.id != undefined)
      {
        param = {...param,...{id: this.id}};
        //alert(this.action)
        this.opdChartAPI.updateOpdChartData(param).subscribe({
          next:(res)=>{
                if(res)
                {
                  this.toast.success("Primary checkup detail Updated successfully")
                  this.btnSaveLoading = false;
                  this.modalService.dismissAll();
                  this.getAllPrimaryCheckups();
                  this.getCheckupData =  {
                    primary_checkups:{}
                  };
                  this.action = ""

                }
          },
          error:(e)=>{
            this.btnSaveLoading = false;
          }
        })
      }


    }
    else
    {
      this.toast.error("Add at least one checkup detail")
    }


  }
  loadPrimaryCheckupTemplate()
  {
    let data = {
      where: {
        specialty: this.localStorage.getSpecialty(),
      }
    }
   this.api.getAllPrimaryCheckupTemplate(data).subscribe({
    next:(res)=>{
      this.templateData = res;
      this.selectedCheckup = {id:res[0].id,slug:res[0].slug,data:res[0].data || []}
    },
    error:(e)=>{

    }
   })
  }
  setTemplateData(item:any)
  {
    this.valueHoldArray = [];
    this.selectedCheckup = {id:item.id,slug:item.slug,data:item.data || []}
  }
  getDataFromTemplate()
  {
    return this.templateData.filter((item:any)=>{  if(item.slug == this.selectedCheckup.slug) { return item}})
  }
  OpenNewCheckup(content:any)
  {
    this.modalService.open(content,  {  size:'xl',centered: true, scrollable: true,ariaLabelledBy: 'modal-basic-title' });
    this.loadPrimaryCheckupTemplate()
    this.action = "add";
  }
  editCheckup(content:any,item:any)
  {
    this.loading = true;
    this.action = "edit";
    this.modalService.open(content,  {  size:'xl', centered: true, scrollable: true,ariaLabelledBy: 'modal-basic-title' });
    this.loadPrimaryCheckupTemplate()
    this.id = this.id;
    this.getCheckupData['primary_checkups'] = item;
    this.loading = false;
  }
}
