import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private http: HttpClient) { }

  getDepartments(data:any): Observable<any> {
    return this.http.post(environment.getAllDepartment,data);
  }

  getUsersByDepartments(data:any): Observable<any> {
    return this.http.post(environment.getUserByDepartment,data);
  }
  getAllSchedule(data:any): Observable<any> {
    return this.http.post(environment.getAllSchedule,data);
  }

  createSchedule(data:any): Observable<any> {
    return this.http.post(environment.createSchedule,data);
  }
  bulkSchedule(data:any): Observable<any> {
    return this.http.post(environment.bulkSchedule,data);
  }

  deleteSchedule(id:any): Observable<any> {
    return this.http.delete(environment.deleteSchedule+"/"+id);
  }
  updateSchedule(data:any): Observable<any> {
    return this.http.post(environment.updateSchedule,data);
  }

}
