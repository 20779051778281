import { Injectable } from '@angular/core';
import jwt_decode  from 'jwt-decode';
import { AppCookieService } from 'src/app/utils/AppCookieService';
@Injectable({
  providedIn: 'root'
})
export class JWTTokenServiceService {

  jwtToken: any;
  token:any;
  decodedToken:any = {};

  constructor(private cookies:AppCookieService) {
  }

  getToken()
  {
    // this.token = localStorage.getItem("token")
   this.token = this.cookies.get("token")
    return this.token;
  }

  setToken(token: string) {
   // localStorage.setItem("token",token);
    this.cookies.set("token",token)

  }

  decodeToken() {
    this.jwtToken =  this.cookies.get("token");
    if (this.jwtToken) {
    this.decodedToken = jwt_decode(this.jwtToken);
    }
  }

  getDecodeToken() {
    this.jwtToken =  this.cookies.get("token");
    return jwt_decode(this.jwtToken);
  }

  getUser() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.displayname : null;
  }

  getEmailId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.email : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  isTokenExpired(): boolean {
    const expiryTime: number = this.getExpiryTime();
   // console.log(expiryTime)
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return false;
    }
  }

  
}
