import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OpdchartService } from 'src/app/private/services/opdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { Subject, debounceTime } from 'rxjs';
import Prescription from 'src/app/utils/printLayout/GeneratePrescription';
import { ActivatedRoute } from '@angular/router';
import { PrescriptionService } from 'src/app/utils/printService/prescription.service';

@Component({
  selector: 'app-prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.scss']
})
export class PrescriptionsComponent {
  @Input() app_data:any
  id:any;
  @Input() titleOfHead: any = {
    title: 'OPD',
    root: 'Prescriptions'
  };
  @Input() patient:any
  medicineDb:any = [];
  btnSaveLoading:boolean = false;
  btnShareLoading:boolean = false;
  medicationListData:any = []
  count:any = 0;
  drugList:any = [];
  tempDrugList:any = []
  templateDugList:any = [];
  templateName:any = ""
  templateSaveButton:boolean =false
  filteredMedicines:any = []
  tempMedicine:any = [];
  searchLoading = false;
  printButtonLoading:boolean = false;
  printPrescriptionObject:any;
  drugSearch = new Subject<string>();
  print_option:any = ['primary_checkups','medications'];

  currentTab:any;
  medicineObject = {
    drug_type:"",
    drug_name:"",
    duration_count:"1",
    quantity:"1",
    duration:"day(s)",
    frequency:"1-0-1",
  }
  current_app_id:any;
  current_patient_id:any
  constructor(public printPrescriptionService:PrescriptionService, public route:ActivatedRoute,private opdChartAPI:OpdchartService, private toast:ToastrService, public dialog: MatDialog,private modalService: NgbModal,private api:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.current_app_id = params['app_id']
      })
  }

 
  ngAfterViewInit()
{
  this.getPatientMedicationData();
}

getPatientMedicationData()
{
// console.log(this.app_data)
 let param = {
   where:{
  hospital_id:this.localStorage.getHospitalId(),
   center_id:this.localStorage.getCenterId(),
   patient_id:this.current_patient_id,
   },
 }
 this.opdChartAPI.getAllOpdChartData(param).subscribe({
   next:(res)=>{
    if(res)
    {
      this.count = res.filter((item:any)=>{return item.medications}).length;
      this.medicationListData = res.filter((item:any)=>{return item.app_id != this.app_data.appointment.app_id && item.medications });
      let existingObject  = res.filter((item:any)=>{return item.app_id == this.app_data.appointment.app_id })[0]
      if(existingObject!=undefined)
      {
        this.id =  existingObject.id || undefined;
        if(existingObject.medications){
        this.currentTab = existingObject;
        }
      }
   
    
    }

   },
   error:(e)=>{

   }
 })
}
  
  createPrescription(prescription:any,item:any)
  {
    this.modalService.open(prescription,  {  size:'xl', scrollable: false,ariaLabelledBy: 'modal-basic-title' });
    this.printPrescriptionObject = item;
    setTimeout(() => {
      this.viewPrescription();
    }, 1000);
   
  }
   async addPrintOption(event:any){

    if(event.target.checked){
      this.print_option.push(event.target.value)
    
    }
     
    else{
      this.print_option = this.print_option.filter((item:any)=> item != event.target.value)
      //this.print_option.pop(event.target.value)
    }
    this.viewPrescription();
  }
  async viewPrescription(){
    // console.log(this.print_option)
     let param = {
       print_option:this.print_option,
       patientInfo:this.app_data,
       patient_id:this.current_patient_id,
       pId:this.app_data.pId,
       app_id:this.current_app_id,
       prescription:this.printPrescriptionObject,
     }
     
     const pdfData = await this.printPrescriptionService.generatePdf(param,"view");
     const targetElement:any = document.querySelector('#viewPdf');
     const iframe:any = document.createElement('iframe');
     iframe.src = pdfData;
     iframe.width = "100%";
     iframe.height = "400px";
     while (targetElement.firstChild) {
      targetElement.removeChild(targetElement.firstChild);
  }
     targetElement.appendChild(iframe);
   
     console.log(pdfData)
   }
  printPrescription(){
   // console.log(this.print_option)
    let param = {
      print_option:this.print_option,
      patientInfo:this.app_data,
      patient_id:this.current_patient_id,
      pId:this.app_data.pId,
      app_id:this.current_app_id,
      prescription:this.printPrescriptionObject,
    }
    console.log(param)
    this.printPrescriptionService.generatePdf(param,"print");
  }
 async sharePrescription(){
    // console.log(this.print_option)
    this.btnShareLoading = true;
     let param = {
       print_option:this.print_option,
       patientInfo:this.app_data,
       patient_id:this.current_patient_id,
       pId:this.app_data.pId,
       app_id:this.current_app_id,
       prescription:this.printPrescriptionObject,
     }
   //  console.log(param)
     const ddd =  await this.printPrescriptionService.generatePdf(param,"email")
     this.btnShareLoading = false;
     console.log(ddd)
   }
  
}
