import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

    constructor(private http: HttpClient) { }
    getLeadAnalytics(data:any): Observable<any> {
        return this.http.post(environment.getLeadAnalytics,data);
      }
    getAppointmentAnalytics(data:any): Observable<any> {
        return this.http.post(environment.getAppointmentAnalytics,data);
      }  
    getChatbotAnalytics(data:any): Observable<any> {
        return this.http.post(environment.getAnalytics,data);
      }  
      
}