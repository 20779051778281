import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppointmentService } from 'src/app/private/services/appointment.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-ipd-dashboard',
  templateUrl: './ipd-dashboard.component.html',
  styleUrls: ['./ipd-dashboard.component.scss']
})
export class IpdDashboardComponent {
  active = "patient_details"
  patient_id = 0;
  current_app_id = 0;
  currentAppointment:any;
  currentDashabord:any;
  patient: Patient = new Patient();
  modules:any = [];
  permissions:any = [];
constructor(public route:ActivatedRoute,private api:UsersService , private localStorage:LocalStorageService)
{

}

  ngOnInit(){
    
    this.route.params.subscribe(params =>
        {
          this.currentDashabord = this.route.snapshot.data['dashabord'] || "";
          this.patient_id = params['id']
          this.current_app_id = params['ipd_app_id']
        })
      
        let param = {
          where:{
                id:this.patient_id,
                hospital_id:this.localStorage.getHospitalId(),
                center_id:this.localStorage.getCenterId(),
          }
        }
        this.api.getPatientById(param).subscribe({
          next:(res)=>{
           this.patient = res;
          },
          error:(e)=>{}
        })
        this.permissions = JSON.parse(this.localStorage.getPermissions());   
        this.modules =  JSON.parse(JSON.stringify(this.localStorage.getFeatureModules()));
}

getfeatureAccess(module_name:any,feature_name:any)
{
  //console.log(this.permissions)
  let feature  = [];
  let module = [];
  module = this.permissions.filter((item:any)=>{
  return  item.module_name  == module_name;
 })
if(module.length > 0)
{
  feature = module[0]['features'].filter((item:any)=>{
    return  item.name  == feature_name;
   })
}

 return (module[0] && feature[0] ) ? true : false;

} 
getFeatureModuleAccess(module_name:any)
{
 return (this.modules.includes(module_name) ? true : false);
}
 goToDischarge()
 {
   this.active = 'discharge_summery'
 }
}

export class Patient {
  id!: Number;
  pId:string = "";
  fname: string = "";
  lname: string = "";
  prefix: string = "";
  gender: string = "";
  blood_group:string = "";
  age: Number = 0;
  email: string = "";
  mobile: string = "";
  address: string = "";
  photo: string = "";
}