import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TicketService } from '../../services/ticket.service';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { FormControl, NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { Toast, ToastrService } from 'ngx-toastr';
import { UploadService } from 'src/app/private/services/upload.service';
import * as XLSX from 'xlsx';
import moment from 'moment';

@Component({
  selector: 'app-manage-tickets',
  templateUrl: './manage-tickets.component.html',
  styleUrls: ['./manage-tickets.component.scss']
})
export class ManageTicketsComponent {
  file: any;
  url: any = '' || 'assets/img/no-image.jpg';
  isChecked = false;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  btnLoading: boolean = false;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  currentTab = 'tina';
  currentFilterTab = 'allTicket';
  userDropdown:any = [];
  ticketHistoryList:any = [];
  ticketArrayData = [];
  ticketStatus: any = "";
  temp: any = [];
  formAction:any = "add";
  closeResult = '';
  isSubmitted: boolean = false;
  displayedColumns: string[] = ["select", "ticketId", "requester", "status", "requestedAt", "updatedAt", "assignedTo", "priority", "action"];
  exportColumns: string[] = ["ticket_id", "hospital_id", "status", "ticket_body", "ticket_subject", "priority", "source", "assign_to", "requested_by", "tag", "type", "RequestedOn", "UpdatedOn"];
  dateObject: any = { startDate: "", endDate: "" }
  ticket: Ticket = new Ticket();
  @ViewChild("leadForm")
  leadForm!: NgForm;
  @ViewChild("ticketForm")
  ticketForm!: NgForm;
  @ViewChild("leadFolloupForm")
  leadFolloupForm!: NgForm;
  leadsFollowList: any = [];
  leadChannels: any = [];
  exportArrayData = [];
  param = {};

  constructor(private uploadService: UploadService, private toast: ToastrService, private api:TicketService, private modalService: NgbModal, private localStorage: LocalStorageService) {

    //console.log(this.localStorage.getClientId())
    //this.leadChannels = JSON.parse(JSON.stringify(this.localStorage.getLeadChannels()));
    //console.log(this.leadChannels)
     //   this.loadZendeskData('allTicket');
 
  }
  ngAfterViewInit() {

    this.loadTinaData('allTicket');

  }
  ngOnInit(): void { }

  searchFilter(query: any) {
    this.searchQuery.setValue(query);
  }
  ResetDateFilter(event: any) {
    this.dateQuery.setValue("");
  }
  ResetFilter(event: any) {
    this.searchQuery.setValue("");
  }
  clearTicketForm() {
    this.ticket = new Ticket();
  }
  // load Initial table data from based on source type
  loadTinaData(filterParam: any) {

    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(this.dateQuery.valueChanges, this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
        switchMap(() => {
      
          // console.log(this.dateQuery.value)
          if (filterParam === 'allTicket') {
            this.param = {
              where: { hospital_id: this.localStorage.getHospitalId() },
              status: "NotDeleted",
              filter: this.searchQuery.value,
              page: (this.paginator.pageIndex * this.paginator.pageSize),
              limit: this.paginator.pageSize,
              // startDate:this.dateQuery.value.startDate,
              // endDate:this.dateQuery.value.endDate,
              order: [{ col: 'createdAt', dir: "desc" }],
            }
          } else if (filterParam === 'unassigned') {
            this.param = {
              where: { assign_to_name: null, hospital_id: this.localStorage.getHospitalId() },
              filter: this.searchQuery.value,
              page: (this.paginator.pageIndex * this.paginator.pageSize),
              limit: this.paginator.pageSize,
              // startDate:this.dateQuery.value.startDate,
              // endDate:this.dateQuery.value.endDate,
              order: [{ col: 'createdAt', dir: "desc" }],
            }
          } else {
            this.param = {
              where: { status: filterParam, hospital_id: this.localStorage.getHospitalId() },
              filter: this.searchQuery.value,
              page: (this.paginator.pageIndex * this.paginator.pageSize),
              limit: this.paginator.pageSize,
              // startDate:this.dateQuery.value.startDate,
              // endDate:this.dateQuery.value.endDate,
              order: [{ col: 'createdAt', dir: "desc" }],
            }
          }
 
          if (this.dateQuery.value) { this.param = { ...this.param, ...this.dateQuery.value } }

          return this.api.getAllTinaTicket(this.param)
            .pipe(catchError(() => observableOf(null)));
        }), map((response: any) => {
          if (response === null) {
            return [];
          }

          this.totalRows = response.count;
          return response
        })
      ).subscribe({
        next: (data) => {
          this.exportArrayData = data.rows;
          this.ticketArrayData = data.rows;
          this.dataSource = new MatTableDataSource<any>(data.rows);

        },
        error: (e) => {
          console.error(e)
        },
        complete: () => {

        }
      })
    }
    catch (e) {
      console.error(e)
    }
  }

  loadZendeskData(filterParam: any) {
    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(this.dateQuery.valueChanges, this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
        switchMap(() => {
          // console.log(this.dateQuery.value)
          let param = {
            where: { hospital_id: this.localStorage.getHospitalId() },
            filter: this.searchQuery.value,
            page: (this.paginator.pageIndex * this.paginator.pageSize),
            limit: this.paginator.pageSize,
            // startDate:this.dateQuery.value.startDate,
            // endDate:this.dateQuery.value.endDate,
            order: [{ col: 'createdAt', dir: "desc" }],
          }
          if (this.dateQuery.value) { param = { ...param, ...this.dateQuery.value } }

          return this.api.getAllZendeskTicket(param)
            .pipe(catchError(() => observableOf(null)));
        }), map((response: any) => {
          if (response === null) {
            return [];
          }

          this.totalRows = response.count;
          return response
        })
      ).subscribe({
        next: (data) => {
          // console.log(data)
          this.exportArrayData = data.rows;
          if (filterParam === 'allTicket') {
            this.dataSource = new MatTableDataSource<any>(data.rows);
          }
          if (filterParam === 'pending') {
            let filteredTickets = data.rows.filter((data: any) => {
              return data.status === 'pending';
            });
            this.dataSource = new MatTableDataSource<any>(filteredTickets);
          }
          if (filterParam === 'unassigned') {
            let filteredTickets = data.rows.filter((data: any) => {
              return data.assign_to === null;
            });
            this.dataSource = new MatTableDataSource<any>(filteredTickets);
          }
          if (filterParam === 'open') {
            let filteredTickets = data.rows.filter((data: any) => {
              return data.status === 'open';
            });
            this.dataSource = new MatTableDataSource<any>(filteredTickets);
          }
          if (filterParam === 'solved') {
            let filteredTickets = data.rows.filter((data: any) => {
              return data.status === 'solved';
            });
            this.dataSource = new MatTableDataSource<any>(filteredTickets);
          }
          if (filterParam === 'deleted') {
            let filteredTickets = data.rows.filter((data: any) => {
              return data.status === 'deleted';
            });
            this.dataSource = new MatTableDataSource<any>(filteredTickets);
          }
        },
        error: (e) => {
          console.error(e)
        },
        complete: () => {

        }
      })
    }
    catch (e) {
      console.error(e)
    }
  }

  dateFilter(event: any) {
    this.dateQuery.setValue(event);
  }

  changeTab(tab: any) {
    if (tab == 'tina') {
      this.currentTab = tab;
      this.totalRows = 0;
      this.currentPage = 0;
      this.dataSource = new MatTableDataSource();
      this.loadTinaData('allTicket');
    } else {
      this.currentTab = tab;
      this.totalRows = 0;
      this.currentPage = 0;
      this.dataSource = new MatTableDataSource();
      this.loadZendeskData('allTicket');
    }

  }
  changeFilterTab(filterTab: any, mainTab: any) {
    if (mainTab == 'tina') {
      this.currentFilterTab = filterTab;
      this.loadTinaData(filterTab);
    }
    if (mainTab == 'zendesk') {
      this.currentFilterTab = filterTab;
      this.loadZendeskData(filterTab);
    }
  }
  editTicket(content: any, object: any) {
    this.getUser();
    this.formAction = "update";
    this.ticket.ticket_id = object.ticket_id;
    this.ticket.status = object.status;
    this.ticket.priority = object.priority;
    this.ticket.source = object.source;
    this.ticket.requested_by_email = object.requested_by_email;
    this.ticket.requested_by_name = object.requested_by_name;
    this.ticket.assign_to_id = object.assign_to_id;
    this.ticket.assign_to_name = object.assign_to_name;
    this.ticket.ticket_subject = object.ticket_subject;
    this.ticket.ticket_body = object.ticket_body;
    this.ticket.tag = object.tag;
    this.ticket.type = object.type;
    this.ticket.attachment = object.attachment;
   
    this.modalService.open(content, { size: 'xl', scrollable: true });
    this.getTicketHistory(object.ticket_id);
  }


  getUser() {
    let param = {
      where: { status: "Active", hospital_id: this.localStorage.getHospitalId() }
    }
    this.api.getUser(param).subscribe({
      next: (res: any) => {
        this.userDropdown = res || [];
      },
      error(err: any) {

      },
    })
  }

  getTicketHistory(ticketId: any) {
    let param = {
      where: { ticket_id: ticketId }
    }
    this.api.getTinaTicketHistory(param).subscribe({
      next: (res: any) => {
        this.ticketHistoryList = res.rows || [];
      },
      error(err: any) {

      },
    })
  }
  open(content: any) {
    this.formAction = "add";
    this.getUser();
    this.clearTicketForm();
    this.ticket.requested_by_name = this.localStorage.getProfileName();
    this.ticket.requested_by_email = this.localStorage.getProfileEmail();
    this.modalService.open(content, { size: 'md', scrollable: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;

      },
      (reason) => {

        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }
  isAllSelected() {
    return this.dataSource.data.every((item) => item.isSelected);
  }

  isAnySelected() {
    return this.dataSource.data.some((item) => item.isSelected);
  }

  selectOne(event: any, element: any) {
    if (event.target.checked) {
      this.temp.push(this.ticketArrayData.find((ticket: any) => ticket.ticket_id == element.ticket_id));
    }
    else {
      this.temp = this.temp.filter(function (obj: any) {
        return obj.ticket_id !== element.ticket_id;
      })
    }

  }

  selectAll(event: any) {

    var checkboxes: any = document.getElementsByClassName('check_input_manual');
    if (event.target.checked) {

      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          // console.log(this.ticketArrayData)
          let ticketObject = this.ticketArrayData.find((ticket: any) => ticket.ticket_id == checkboxes[i].value);
          // console.log(ticketObject)
          checkboxes[i].checked = true;
          this.temp.push(ticketObject)
        }
      }
    }
    else {
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = false;
        }
      }
      this.temp = [];
    }

  }
  openTicketList(content: any) {
    if (this.temp.length < 1) {
      this.toast.error("Please select a record to create the list !");
    }
    else {
      this.getUser();
      this.formAction = "updateTicket";
      this.modalService.open(content, { size: 'md', scrollable: true }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;

        },
        (reason) => {

          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
    }
  }

  updateTicketList(isValid: any) {
    this.isSubmitted = true
    if (isValid) {
      this.btnLoading = true;
      // console.log(this.temp)
      this.temp.map((data: any) => {
        // console.log("this.ticket.ticketStatus", this.ticket.ticketStatus);
        if (!(this.ticket.ticketStatus === "")) {
          data.status = this.ticket.ticketStatus;
        }
        // console.log("this.ticket.priority", this.ticket.priority);
        if (!(this.ticket.priority === "")) {
          // console.log("this.ticket.priority", this.ticket.priority);
          data.priority = this.ticket.priority;
        }
        if (this.ticket.assign_to && Object.keys(this.ticket.assign_to).length !== 0) {
          // console.log("this.ticket.assign_to.length", this.ticket.assign_to)
          data.assign_to_id = this.ticket.assign_to.user_id;
          data.assign_to_name = this.ticket.assign_to.prefix + " " + this.ticket.assign_to.fname + " " + this.ticket.assign_to.lname;
        }
        data.remarks = this.ticket.remarks;
        data.updatedBy = {
          userId: this.localStorage.getUserId().slice(7),
          name: this.localStorage.getProfileName(),
          email: this.localStorage.getProfileEmail()

        }
      })

      this.api.updateBulkTicket(this.temp).subscribe({
        next: (c) => {
          this.modalService.dismissAll();
          this.loadTinaData('allTicket');
          this.btnLoading = false;
          this.isSubmitted = false;
          // this.updateTicketHistory(this.ticket);
          this.createBulkTicketHistory(this.temp);
          this.temp = [];
          this.ticket = new Ticket();
          this.toast.success("Ticket Updated Successfully", "Success!!");
        },
        error: (e) => {
          this.btnLoading = false;
          this.isSubmitted = false
        },
        complete: () => { }
      })
    }
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  uploadImage(ticket_id: any, fileName: any) {

    if (this.file) {
      console.log(this.file)
      const formData = new FormData();
      formData.append("file", this.file, fileName);
      formData.append("folders", "public/TicketImages/");
      formData.append("bucket", "emruploads");
      // console.log(formData)
      this.uploadService.uploadPatientProfile(formData).subscribe({
        next: (res) => {
          if (res) {
            let param = {
              ticket_id: ticket_id,
              attachement_link: res.url,
              remarks: "Attachment uploded"

            }
            this.api.updateTicket(param).subscribe({
              next: (res: any) => {
              }
            })

          }
        },
        error: (e) => {
          console.log(e);
        }
      })

    }
  }

  onFileSelected(event: any) {

    const files = event.target.files;
    if (files.length === 0)
      return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.toast.error('Only images are supported.');
      return;
    }

    const reader = new FileReader();
    this.file = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {

      // this.patient.photo = reader.result; 
    }

  }


  SaveTicket(isValid: any) {
    this.isSubmitted = true;

    if (isValid)
      console.log();
    {
      if (this.formAction == 'add') {
        this.btnLoading = true;
        this.ticket.hospital_id = this.localStorage.getHospitalId();
        this.ticket.source = "tina";
        if (this.ticket.assign_to && Object.keys(this.ticket.assign_to).length !== 0) {
          // console.log("this.ticket.assign_to.length", this.ticket.assign_to)
          this.ticket.assign_to_id = this.ticket.assign_to.user_id;
          this.ticket.assign_to_name = this.ticket.assign_to.prefix + " " + this.ticket.assign_to.fname + " " + this.ticket.assign_to.lname;
        }
        this.api.addTicketData(this.ticket).subscribe({
          next: (res: any) => {
            this.modalService.dismissAll()
            this.ticket = new Ticket();
            if (res) {
              this.uploadImage(res.ticket_id, res.ticket_id + res.createdAt);
            }
            this.loadTinaData("allTicket");
            this.btnLoading = false;
            this.toast.success("Ticket Added Successfully", "Success!!")
          },
          error: (e: any) => {
            this.btnLoading = false;
          },
          complete: () => { }
        })
      }
      else if (this.formAction == 'update') {
        //alert()
        if (this.ticket.assign_to && Object.keys(this.ticket.assign_to).length !== 0) {
          // console.log("this.ticket.assign_to.length", this.ticket.assign_to)
          this.ticket.assign_to_id = this.ticket.assign_to.user_id;
          this.ticket.assign_to_name = this.ticket.assign_to.prefix + " " + this.ticket.assign_to.fname + " " + this.ticket.assign_to.lname;
        }
        this.btnLoading = true;
        this.api.updateTicket(this.ticket).subscribe({
          next: (c: any) => {
            this.modalService.dismissAll()
            this.loadTinaData('allTicket')
            this.isSubmitted = false
            this.btnLoading = false;
            this.toast.success("Ticket Updated Successfully", "Success!!");
            this.updateTicketHistory(this.ticket);
            this.ticket = new Ticket();

          },
          error: (e: any) => {
            this.btnLoading = false;
            this.isSubmitted = false
          },
          complete: () => { }
        })

      }
    }
  }

  updateTicketHistory(ticket: any) {
    this.ticket.updatedBy = {
      userId: this.localStorage.getUserId(),
      name: this.localStorage.getProfileName(),
      email: this.localStorage.getProfileEmail()
    }
    this.api.updateTicketHistory(ticket).subscribe({
      next: (c: any) => {
      },
      error: (e: any) => {
        this.btnLoading = false;
        this.isSubmitted = false
      },
      complete: () => { }
    })
  }
  createBulkTicketHistory(ticket: any) {
    // console.log("updateBulkTicketHistory", ticket);
    this.api.createBulkTicketHistory(ticket).subscribe({
      next: (c: any) => {
      },
      error: (e: any) => {
        this.btnLoading = false;
        this.isSubmitted = false
      },
      complete: () => { }
    })
  }
  deleteTicket(obj: any) {
    obj.status = "Deleted";
    obj.remarks = "for validation";
    this.api.updateTicket(obj).subscribe({
      next: (c: any) => {
        this.loadTinaData('allTicket');
      },
      error: (e: any) => {

      },
      complete: () => { }
    })
  }

  export(header: any) {

    let excelRowData: any = [];
    this.exportArrayData.forEach((element: any) => {
      excelRowData.push({ 0: element.ticket_id, 1: element.hospital_id, 2: element.status, 3: element.ticket_body, 4: element.ticket_subject, 5: element.priority, 6: element.source, 7: element.assign_to_name, 8: element.requested_by_email, 9: element.tag, 10: element.type, 11: element.createdAt, 12: element.updatedAt, 13: element.requested_by_name })
    });

    let excelData = [];
    let excelHeader = [];
    for (let i = 0; i < excelRowData.length; i++) {
      let element = excelRowData[i];
      let obj: any = {}
      for (let index = 0; index < header.length; index++) {
        let key = header[index]['key'];
        obj[key] = element[key]
      }
      excelData.push(obj)
    }
    for (let index = 0; index < header.length; index++) {
      let value = header[index]['value'];
      excelHeader.push(value)
    }
    // console.log(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
    XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    XLSX.writeFile(wb, "ticket_data_" + day + "_" + month + "_" + year + "_" + date.getTime() + ".csv");


  }


}
function observableOf(arg0: null): any {

  //throw new Error('Function not implemented.');
}
export class Ticket {
  source: string = "";
  hospital_id!: Number;
  ticket_id!: Number;
  requested_by_name: string = "";
  requested_by_email: string = "";
  mobile: string = "";
  assign_to_id!: Number;
  assign_to_name: string = "";
  ticket_subject: string = "";
  ticket_body: string = "";
  status: string = "Open";
  tag: string = "";
  type: string = "";
  priority: string = "";
  attachment: string = "";
  remarks: string = "";
  updatedBy: any = {};
  assign_to: any = "";
  ticketStatus: any = "";
}