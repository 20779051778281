import { Component } from '@angular/core';
import { JWTTokenServiceService } from 'src/app/public/services/token.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  openSidebar: boolean = true;
  expand: boolean = false;
  sidepanel:any = "hide_panel";
  role:any;
  permissions:any = [];
  modules:any = [];
  constructor(private localStorage:LocalStorageService,private auth:JWTTokenServiceService) {

   }
  
   getfeatureAccess(module_name:any,feature_name:any)
   {
     //console.log(this.permissions)
     let feature  = [];
     let module = [];
     module = this.permissions.filter((item:any)=>{
     return  item.module_name  == module_name;
    })
   if(module.length > 0)
   {
     feature = module[0]['features'].filter((item:any)=>{
       return  item.name  == feature_name && item.active == true;
      })
   }
   
    return (module[0] && feature[0] ) ? true : false;
 
   } 
  getAccess(module_name:any)
  {
    //console.log("chetan")
   //console.log(this.permissions)
   let result = this.permissions.filter((item:any)=>{
    return  item.module_name  == module_name && item.active == true;
   })
   //console.log(result[0])
   return (result[0]) ? true : false;
   //return true;
  } 
  ngOnInit() {
    if(this.auth.getToken())
    {
      this.role = this.localStorage.getRole();
      this.permissions = JSON.parse(this.localStorage.getPermissions()); 
      this.modules =  JSON.parse(JSON.stringify(this.localStorage.getFeatureModules()));
    }
    
   //console.log(this.modules)
  
  }
  getFeatureModuleAccess(module_name:any)
  {
   return (this.modules.includes(module_name) ? true : false);
  }
  togglePanel()
  {
    this.sidepanel = "show_panel";
  }
  togglePanelOff()
  {
    this.sidepanel = "hide_panel";
  }

  openSubmenu()
  {
    this.expand = true;
  }
  closeSubmenu()
  {
    this.expand = false;
  }
  showSubmenu(itemEl: HTMLElement) {
    itemEl.classList.toggle("showMenu");
  }
}
