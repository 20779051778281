import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { DateFilterComponent } from 'src/app/private/shared/ui/date-filter/date-filter.component';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-ipd-report',
  templateUrl: './ipd-report.component.html',
  styleUrls: ['./ipd-report.component.scss']
})
export class IpdReportComponent {
  DateFilterComponent = DateFilterComponent;
  @Input() BillingDataList:any;
  @Input() totalCollection:any;
  
  FilterdateStart:Date =  new Date();
  Filterdateend:Date =  new Date();
  @Output("DateFilter") DateFilter: EventEmitter<any> = new EventEmitter();
  @Output("PaymentFilter") PaymentFilter: EventEmitter<any> = new EventEmitter();
  fileName= moment().format("YYYY-MM-DD")+'_collection_report.xlsx';  
  filterByDateRange()
{
  let start = (this.FilterdateStart!=null) ? new Date(this.FilterdateStart.setHours(0,0,0,0)) :this.FilterdateStart;;
  let end =  (this.Filterdateend!=null) ? new Date(this.Filterdateend.setHours(0,0,0,0)) :this.Filterdateend;

  let startd = moment(start).format("YYYY-MM-DD")
  let endb = moment(end).format("YYYY-MM-DD")
  if(startd!= "Invalid date" && endb!= "Invalid date")
  {
    let object = {startDate:startd,endDate:endb}
    this.DateFilter.emit(object)
  }

}
onPaymentTypeChange(event:any)
{
  let object = {payment_method:event.target.value}
  this.PaymentFilter.emit(object)
}
export(): void 
    {
       /* table id is passed over here */   
  
       var Heading = [
        ["S.No", "IPD No", "Invoice No", "Invoice Date", "Payment Status", "Payment Method", "Paid Amount"],
      ];
      let data:any = [];
      console.log(this.BillingDataList)
      this.BillingDataList.forEach((element:any,i:any) => {
      
        data.push({sr:i+1,opd_number:element.opd_no,invoice_number:element.receipt_no,billing_date:element.billing_date,status:element.status,payment_method:element.payment_method,amount:element.amount})
      });
  
  
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');


       /* save to file */
       XLSX.writeFile(wb, this.fileName);
			
    
}
resetDatePicker()
{
  this.DateFilter.emit("")
}
}

