import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private http: HttpClient) { }

  getAllPriceCategory(): Observable<any> {
    return this.http.get(environment.getAllPriceCategory);
  }
  getAllPriceMaster(data:any): Observable<any> {
    return this.http.post(environment.getAllPriceMaster,data);
  }
  createBilling(data:any): Observable<any> {
    return this.http.post(environment.createBilling,data);
  }
  getBillingListByPatient(data:any): Observable<any> {
    return this.http.post(environment.getBillingListByPatient,data);
  }
  getReceiptData(data:any): Observable<any> {
    return this.http.post(environment.getReceiptData,data);
  }
  generateReceiptNo(data:any): Observable<any> {
    return this.http.post(environment.generateReceiptNo,data);
  }
  updateBilling(data:any): Observable<any> {
    return this.http.post(environment.updateBilling,data);
  }
  getBillingData(data:any): Observable<any> {
    return this.http.post(environment.getBillingData,data);
  }
  getBillingModuleData(data:any): Observable<any> {
    return this.http.post(environment.getBillingModuleData,data);
  }

  createIPDBilling(data:any): Observable<any> {
    return this.http.post(environment.createIPDBilling,data);
  }
  getIPDbillsByIPDAppId(data:any): Observable<any> {
    return this.http.post(environment.getIPDbillsByIPDAppId,data);
  }
  updateIPDBilling(data:any): Observable<any> {
    return this.http.post(environment.updateIPDBilling,data);
  }
  
  getCreditOrganization(data:any): Observable<any> {
    return this.http.post(environment.getCreditOrganization,data);
  }
  generateIPDBillNo(data:any): Observable<any> {
    return this.http.post(environment.generateIPDBillNo,data);
  }
  getIpdBillingPrintData(data:any): Observable<any> {
    return this.http.post(environment.getIpdBillingPrintData,data);
  }

  getIpdBillingDataReport(data:any): Observable<any> {
    return this.http.post(environment.getIpdBillingDataReport,data);
  }

  getPaymentMethods(data:any): Observable<any> {
    return this.http.post(environment.getPaymentMethods,data);
  }
  
  getTaxes(data:any): Observable<any> {
    return this.http.post(environment.getTaxes,data);
  }
  createCreditNote(data:any): Observable<any> {
    return this.http.post(environment.createCreditNote,data);
  }

  getCreditBillingData(data:any): Observable<any> {
    return this.http.post(environment.getCreditBillingData,data);
  }
  
  
  
  

  
  
  

  
  
  
  
  
}
