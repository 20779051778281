import { Component } from '@angular/core';

@Component({
  selector: 'app-patient-review-graph',
  templateUrl: './patient-review-graph.component.html',
  styleUrls: ['./patient-review-graph.component.scss']
})
export class PatientReviewGraphComponent {

}
