import { Component } from '@angular/core';
import { BillingService } from '../../services/billing.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-billing',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent  {
  active = "OPD"
  tab:any = "OPD";
  BillingList:any = [];
  totalCollection:Number = 0;
  dateQuery:any =  new FormControl();
  tabQuery:any =  new FormControl();
  paymentType:any =  new FormControl();
  filterType:any =  new FormControl();
  constructor(private api:BillingService,private localStorage:LocalStorageService)
  {
    this.tabQuery.setValue("OPD")
 
  }
  ngAfterViewInit() {
 
    if(this.tabQuery.value=='OPD')
    {
      this.loadBillingData();
    }
    else if(this.tabQuery.value=='IPD')
    {
      this.loadIpdBillingData();
    }
    else if(this.tabQuery.value=='CREDIT')
      {
       // alert()
        this.loadCreditBillingData();
      }
  }

  filterData(event:any){
    this.dateQuery.setValue(event);
  }
  filterDataPayment(event:any){
    this.paymentType.setValue(event);
    this.loadBillingData();
  }
  filterDataIpdPayment(event:any){
    this.paymentType.setValue(event);
    this.loadIpdBillingData();
  }
  changeTab(event:any)
  {
    if(event=='OPD')
    {
      this.loadBillingData();
    }
    else if(event=='IPD')
    {
      this.loadIpdBillingData();
    }
    else if(event=='CREDIT')
      {
        this.loadCreditBillingData();
      }
    this.tabQuery.setValue(event)
  }
  filterDataSearchPatient(event:any){
    console.log(event)
   this.filterType.setValue(event)
   this.loadBillingData();
  }
  loadIpdBillingData()
  {
    try{
      merge(this.dateQuery.valueChanges).pipe(startWith({}),
      switchMap(()=>{
        let param = {
          where:{hospital_id:this.localStorage.getHospitalId(),
          center_id:this.localStorage.getCenterId
          },
          paymentType:this.paymentType.value
        }
        if(this.dateQuery.value){
          param = {...param,...this.dateQuery.value};
        }
  
   

       return  this.api.getIpdBillingDataReport(param)
       .pipe(catchError(() => observableOf(null)));
      }),map((response:any)=>{
       if (response === null) {
         return [];
       }
       let total = 0;
       response.forEach((element:any) => {

        total = total + Number(element.billing_details.total_amount)
        
       });
       this.totalCollection = total;
       return response
      })
      ).subscribe({
       next:(data) =>{
      // console.log(data)
       this.BillingList = data;
       },
       error:(e)=>{
        console.error(e)
       },
       complete:()=>{
        
       }
      })
     }
     catch(e){
      console.error(e)
     }
     }
    
  
  loadBillingData(){
    try{
      merge(this.dateQuery.valueChanges).pipe(startWith({}),
      switchMap(()=>{
        let param = {
          where:{hospital_id:this.localStorage.getHospitalId(),
          center_id:this.localStorage.getCenterId(),
          }
        }
        if(this.dateQuery.value){
          param = {...param,...this.dateQuery.value};
        }
        if(this.paymentType.value!=null){
          param.where = {...param.where,...this.paymentType.value};
        }
        if(this.filterType.value){
          param = {...param,...{filter:this.filterType.value}};
        }
       return  this.api.getBillingData(param)
       .pipe(catchError(() => observableOf(null)));
      }),map((response:any)=>{
       if (response === null) {
         return [];
       }
       let total = 0;
       response.forEach((element:any) => {

        total = total + Number(element.amount)
        
       });
       this.totalCollection = total;
       return response
      })
      ).subscribe({
       next:(data) =>{
      // console.log(data)
       this.BillingList = data;
       },
       error:(e)=>{
        console.error(e)
       },
       complete:()=>{
        
       }
      })
     }
     catch(e){
      console.error(e)
     }
     }

     loadCreditBillingData(){
      try{
        merge(this.dateQuery.valueChanges).pipe(startWith({}),
        switchMap(()=>{
          let param = {
            where:{hospital_id:this.localStorage.getHospitalId(),
            center_id:this.localStorage.getCenterId(),
            }
          }
          if(this.dateQuery.value){
            param = {...param,...this.dateQuery.value};
          }
          if(this.paymentType.value!=null){
            param.where = {...param.where,...this.paymentType.value};
          }
          if(this.filterType.value){
            param = {...param,...{filter:this.filterType.value}};
          }
         return  this.api.getCreditBillingData(param)
         .pipe(catchError(() => observableOf(null)));
        }),map((response:any)=>{
         if (response === null) {
           return [];
         }
         let total = 0;
         response.forEach((element:any) => {
  
          total = total + Number(element.amount)
          
         });
         this.totalCollection = total;
         return response
        })
        ).subscribe({
         next:(data) =>{
        // console.log(data)
         this.BillingList = data;
         },
         error:(e)=>{
          console.error(e)
         },
         complete:()=>{
          
         }
        })
       }
       catch(e){
        console.error(e)
       }
       }
}
function observableOf(arg0: null): any {
  throw new Error('Function not implemented.');
}

