import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-populer-timing-graph',
  templateUrl: './populer-timing-graph.component.html',
  styleUrls: ['./populer-timing-graph.component.scss']
})
export class PopulerTimingGraphComponent {
  @Input() data:any = [];
  @Input() id:any;
  @Input() Title:any;
  time:any;
  constructor()
  {
    
  }
  ngAfterViewInit()
  {
    let maxObject = null;
    let maxValue:any = -0;
    for (let key in this.data) {
      if (this.data[key] > maxValue) {
        maxValue = this.data[key];
        maxObject = key;
      }
    }
    this.time = maxObject;

    //const maxValue = Math.max(...valuesdd);

    const analysis_daily_opt:any = document.getElementById(this.id);
    const analysis_daily_ctx = analysis_daily_opt.getContext('2d');
    var gradient = analysis_daily_ctx.createLinearGradient(0, 140, 0, 0);
    let chartStatus = Chart.getChart(this.id); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    var keys = [];
    var values = [];
    if(this.data)
    {
    for (var key in this.data) {
      keys.push(key);
        values.push(this.data[key])
    }

    gradient.addColorStop(0, 'rgb(27 98 129 / 20%)');  
    gradient.addColorStop(1, 'rgb(67 204 213)');
    let myChart2 = new Chart(analysis_daily_ctx, {
      type: 'line',
      data: {
          labels: keys,
          datasets: [{
              label: '',
              //fill: true,
              data: values,
            
              borderColor: "#ffbd35",
              borderWidth: 1.5,
              pointRadius: 1,
              //tension: 0.4,
             
              backgroundColor: gradient
          }]
      },
      options: {
        plugins: {
          legend: {
              display: false
          },
      },
        responsive: false,
        // display: true,
        scales: {
          y: {
            grid: {
              display:true,
            }
          },
          x: {
            grid: {
              display:true,
            }
          }
        }
      },
    })
  }
  }
}
