import { Component } from '@angular/core';

@Component({
  selector: 'app-opd-receipt',
  templateUrl: './opd-receipt.component.html',
  styleUrls: ['./opd-receipt.component.scss']
})
export class OpdReceiptComponent {

}
