import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MasterService } from 'src/app/private/services/master.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-procedure-search-input',
  templateUrl: './procedure-search-input.component.html',
  styleUrls: ['./procedure-search-input.component.scss']
})
export class ProcedureSearchInputComponent {
  resultArray:any = []
  TemplateList:any = []
  showPopup:boolean = false;
  selectOrgan:any = "";
  @Input() URL:any;
  @Input() PLACEHOLDER:any;
  @Input() DATA:any = [];
  @Output("inputValue") inputValue: EventEmitter<any> = new EventEmitter();
  constructor(private localStorage:LocalStorageService,private api:MasterService,private templateService:TemplateService)
  {

  }
  ngOnInit(){
    console.log(this.DATA)
    this.resultArray = this.DATA;
  }
  removeTag(item:any)
  {
    this.resultArray = this.resultArray.filter((value:any)=> value!=item);
    this.inputValue.emit(this.resultArray)
  }
  openTemplate()
  {
    this.showPopup = true;
    this.getTemplate()
  }
  closeTemplate()
  {
    this.showPopup = false;
  }
  getTemplate()
  {

    let param = {
 
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        cat_id:2
      
    }
    this.templateService.getDynamicTemplateService(param,this.URL).subscribe({
      next:(res)=>{
        if(res)
        {
          this.TemplateList = res;
        }
      },
      error:(e)=>{

      }
    })
  }
  searchRecord(event:any)
  {
    //console.log(event)
    this.TemplateList.data  = this.TemplateList.data.filter((item:any)=> item == event)
  }
  onKeyUp(event:any)
  {
    const inputValue: string = event.target.value;
    this.searchRecord(inputValue)
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag(event.target.value);
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Enter') {
        this.addDiagnosis(inputValue,"");
        event.target.value = ""
      }
    }
  }
  addDiagnosis(item:any,organ:any)
{

  let existingArray = []
  let value:any = organ+" "+item.item
   existingArray = this.resultArray || [];
   this.resultArray = existingArray.concat(value);
   this.inputValue.emit(this.resultArray)
   this.showPopup = false;
}
  selectOrganFn(event:any)
{
  this.selectOrgan = event.target.value;
}
  
}
