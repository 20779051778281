import { Component } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { map, timer } from 'rxjs';
import { AppointmentService } from 'src/app/private/services/appointment.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { TimerService } from 'src/app/utils/timer.service';

@Component({
  selector: 'app-emr-detail',
  templateUrl: './opd-dashboard.component.html',
  styleUrls: ['./opd-dashboard.component.scss']
})
export class OpdDashboardComponent {
  
   startDate = new Date();
   timer:any;
   active = "patient_details"
   patient_id = 0;
   pId:any;
   current_app_id = 0;
   currentAppointment:any;
   currentDashabord:any;
   patient: Patient = new Patient();
   modules:any = [];
   permissions:any = [];
   constructor(public timerService: TimerService,public route:ActivatedRoute,private api:AppointmentService , private localstorage:LocalStorageService)
   {
    this.permissions = JSON.parse(this.localstorage.getPermissions());   
    this.modules =  JSON.parse(JSON.stringify(this.localstorage.getFeatureModules()));
   }
   ngOnInit(){
    
      this.route.params.subscribe(params =>
          {
            this.currentDashabord = this.route.snapshot.data['dashabord'] || "";
            //console.log(this.currentDashabord)
            this.patient_id = params['id']
            this.current_app_id = params['app_id']
          })
        
          let param = {
            where:{
                  id:this.patient_id,
                  app_id:this.current_app_id,
                  hospital_id:this.localstorage.getHospitalId(),
                  center_id:this.localstorage.getCenterId(),
            }
          }
          this.api.getPatient(param).subscribe({
            next:(res)=>{
              if(res)
              {
                this.patient = res;
                this.pId = res.pId
                this.currentAppointment = res;
              }
        
            },
            error:(e)=>{}
          })
         
  }

  getfeatureAccess(module_name:any,feature_name:any)
  {
    //console.log(this.permissions)
    let feature  = [];
    let module = [];
    module = this.permissions.filter((item:any)=>{
    return  item.module_name  == module_name;
   })
  if(module.length > 0)
  {
    feature = module[0]['features'].filter((item:any)=>{
      return  item.name  == feature_name;
     })
  }
  
   return (module[0] && feature[0] ) ? true : false;

  } 
  getFeatureModuleAccess(module_name:any)
  {
   return (this.modules.includes(module_name) ? true : false);
  }
}




export class Patient {
   id!: Number;
   pId:string = "";
   fname: string = "";
   lname: string = "";
   prefix: string = "";
   gender: string = "";
   blood_group:string = "";
   age: Number = 0;
   email: string = "";
   mobile: string = "";
   address: string = "";
   photo: string = "";
 }
