import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-investigation-type-chart',
  templateUrl: './investigation-type-chart.component.html',
  styleUrls: ['./investigation-type-chart.component.scss']
})
export class InvestigationTypeChartComponent {
  @Input() id: any;
  @Input() Title: any;
  colors = ['#104358', '#1369A3', '#469BD3']; // Update the colors array to include the color for the third dataset

  ngAfterViewInit() {
    const analysis_daily_opt: any = document.getElementById("canvas_" + this.id);
    const analysis_daily_ctx = analysis_daily_opt.getContext('2d');

    let chartStatus = Chart.getChart(this.id); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    const data = {
      labels: ['Fundus', 'Cataract', 'Fundus', 'Cataract', 'Fundus', 'Fundus'],
      datasets: [
        {
          label: 'Dataset 1',
          backgroundColor: '#D83F3F', // Use the first color from the colors array
          borderWidth: 1,
          data: [10, 15, 30, 40, 50, 10],
          // barThickness: 'flex', // Set bar thickness to 'flex' to adjust based on data values
        },
        {
          label: 'Dataset 2',
          backgroundColor: "#F0950C", // Use the second color from the colors array
          borderWidth: 1,
          data: [10, 40, 40, 50, 60, 30],
          // barThickness: 'flex', // Set bar thickness to 'flex' to adjust based on data values
        },
        {
          label: 'Dataset 3',
          backgroundColor: '#77DC75', // Use the third color from the colors array
          borderWidth: 1,
          data: [60, 45, 20, 10, 50, 90],
          // barThickness: 'flex', // Set bar thickness to 'flex' to adjust based on data values
        }
      ]
    };

    let myChart2 = new Chart(analysis_daily_ctx, {
      type: 'bar',
      data: data,
      options: {

        plugins: {
          legend: {
            display: false
          },

        },
        responsive: true,
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            border: {
              display: false
            },
            grid: {
              display: false,
            },
            ticks: {
              display: true,
            },
          },
          y: {
            stacked: true,
            border: {
              display: false
            },
            grid: {
              display: false
            },
            ticks: {
              display: true,
            },

          },
        },

        layout: {
          padding: {
            left: 5,
            right: 5,
            top: 0,
            bottom: 0
          }


        }
      },

    });
  }
}
