import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BillingService } from 'src/app/private/services/billing.service';
import { OpdchartService } from 'src/app/private/services/opdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-investigations',
  templateUrl: './investigations.component.html',
  styleUrls: ['./investigations.component.scss']
})
export class InvestigationsComponent {
  @Input() app_data:any
  @Input() patient:any
  @Input() titleOfHead: any = {
    title: 'OPD',
    root: 'Investigations'
  };
  id:any;
  btnSaveLoading:boolean = false;
  currentTab:any;
  investigationList:any = [];
  templateName:any = "";
  investigationTypeList:any = [];
  temp_Object:any ;
  templateInvestigationList:any = [];
  templateSaveButton:boolean = false;
  count:Number = 0;
  currentDate:any = new Date();
  investigationListData:any = []
  investigationObject:any = {
   investigation_name:"",
   item_id:"",
   priority:"",
   remarks:"",
  }
  layoutLoading:boolean = false;
  current_app_id:any;
  current_patient_id:any
  loading:boolean = false;
  constructor(public route:ActivatedRoute,private opdChartAPI:OpdchartService, private toast:ToastrService, public dialog: MatDialog,private modalService: NgbModal,private api:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.current_app_id = params['app_id']
      })
  }

  edit(content:any,item:any)
  {
    this.loadPriceMasterItems();
    this.getInvestigationTemplate();
    this.modalService.open(content,  {  size:'xl', centered:true, scrollable: false,ariaLabelledBy: 'modal-basic-title' });
    this.investigationList = item; 
  
  }
  clearData()
  {
    this.modalService.dismissAll();
    this.investigationObject = {
      investigation_name:"",
      item_id:"",
      priority:"",
      remarks:"",
     }
     this.temp_Object = undefined;
  }

  loadPriceMasterItems()
  {
    let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       cat_id:3
    }
    this.opdChartAPI.getAllPriceMaster(param).subscribe({
      next:(res)=>{
         this.investigationTypeList = res;
      },
      error:(e)=>{

      }
    })
  }
  addInvestigation()
  {

  if(this.temp_Object == undefined)
  {
   this.toast.error("Select Investigation Type")
  }
  else
  {
   
    this.investigationObject.investigation_name = this.temp_Object.item;  
    this.investigationObject.item_id = this.temp_Object.id;  
    this.investigationList.push(this.investigationObject)
    this.investigationObject =  {
      investigation_name:"",
      item_id:"",
      priority:"",
      remarks:"",
     }
  }
 
  }
  copyTemplate(item:any)
  {
    this.investigationList =  [...this.investigationList , ...item.data] ;
  
  }
  removeInvestigation(i:any)
  {
    this.investigationList.splice(i, 1)
  }
  ngAfterViewInit()
  {
    this.getPatientInvestigationData();
  }
  saveInvestigation(){

    if(this.id)
    {
     this.btnSaveLoading = true;
     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       id:this.id,
       app_id:this.app_data.appointment.app_id,
       investigations:this.investigationList,
       }
 
 
      this.opdChartAPI.updateOpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnSaveLoading = false;
           this.toast.success("Data Saved successfully...")
           this.getPatientInvestigationData();
          this.clearData()
         }
       },error:(e)=>{
         this.btnSaveLoading = false;
       }
      })
    }
    else{
      this.btnSaveLoading = true;
     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       patient_id:this.app_data.id,
       app_id:this.app_data.appointment.app_id,
       medications:this.investigationList,
       }
       
   
      this.opdChartAPI.createOpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnSaveLoading = false;
           this.toast.success("Data Saved successfully...")
           this.clearData()
           this.getPatientInvestigationData();
         
         }
       },error:(e)=>{
         this.btnSaveLoading = false;
       }
      })
    }
  }
  getInvestigationTemplate(){
    let param = {
      where:{
      specialty: this.localStorage.getSpecialty(),
      }
    }
    this.opdChartAPI.getInvestigationTemplate(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.templateInvestigationList = res;
        }

      },
      error:(e)=>{
      }
     })
  }
  SaveInvestigationTemplate()
  {
     if(this.templateName)
     {
      let param = {
        title:this.templateName,
        data:this.investigationList,
        specialty: this.localStorage.getSpecialty(),
      }
      this.templateSaveButton = true;
         this.opdChartAPI.createInvestigationTemplate(param).subscribe({
          next:(res)=>{
            if(res)
            {
              this.toast.success("Template Added Successfully")
              this.templateSaveButton = false;
              this.getInvestigationTemplate()
            }
          },
          error:(e)=>{
            this.templateSaveButton = false;
          }
         })
     }
     else
     {
      this.toast.error("Template should not blank")
     }

  }

  OpenNewInvestigation(content:any)
  {
    this.modalService.open(content,  {  size:'xl', centered:true, scrollable: false,ariaLabelledBy: 'modal-basic-title' });
    this.loadPriceMasterItems();
    this.getInvestigationTemplate();
  }
  getPatientInvestigationData()
  {
    // console.log(this.app_data)
    this.loading = true;
   let param = {
    where:{
   hospital_id:this.localStorage.getHospitalId(),
    center_id:this.localStorage.getCenterId(),
    patient_id:this.current_patient_id,
    },
    attributes:['id','app_id','hospital_id','patient_id','center_id','investigations','createdAt']
  }
  this.opdChartAPI.getAllOpdChartData(param).subscribe({
    next:(res)=>{
     if(res)
     {
       this.count = res.filter((item:any)=>{return item.investigations}).length;
       this.investigationListData = res.filter((item:any)=>{return item.app_id != this.app_data.appointment.app_id && item.investigations });
       let existingObject  = res.filter((item:any)=>{return item.app_id == this.app_data.appointment.app_id })[0]
       if(existingObject!=undefined)
       {
         this.id =  existingObject.id || undefined;
         if(existingObject.investigations){
         this.currentTab = existingObject;
         }
       }
       this.layoutLoading = true;
       this.loading = false;
     
     }
 
    },
    error:(e)=>{
      this.loading = false;
    }
  })
  }
}
