import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IpdchartService } from 'src/app/private/services/ipdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-ipd-follow-up',
  templateUrl: './ipd-follow-up.component.html',
  styleUrls: ['./ipd-follow-up.component.scss']
})
export class IpdFollowUpComponent {
  @Input() titleOfHead: any = {
    title: 'IPD',
    root: 'Follow Up'
  };
  @Input() patient:any;

  btnSaveLoading:boolean = false;
  treatment_plan_object:any = {
    date:"",
    time:"",
    surgeon:"",
    chamber:"",
    condition:"",
    review:"",
  }
  treatment_plan_array:any = [];
  current_patient_id:any;
  ipd_app_id:any;
  id:any;
  currentTab:any;
  constructor(private toast:ToastrService,public route:ActivatedRoute,private ipdChartData:IpdchartService, private templateService:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.ipd_app_id = params['ipd_app_id']
      })
  }
  addTreatmentPlan()
  {
    this.treatment_plan_array.push(this.treatment_plan_object)
    this.treatment_plan_object = {
      date:"",
      time:"",
      surgeon:"",
      chamber:"",
      condition:"",
      review:"",
    }
  }
  ngAfterViewInit()
  {
    this.getPatientTreatmentPlanData();
  }
  getPatientTreatmentPlanData()
  {
    // console.log(this.app_data)

   let param = {
    where:{
   hospital_id:this.localStorage.getHospitalId(),
    center_id:this.localStorage.getCenterId(),
    patient_id:this.current_patient_id,
    },
    attributes:['id','ipd_app_id','hospital_id','patient_id','center_id','treatment_plan']
  }
  this.ipdChartData.getAllIpdChartData(param).subscribe({
    next:(res)=>{
     if(res)
     {
      // this.count = res.filter((item:any)=>{return item.investigations}).length;
     //  this.investigationListData = res.filter((item:any)=>{return item.app_id != this.ipd_app.appointment.app_id && item.investigations });
       let existingObject  = res.find((item:any)=>{return item.ipd_app_id == this.ipd_app_id })
       if(existingObject!=undefined)
       {
         this.id =  existingObject.id || undefined;
         if(existingObject.treatment_plan){
         this.currentTab = existingObject;
         this.treatment_plan_array = existingObject.treatment_plan;
         }
       }


     }

    },
    error:(e)=>{

    }
  })
  }
  saveTreatmentPlan()
  {
    if(this.id)
    {
     this.btnSaveLoading = true;
     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       id:this.id,
       ipd_app_id:this.ipd_app_id,
       treatment_plan:this.treatment_plan_array,
       }
      this.ipdChartData.updateIpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnSaveLoading = false;
           this.toast.success("Data Saved successfully...")
         }
       },error:(e)=>{
         this.btnSaveLoading = false;
       }
      })
    }
    else{
      this.btnSaveLoading = true;

     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       patient_id:this.current_patient_id,
       ipd_app_id:this.ipd_app_id,
       treatment_plan:this.treatment_plan_array,
       }

      this.ipdChartData.createIpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnSaveLoading = false;
           this.toast.success("Data Saved successfully...")

         }
       },error:(e)=>{
         this.btnSaveLoading = false;
       }
      })
    }
  }
}
