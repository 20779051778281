import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from 'src/app/private/services/appointment.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';


@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss']
})
export class CreateAppointmentComponent {
  active = 1;
  appointment:Appointment =  new Appointment();
  appointmentTypeList:any = [];
  departmentListDp:any = [];
  DoctorDpList:any = [];
  DateList:any = [];
  TimeList:any = [];
  @ViewChild("appointmentForm")
  appointmentForm!: NgForm;
  @ViewChild("appointmentFormFolloup")
  appointmentFormFolloup!: NgForm;
  isSubmitted:any = false;
  isSubmittedFolloup = false;
  btnLoading:boolean = false;
  findQuery:any;
  filterType:any = "";
  @Output("close") close: EventEmitter<any> = new EventEmitter();
  @Output("reload") reload: EventEmitter<any> = new EventEmitter();
  hideDropdown:boolean = true;
  loadingSpinnerDep:boolean = false;
  loadingSpinnerDoc:boolean = false;
  loadingSpinnerDate:boolean = false;
  loadingSpinnerTime:boolean = false;
  loadingSpinnerType:boolean = false;
  timeZone:any = Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Calcutta";
  doctorLabel:any = "Doctor";
  constructor(private toast:ToastrService,private localStorage:LocalStorageService,private api:AppointmentService) {}
  closeModal()
  {
     this.close.emit('close');
  }
  
  ngAfterContentInit()
  {
    this.loadDepartments()
    console.log(this.timeZone)
  }
  findPaitendData()
  {
    let type = this.filterType;
    let query = this.findQuery
    let object:any = {}
    object[type] = query;
 
    let data = {
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      filter:object
    }
    ;
    this.api.getPatientBySearch(data).subscribe({
      next:(res) =>{
        if(res)
        {
          this.appointment = res;
          this.appointment.dept_id = ""
          this.appointment.patient_id = res.id;
        }
        else
        {
          this.toast.error("Please enter valid data")
        }
  
      },
      error:(e)=>{
        this.toast.error("Please enter valid data")
      }
    })
  }
  loadDepartments()
  {
    let param = {
      where:{
        hospital_id:this.localStorage.getHospitalId(),
        // center_id:this.localStorage.getCenterId(),
        isPublic:true,
      }
      
    }
   this.loadingSpinnerDep = true 
   this.api.getDepartments(param).subscribe({
    next:(response)=>{
     // console.log(response)
     if(response && response.length > 0){
      this.departmentListDp = response
      this.appointment.dept_id = this.departmentListDp[0].dept_id
      this.loadDoctorsByDepartmentId(this.departmentListDp[0].dept_id)
      this.loadingSpinnerDep = false 
     }
    
    },
    error:(e)=>{
      this.loadingSpinnerDep = false 
    }
   })
  }
  loadDoctorsByDepartments(event:any)
  {
  
    if(event.target.value)
    {

  
        let dep = this.departmentListDp.find((item:any)=> item.dept_id == event.target.value);
        if(dep.dept_name=="Therapist"){
          this.doctorLabel = "Therapist"
        } else if(dep.dept_name=="Psychiatrist"){
          this.doctorLabel = "Psychiatrist"
        }else{
          this.doctorLabel = "Doctor"
        }

      

    let param = {
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        dept_id:event.target.value,
    }
    if(this.localStorage.getIgnoreCenterCheck()=="true"){
      delete param.center_id;
    }
    this.loadingSpinnerDoc = true;
    this.api.getUserByDepartmentSchedule(param).subscribe({
      next:(response)=>{
       console.log(response)
        this.DoctorDpList = response
        this.loadingSpinnerDoc = false;
      },
      error:(e)=>{
        this.loadingSpinnerDoc = false;
      }
     })
    }
    else {
      this.DoctorDpList = []
      this.resetDropdowns();
      this.appointment.user_id = ""
    }
  }
  loadDoctorsByDepartmentId(dept_id:any)
  {
  
    if(dept_id)
    {

  
        let dep = this.departmentListDp.find((item:any)=> item.dept_id == dept_id);
        if(dep.dept_name=="Therapist"){
          this.doctorLabel = "Therapist"
        } else if(dep.dept_name=="Psychiatrist"){
          this.doctorLabel = "Psychiatrist"
        }else{
          this.doctorLabel = "Doctor"
        }

      

    let param = {
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        dept_id:dept_id,
    }
    if(this.localStorage.getIgnoreCenterCheck()=="true"){
      delete param.center_id;
    }
    this.loadingSpinnerDoc = true;
    this.api.getUserByDepartmentSchedule(param).subscribe({
      next:(response)=>{
       console.log(response)
        this.DoctorDpList = response
        this.loadingSpinnerDoc = false;
      },
      error:(e)=>{
        this.loadingSpinnerDoc = false;
      }
     })
    }
    else {
      this.DoctorDpList = []
      this.resetDropdowns();
      this.appointment.user_id = ""
    }
  }

  getType(event:any)
  {
    this.resetDropdowns();
    const user_id = event.target.value;
    if(user_id)
    {
     
      let param = {
        where: {
          hospital_id:this.localStorage.getHospitalId(),
          user_id:user_id,
          center_id:this.localStorage.getCenterId(),
          dept_id:this.appointment.dept_id,
        }
    }
    if(this.localStorage.getIgnoreCenterCheck()=="true"){
      delete param.where.center_id;
    }
    this.loadingSpinnerType = true;
    this.appointmentTypeList.push('walk_in')
    this.appointmentTypeList.push('emergency')
    this.api.getSchedule(param).subscribe({
      next:(response)=>{
       //console.log(response)
       if(response && response.status)
       {
        const schedule = response.schedule;
        this.loadingSpinnerType = false;
        for (const key in schedule) {
          if (Object.prototype.hasOwnProperty.call(schedule, key)) {
            const element = schedule[key];
            if(element.isTrue)
            {
              this.appointmentTypeList.push(key)
            }
           // console.log(element)

          }
        }

         this.appointment.appointment_type = "";
       }
       else
       {
        this.loadingSpinnerType = false;
        this.toast.error("Selected User is offline at this moment")
       }
        
      },
      error:(e)=>{
        this.loadingSpinnerType = false;
      }
     })
    }
    else
    {
      this.appointment.user_id = ""
    }

  }
  resetDropdowns()
  {
    this.appointmentTypeList = []
    this.DateList = [];
    this.TimeList = [];
    this.appointment.appointment_time = ""
    this.appointment.appointment_date = ""
    this.hideDropdown = true;

  }
  createFolloupAppointment(isValid:any)
  {
    this.isSubmittedFolloup = true;
    if(isValid)
    {
      this.btnLoading = true;
      this.appointment.hospital_id = this.localStorage.getHospitalId()
      this.appointment.center_id = this.localStorage.getCenterId()
      this.appointment.check_in_type = "follow-up"
      this.appointment.appointment_source = "webapp"
      this.appointment.timezone = this.localStorage.getTimeZone()
      this.appointment.status = "booked"

      this.api.createAppointment(this.appointment).subscribe({
        next:(res)=>{
          this.btnLoading = false;
          this.reload.emit(true)
          this.closeModal();
          this.toast.success("Follow-Up Appointment Booked Successfully")
        },
        error:(e)=>{
          this.btnLoading = false;
        }
      })
      console.log(this.appointment)
    }

  }
  saveAppointment(isValid:any)
  {

    this.isSubmitted = true;
    if(isValid)
    {
      this.btnLoading = true;
      this.appointment.hospital_id = this.localStorage.getHospitalId()
      this.appointment.center_id = this.localStorage.getCenterId()
      this.appointment.check_in_type = "new"
      this.appointment.timezone = this.localStorage.getTimeZone()
      if(this.appointment.patient_id)
      {
        this.appointment.check_in_type = "follow-up"
        this.appointment.appointment_source = "webapp"
        this.appointment.status = "booked"
      }

      this.api.createAppointment(this.appointment).subscribe({
        next:(res)=>{
          this.btnLoading = false;
          this.reload.emit(true)
          this.closeModal();
          this.toast.success("Appointment Booked Successfully")
        },
        error:(e)=>{
          this.btnLoading = false;
        }
      })
     // console.log(this.appointment)
    }

  }
  loadDates(event:any)
  {

    const type = event.target.value;
    let lo = type.toLowerCase()
    if(lo=="walk_in" || lo=="emergency" || lo=='' )
    {
  
      this.hideDropdown = true;
     
    }
    else
    {
      this.hideDropdown = false;
      let param = {
        where: {
          hospital_id:this.localStorage.getHospitalId(),
          user_id:this.appointment.user_id,
          center_id:this.localStorage.getCenterId(),
          dept_id:this.appointment.dept_id,
        },
        appointment_type:lo,
        timezone: this.localStorage.getTimeZone(),
        dateFormat: this.localStorage.getDateFormat()
    }
    if(this.localStorage.getIgnoreCenterCheck()=="true"){
      delete param.where.center_id;
    }
    this.loadingSpinnerDate = true;
        this.api.getAvailableDays(param).subscribe({
          next:(response)=>{
             console.log(response)

             if(response.length > 0 )
             {
              this.DateList = response;
             }
             else
             {
              this.toast.error("No Dates Available")
             }
             this.loadingSpinnerDate = false;
          },
          error:(e)=>{
            this.loadingSpinnerDate = false;
          }
        })
    }
    
    
  }
  fnCheckCondition(item:any)
  {

    let flag:boolean = false;

    if((item.appointment_type!='walk_in') || item.appointment_type!='emergency')
    {
      flag = true;
    }
  
 
    
    return flag

  }
  loadSlots(event:any)
  {

    const date = event.target.value;
    if(date!="")
    {
      let param = {
        where: {
          hospital_id:this.localStorage.getHospitalId(),
          user_id:this.appointment.user_id,
          center_id:this.localStorage.getCenterId(),
          dept_id:this.appointment.dept_id,
        },
        appointment_type:this.appointment.appointment_type,
        appointment_date:date,
        timezone: this.localStorage.getTimeZone(),
        dateFormat: this.localStorage.getDateFormat()
    }
    if(this.localStorage.getIgnoreCenterCheck()=="true"){
      delete param.where.center_id;
    }
   // console.log(param)
   this.loadingSpinnerTime = true;
        this.api.getSlots(param).subscribe({
          next:(response)=>{
             console.log(response)
             if(response.length > 0 )
             {
              this.TimeList = response;
             }
             else
             {
              this.toast.error("No Time Slots Available")
             }
             this.loadingSpinnerTime = false;
          },
          error:(e)=>{
            this.loadingSpinnerTime = false;
          }
        })
    }
    
    
  }
  removeSp(_string:any)
  {
   return _string.replace("_"," ")
  }
  getPatientRecord(event:any)
{
      this.appointment = event;
      //this.appointment.dept_id = ""
      this.appointment.user_id = ""
      this.appointment.patient_id = event.id;
}
calculateAge() {
  if (this.appointment.dob) {
    const today = new Date();
    const birthDate = new Date(this.appointment.dob);
    let age:any = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    this.appointment.age = age;
  }
}
formatTo12Hour(time: string): string {
  if(this.localStorage.isTwelveHourFormat()) {
    return moment(time, 'HH:mm').format('hh:mm A');
  }
  else {
    return time;
  }
}
calculateDOB() {
  if (this.appointment.age !== null) {
    let age:any = this.appointment.age;
    const today = new Date();
    const birthYear = today.getFullYear() - age;
    const birthMonth = today.getMonth();
    const birthDay = today.getDate();

    this.appointment.dob = new Date(birthYear, birthMonth, birthDay).toISOString().split('T')[0];
  }
}
}




export class Appointment {
  fname: string = "";
  lname: string = "";
  patient_id!:Number;
  prefix: string = "";
  gender: string = "N/A";
  user_id: string = "";
  age: Number = 0;
  uid_number:string = "";
  abha_number:string = "";
  blood_group:string = "";
  email: string = "";
  mobile: string = "";
  address: string = "";
  check_in_type:string = "new";
  appointment_source:string = "webapp";
  appointment_type:string = "";
  appointment_date:string = "";
  appointment_time:string = "";
  dept_id:string = "";
  client_id:string = ""
  hospital_id:Number = 0
  center_id:Number = 0;
  status:string = "booked";
  timezone: string = "";
  ref_by:string = "";
  notes:string = "";
  dob:string = "";
}
