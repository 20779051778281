import { Component, OnInit } from '@angular/core';
import { JWTTokenServiceService } from 'src/app/public/services/token.service';
import { HomeService } from '../../services/home.service';
import { AnalyticsService } from '../../services/analytics.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { PermissionService } from '../../services/permission.service';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  active = 1;
  data: any;
  leadLoading: boolean = false;
  appointmentLoading: boolean = false;
  leadsAnalytics: any;
  appointmentAnalytics: any;
  opthoAnalytics: any;
  opthoAnalyticsLoading: boolean = false;
  currentTab = "leads";
  totalLeads: any = 0;
  chatBotAnalytics: any;
  constructor(private jwt: JWTTokenServiceService, private api: AnalyticsService, private localStorage: LocalStorageService, private checkPermission: PermissionService) {
    // console.log(jwt.isTokenExpired())
    //console.log(jwt.getDecodeToken())
  }

  _check(module: any, feature: any) {

    if (this.checkPermission.getFeatureModuleAccess(module) && this.checkPermission.getfeatureAccess(module, feature)) {
      return true
    } else {
      return false
    }

  }
  ngOnInit(): void {
    this.fnGetLeadAnalytics();
  }

  fnChangeTab(event: any) {
    this.currentTab == event;
    console.log(event)
    if (event == 'appointment') {
      this.fnGetAppointmentAnalytics();
    }
    else if (event == 'lead') {
      this.fnGetLeadAnalytics();
    }
    else if (event == 'chatbot') {
      this.fnChatbotAnalyticsData();
    }
    else if (event == 'Optometrist') {
      console.log("hello")
      this.opthoAnalytics = {
        platform_wise_appointments: { "tina": { "Apr": 0, "Mar": 10, "Feb": 2, "Jan": 0, "Dec": 0, "Nov": 0, "Oct": 0, "Sep": 0, "Aug": 0, "Jul": 0, "Jun": 0, "May": 0 }, "manual": { "Apr": 0, "Mar": 0, "Feb": 14, "Jan": 1, "Dec": 0, "Nov": 0, "Oct": 0, "Sep": 0, "Aug": 0, "Jul": 0, "Jun": 0, "May": 0 }, "whatsapp": { "Apr": 0, "Mar": 0, "Feb": 0, "Jan": 0, "Dec": 0, "Nov": 0, "Oct": 0, "Sep": 0, "Aug": 0, "Jul": 0, "Jun": 0, "May": 0 } }
      }
      this.opthoAnalyticsLoading = true;
    }

    console.log("opto", this.opthoAnalytics)
    console.log("leads", this.opthoAnalyticsLoading)
  }

  fnChatbotAnalyticsData() {
    let param = {
      // client_id: this.localStorage.getClientId(),
      client_id: "6f20b077-dc46-467d-9e80-fd7027169e9d",
    }

    this.api.getChatbotAnalytics(param).subscribe({
      next: (res) => {
        console.log(res)
        if (res) {
          this.chatBotAnalytics = res;
          this.chatBotAnalytics.user_ratings = {
            "5": 80,
            "4": 45,
            "3": 15,
            "2": 10,
            "1": 3,
          }
          this.chatBotAnalytics.user_feedbacks = {
            "negative": 0,
            "positive": 52
          }
        } else {
          this.chatBotAnalytics = {
            "user_data": {
              "0": 0,
              "1": 0,
              "2": 0,
              "3": 0,
              "4": 0,
              "5": 0,
              "6": 0,
              "7": 0,
              "8": 0,
              "9": 0,
              "10": 0,
              "11": 0
            },
            "common_disease": "",
            "avg_waiting_time": 0,
            "hot_leads": 0,
            "total_users": 0,
            "total_messages": 0,
            "old_appointment": 0,
            "active_hours": 0,
            "top_active_hours": {
              "13:00-14:00": 0,
              "15:00-16:00": 0,
              "11:00-12:00": 0,
              "09:00-10:00": 0,
              "05:00-06:00": 0
            },
            "unique_users": "0",
            "total_leads_last_month": {
              "whatsapp": 0,
              "youtube": 0,
              "total": 0,
              "twitter": 0,
              "messenger": 0,
              "facebook": 0,
              "instagram": 0,
              "linkedin": 0,
              "tina": 0
            },
            "new_appointment": 0,
            "user_feedbacks": {
              "negative": 0,
              "positive": 0
            },
            "user_ratings": {
              "5": 0,
              "4": 0,
              "3": 0,
              "2": 0,
              "1": 0,
            },
            "total_ratings": 0,
            "cold_leads": 0,
            "leads": {
              "whatsapp": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0,
                "11": 0
              },
              "youtube": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0,
                "11": 0
              },
              "twitter": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0,
                "11": 0
              },
              "messenger": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0,
                "11": 0
              },
              "facebook": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0,
                "11": 0
              },
              "instagram": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0,
                "11": 0
              },
              "linkedin": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0,
                "11": 0
              },
              "tina": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0,
                "11": 0
              }
            },
            "total_app": 0,
            "populer_topic": {},
            "pending_appointment": 0,
            "location": "Asia/Calcutta",
            "done_appointment": 0,
            "client_id": "b0808768-9544-4e5d-820c-9f1f35402e9c",
            "total_leads": 0,
            "popular_topic": {
              "Glaucoma": "0",
              "Cataract": "0",
              "clinic": "0",
              "cataract": "0"
            },
            "total_feedback": 0,
            "appStatus": {
              "confirmed": 0,
              "cancelled": 0,
              "rescheduled": 0
            },
            "top_intent": {
              "Appointment_book_bot": "0",
              "Appointment_book_bot_demo": "0",
              "User_Form": "0",
              "TinaOphthalWelcome": "0",
              "Ophthal_Treatment": "0"
            },
            "total_appointments": 0
          }
        }


      },
      error: (e) => {
        this.chatBotAnalytics = {
          "user_data": {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0
          },
          "common_disease": "",
          "avg_waiting_time": 0,
          "hot_leads": 0,
          "total_users": 0,
          "total_messages": 0,
          "old_appointment": 0,
          "active_hours": 0,
          "top_active_hours": {
            "13:00-14:00": 0,
            "15:00-16:00": 0,
            "11:00-12:00": 0,
            "09:00-10:00": 0,
            "05:00-06:00": 0
          },
          "unique_users": "0",
          "total_leads_last_month": {
            "whatsapp": 0,
            "youtube": 0,
            "total": 0,
            "twitter": 0,
            "messenger": 0,
            "facebook": 0,
            "instagram": 0,
            "linkedin": 0,
            "tina": 0
          },
          "new_appointment": 0,
          "user_feedbacks": {
            "negative": 0,
            "positive": 0
          },
          "user_ratings": {
            "5": 0,
            "4": 0,
            "3": 0,
            "2": 0,
            "1": 0,
          },
          "total_ratings": 0,
          "cold_leads": 0,
          "leads": {
            "whatsapp": {
              "0": 0,
              "1": 0,
              "2": 0,
              "3": 0,
              "4": 0,
              "5": 0,
              "6": 0,
              "7": 0,
              "8": 0,
              "9": 0,
              "10": 0,
              "11": 0
            },
            "youtube": {
              "0": 0,
              "1": 0,
              "2": 0,
              "3": 0,
              "4": 0,
              "5": 0,
              "6": 0,
              "7": 0,
              "8": 0,
              "9": 0,
              "10": 0,
              "11": 0
            },
            "twitter": {
              "0": 0,
              "1": 0,
              "2": 0,
              "3": 0,
              "4": 0,
              "5": 0,
              "6": 0,
              "7": 0,
              "8": 0,
              "9": 0,
              "10": 0,
              "11": 0
            },
            "messenger": {
              "0": 0,
              "1": 0,
              "2": 0,
              "3": 0,
              "4": 0,
              "5": 0,
              "6": 0,
              "7": 0,
              "8": 0,
              "9": 0,
              "10": 0,
              "11": 0
            },
            "facebook": {
              "0": 0,
              "1": 0,
              "2": 0,
              "3": 0,
              "4": 0,
              "5": 0,
              "6": 0,
              "7": 0,
              "8": 0,
              "9": 0,
              "10": 0,
              "11": 0
            },
            "instagram": {
              "0": 0,
              "1": 0,
              "2": 0,
              "3": 0,
              "4": 0,
              "5": 0,
              "6": 0,
              "7": 0,
              "8": 0,
              "9": 0,
              "10": 0,
              "11": 0
            },
            "linkedin": {
              "0": 0,
              "1": 0,
              "2": 0,
              "3": 0,
              "4": 0,
              "5": 0,
              "6": 0,
              "7": 0,
              "8": 0,
              "9": 0,
              "10": 0,
              "11": 0
            },
            "tina": {
              "0": 0,
              "1": 0,
              "2": 0,
              "3": 0,
              "4": 0,
              "5": 0,
              "6": 0,
              "7": 0,
              "8": 0,
              "9": 0,
              "10": 0,
              "11": 0
            }
          },
          "total_app": 0,
          "populer_topic": {},
          "pending_appointment": 0,
          "location": "Asia/Calcutta",
          "done_appointment": 0,
          "client_id": "b0808768-9544-4e5d-820c-9f1f35402e9c",
          "total_leads": 0,
          "popular_topic": {
            "Glaucoma": "0",
            "Cataract": "0",
            "clinic": "0",
            "cataract": "0"
          },
          "total_feedback": 0,
          "appStatus": {
            "confirmed": 0,
            "cancelled": 0,
            "rescheduled": 0
          },
          "top_intent": {
            "Appointment_book_bot": "0",
            "Appointment_book_bot_demo": "0",
            "User_Form": "0",
            "TinaOphthalWelcome": "0",
            "Ophthal_Treatment": "0"
          },
          "total_appointments": 0
        }
      },
      complete: () => { }
    })




  }

  fnGetLeadAnalytics() {

    let param = {
      hospital_id: this.localStorage.getHospitalId(),
      year: new Date().getFullYear()
    }
    this.api.getLeadAnalytics(param).subscribe({
      next: (res) => {
        if (res) {

          this.leadsAnalytics = res;
          // console.log(this.leadsAnalytics)
          this.leadLoading = true;
          this.totalLeads = res.total_leads
        }
      },
      error: (e) => {
        this.leadLoading = true;
        this.leadsAnalytics = {
          "platform_wise_leads": {
            "tina": {
              "Mar": 0,
              "Feb": 0,
              "Jan": 0,
              "Dec": 0,
              "Nov": 0,
              "Oct": 0,
              "Sep": 0,
              "Aug": 0,
              "Jul": 0,
              "Jun": 0,
              "May": 0,
              "Apr": 0
            },
            "manual": {
              "Mar": 0,
              "Feb": 0,
              "Jan": 0,
              "Dec": 0,
              "Nov": 0,
              "Oct": 0,
              "Sep": 0,
              "Aug": 0,
              "Jul": 0,
              "Jun": 0,
              "May": 0,
              "Apr": 0
            },
            "whatsapp": {
              "Mar": 0,
              "Feb": 0,
              "Jan": 0,
              "Dec": 0,
              "Nov": 0,
              "Oct": 0,
              "Sep": 0,
              "Aug": 0,
              "Jul": 0,
              "Jun": 0,
              "May": 0,
              "Apr": 0
            }
          },
          "leads_types": {
            "Hot Leads": "0",
            "Cold Leads": "0",
            "Warm Leads": "0",
            "New Leads": "0"
          },
          "leads_status": {
            "Completed": "0",
            "Follow Up": "0",
            "Communicated": "0",
            "Pending": "0"
          },
          "total_leads": "0",
          "completed_leads": "0",
          "pending_leads": "0",
          "communicated_leads": "0",
          "followup_leads": "0"
        };
      }
    })
  }
  fnGetAppointmentAnalytics() {
    let param = {
      hospital_id: this.localStorage.getHospitalId(),
      year: new Date().getFullYear()
    }
    this.api.getAppointmentAnalytics(param).subscribe({
      next: (res) => {
        if (res) {
          console.log("res", JSON.stringify(res))
          this.appointmentAnalytics = res;
          this.appointmentLoading = true;
        }
      },
      error: (e) => {
        this.appointmentLoading = true;
        this.appointmentAnalytics = {
          "platform_wise_leads": {
            "tina": {
              "Mar": 0,
              "Feb": 0,
              "Jan": 0,
              "Dec": 0,
              "Nov": 0,
              "Oct": 0,
              "Sep": 0,
              "Aug": 0,
              "Jul": 0,
              "Jun": 0,
              "May": 0,
              "Apr": 0
            },
            "manual": {
              "Mar": 0,
              "Feb": 0,
              "Jan": 0,
              "Dec": 0,
              "Nov": 0,
              "Oct": 0,
              "Sep": 0,
              "Aug": 0,
              "Jul": 0,
              "Jun": 0,
              "May": 0,
              "Apr": 0
            },
            "whatsapp": {
              "Mar": 0,
              "Feb": 0,
              "Jan": 0,
              "Dec": 0,
              "Nov": 0,
              "Oct": 0,
              "Sep": 0,
              "Aug": 0,
              "Jul": 0,
              "Jun": 0,
              "May": 0,
              "Apr": 0
            }
          },
          "appointment_types": {
            "New ": "0",
            "Follow Up": "0"
          },
          "appointment_visits": {
            "Clinic": "0",
            "Online": "0",
            "Home Visit": "0"
          },
          "appointment_status": {
            "Completed": "0",
            "Check In": "0",
            "Check Out": "0",
            "Pending": 0,
            "Rescheduled": "0",
            "Cancelled": "0"
          },
          "total": "0",
          "completed": "0",
          "pending": 0,
          "check_in": "0",
          "cancelled": "0"
        }
      }
    })
  }


}
