import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MasterService } from 'src/app/private/services/master.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-medication-dosage',
  templateUrl: './medication-dosage.component.html',
  styleUrls: ['./medication-dosage.component.scss']
})
export class MedicationDosageComponent {

  resultArray:any = []
  TemplateList:any = []
  showPopup:boolean = false;
  selectOrgan:any = "";
  @Input() URL:any;
  @Input() PLACEHOLDER:any;
  @Input() DATA:any = [];
  @Input() CLEAR:Boolean = false;
  object:any = {
    duration_count:"1",
    duration:"day(s)",
    frequency:"1",
    drug_instruction:"",
  }
  @Output("inputValue") inputValue: EventEmitter<any> = new EventEmitter();
  constructor(private localStorage:LocalStorageService,private api:MasterService,private templateService:TemplateService)
  {
  }
  onChange(event:any) {

  }
  ngOnChanges(changes: SimpleChanges)
  {
 
   // alert(changes)
    this.resultArray = this.DATA;
  }
  ngOnInit(){
    //console.log(this.DATA)
    this.resultArray = this.DATA;
  }
  removeTag(item:any)
  {
    this.resultArray = this.resultArray.filter((value:any)=> value!=item);
    this.inputValue.emit(this.resultArray)
  }
  openTemplate()
  {
    this.showPopup = true;

  }
  closeTemplate()
  {
    this.showPopup = false;
  }

  searchRecord(event:any)
  {
    //console.log(event)
    this.TemplateList.data  = this.TemplateList.data.filter((item:any)=> item == event)
  }
  onKeyUp(event:any)
  {
    const inputValue: string = event.target.value;
    //this.searchRecord(inputValue)
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag(event.target.value);
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Enter') {
   
        let existingArray = []
        let value:any =  inputValue;
         existingArray = this.resultArray || [];
         this.resultArray = existingArray.concat(value);
         this.inputValue.emit(this.resultArray)
         this.showPopup = false;
        event.target.value = ""
      }
    }
  }
  addDosage()
{
  let instruction = (this.resultArray.length>0 ? "Next" : "First")
  let str = this.object.frequency+" Times in a "+this.object.duration+" For "+instruction+" "+this.object.duration_count+" "+this.object.duration + " "+this.object.drug_instruction
  let existingArray = []
  let value:any =  str;
   existingArray = this.resultArray || [];
   this.resultArray = existingArray.concat(value);
   this.inputValue.emit(this.resultArray)
   this.showPopup = false;
   this.object  = {
    duration_count:"1",
    duration:"day(s)",
    frequency:"1",
    drug_instruction:"",
  }
}

  
}
