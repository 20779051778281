import { Component, ViewChild ,TemplateRef} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { MasterService } from 'src/app/private/services/master.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-procedure-package',
  templateUrl: './procedure-package.component.html',
  styleUrls: ['./procedure-package.component.scss']
})
export class ProcedurePackageComponent {
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  priceSettings:PriceSettings = new PriceSettings();
  @ViewChild("priceSettingsForm")
  priceSettingsForm!: NgForm;
  isSubmitted:boolean = false
  procedureList:any = [];
  procedure:any  = {
    name:"",
    status:"",
    package:[],
  }
  id:any;
  pList:any = "";
  procedures:any = [];
  formAction = 'add'
  categoryList:any = [];
  displayedColumns: string[] = ["item",'price',"date","action"];
  constructor(private localStorage: LocalStorageService, private toast:ToastrService,private offcanvasService: NgbOffcanvas,private api:MasterService,private templateService:TemplateService){}
  searchFilter(query:any){}

  openSeeting(content: TemplateRef<any>) {

		this.offcanvasService.open(content, { position: 'end' });
	}
  edit(content:any,object:any){
    this.formAction = "update";
    this. getPriceCategories();
    this.offcanvasService.open(content, { position: 'end' });
    this.procedure = object;
    this.id = object.id;
    this.procedures =  object.package
   
  }
  remove(item:any)
  {
    this.procedures =    this.procedures.filter((row:any)=> row.id != item.id)
  }
  fnAddProcedurePackage(event:any)
  {
    let value = event.target.value;
    if(value)
    {
      let row =  this.procedureList.find((item:any)=> item.id == value );
      let exist =  this.procedures.find((row:any)=> row.id == value );
      if(!exist)
      {
        this.procedures.push({
          cat_id:row.cat_id,
          id:row.id,
          price:row.price,
          procedure_name:row.item
        })
        this.pList = "";
      }
     
    }
  
  }
  getPriceDetails(id:any)
  {
    let param = {
      id:id,
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
    }
     this.api.getPriceMaster(param).subscribe({next:(res)=>{this.priceSettings = res || {}},error:(e)=>{}})
  }
  open(content:any){
    this.openSeeting(content)
    this.formAction = 'add'
    this.priceSettings =  new PriceSettings();
    this. getPriceCategories();
  }
  getPriceCategories()
  {
    let param = {

      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      cat_id:2
    
  }
  this.templateService.getDynamicTemplateService(param,environment.getAllPriceMaster).subscribe({
    next:(res)=>{
      if(res)
      {
        this.procedureList = res;
      }
    },
    error:(e)=>{

    }
  })
  }

  getPrice(item:any)
  {
    let total = 0;
    if(item.package)
    {
      item.package.forEach((element:any) => {
        total  = total + element.price;
      });
    }
  
    return total;
  }

  save(){


  if(this.procedure.name == "")
  {
    this.toast.error("Please enter package name")
  }
  else if(this.procedures.length == 0)
  {
    this.toast.error("Please add at-least one procedure in package")
  }
  else if(this.procedure.status == "")
  {
    this.toast.error("Please select package status")
  }
  
  else
  {
    this.isLoading = true
    //  alert(this.formAction)
      this.priceSettings.hospital_id = this.localStorage.getHospitalId();
      this.priceSettings.center_id = this.localStorage.getCenterId();
      if(this.id == undefined)
      {
        //console.log(this.priceSettings);
        let param = {
           hospital_id: this.localStorage.getHospitalId(),
           center_id: this.localStorage.getCenterId(),
           name:this.procedure.name,
           package:this.procedures,
           status:this.procedure.status
        }
        console.log(param)
       this.templateService.createProcedurePackage(param).subscribe({
        next:(res)=>{
         this.loadData();
         this.offcanvasService.dismiss();
         this.toast.success("Package Added Successfully",'Success')
         this.isLoading = false
        },
        error:(e)=>{  
          this.toast.error(e.error.msg,'Error')
          this.isLoading = false

          
        }
       })
      }
      else if(this.id)
      {
        let param = {
          hospital_id: this.localStorage.getHospitalId(),
          center_id: this.localStorage.getCenterId(),
          name:this.procedure.name,
          package:this.procedures,
          status:this.procedure.status,
          id:this.id
       }
        this.templateService.updateProcedurePackage(param).subscribe({
          next:(res)=>{
           this.loadData();
           this.offcanvasService.dismiss();
           this.toast.success("Package Update Successfully",'Success')
           this.isLoading = false

          },
          error:(e)=>{  
            this.toast.error(e.error.msg,'Error')
            this.isLoading = false

     
          }
         })
      
    }
  }
    
  }
  ngAfterViewInit(){this.loadData();}
  loadData()
  {
    try{
   this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
   merge(this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
   switchMap(()=>{
    let param = {
      where:{hospital_id:this.localStorage.getHospitalId(),center_id:this.localStorage.getCenterId()},
      page:(this.paginator.pageIndex *  this.paginator.pageSize),
      limit:this.paginator.pageSize,
      filter:this.searchQuery.value,
    }
    return  this.templateService.getProcedurePackageList(param)
    .pipe(catchError(() => observableOf(null)));
   }),map((response:any)=>{
    if (response === null) {
      return [];
    }
 
    this.totalRows = response.count;
    return response
   })
   ).subscribe({
    next:(data) =>{
    this.dataSource = new MatTableDataSource<any>(data.rows);
    },
    error:(e)=>{
     console.error(e)
    },
    complete:()=>{
     
    }
   })
  }
  catch(e){
   console.error(e)
  }
  }
}

export class PriceSettings {
  id!: Number;
  cat_id:String = "";
  item: string = "";
  item_code: string = "";
  price: Number = 0.0;
  hospital_id:Number = 0;
  center_id:Number = 0;
}

function observableOf(arg0: null): any {
  throw new Error('Function not implemented.');
}
