import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private http: HttpClient) { }

  createSubscriberList(data:any): Observable<any> {
    return this.http.post(environment.createSubscriberList,data);
  }
  getAllSubscriberList(data:any): Observable<any> {
    return this.http.post(environment.getAllSubsciberList,data);
  }
  deleteSubscriberList(data:any): Observable<any> {
    return this.http.post(environment.deleteSubsciberList,data);
  }
  getSubsciberList(data:any): Observable<any> {
    return this.http.post(environment.getSubsciberList,data);
  }
  getAllTemplates(data:any): Observable<any> {
    return this.http.post(environment.getAllTemplates,data);
  }

  addCampaignList(data:any): Observable<any> {
    return this.http.post(environment.createCampaignList,data);
  }

  updateCampaignList(data:any): Observable<any> {
    return this.http.post(environment.updateCampaignList,data);
  }

  getAllCampaignList(data:any): Observable<any> {
    return this.http.post(environment.getAllCampaignList,data);
  }
  deleteCampaignList(data:any): Observable<any> {
    return this.http.post(environment.deleteCampaignList,data);
  }

  sendWhatsappMsg(data:any): Observable<any> {
    return this.http.post(environment.sendWhatsappMsg,data);
  }

  sendMediaTemplateMsg(data:any): Observable<any> {
    return this.http.post(environment.sendMediaTemplateMsg,data);
  }
  getAllCampaignWithCount(data:any): Observable<any> {
    return this.http.post(environment.getAllCampaignWithCount,data);
  }

  runCampaignWhatsapp(data:any): Observable<any> {
    return this.http.post(environment.runCampaignWhatsapp,data);
  }

  runCampaignEmail(data:any): Observable<any> {
    return this.http.post(environment.runCampaignEmail,data);
  }
  runCampaignTextMessage(data:any): Observable<any> {
    return this.http.post(environment.runCampaignTextMessage,data);
  }
  

  
}
