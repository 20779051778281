import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-rating-bar-chart',
  templateUrl: './rating-bar-chart.component.html',
  styleUrls: ['./rating-bar-chart.component.scss']
})
export class RatingBarChartComponent {
  @Input() data:any;
  @Input() id:any;
  @Input() Title:any;
  @Input() colors: any;
  ngAfterViewInit()
  {
    this.colors = this.colors || ['#104358', '#1369A3', '#469BD3', '#74A3C3']
    const analysis_daily_opt:any = document.getElementById(this.id);
    const analysis_daily_ctx = analysis_daily_opt.getContext('2d');
    let chartStatus = Chart.getChart(this.id); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    var keys = [];
    var values = [];
    if(this.data)
    {
    for (var key in this.data) {
      keys.push(key);
        values.push(this.data[key])
    }
   new Chart(analysis_daily_ctx, {
      type: 'bar',
       data : {
        labels: keys,
        datasets: [{
          label: '',
          data: values,
          backgroundColor: [
            this.colors[0] ,
            this.colors[1] ,
            this.colors[2],
            this.colors[3] ,

          ],
          borderWidth: 1
        }]
      },
      options: {
        plugins: {
          legend: {
              display: false
          },
      },
        responsive: false,
        aspectRatio: 3,
        // display: true,
        scales: {
          
          x: {
            border:{
              display:false
            },
            grid: {
              display: false,
              
            },
            ticks: {
              display: false,
            
            },
          },
          y: {
            border:{
              display:false
            },
            grid: {
              display: false
            },
            ticks: {
              display: false,
            },
          },
        },
      },
    })
  }
  }
}
