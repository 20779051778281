import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IpdAppointmentService {

  constructor(private http: HttpClient) { }

  getAllIpdAppointmentWithCount(data:any): Observable<any> {
    return this.http.post(environment.getAllIpdAppointmentWithCount,data);
  }
  
  getPatient(data:any): Observable<any> {
    return this.http.post(environment.getPatient,data);
  }
  createIPDAppointment(data:any): Observable<any> {
    return this.http.post(environment.createIPDAppointment,data);
  }
  getIPDAppointmentDetails(data:any): Observable<any> {
    return this.http.post(environment.getIPDAppointmentDetails,data);
  }

  checkInIpdPatientData(data:any): Observable<any> {
    return this.http.post(environment.checkInIpdPatientData,data);
  }
  updateIpdAppointment(data:any): Observable<any> {
    return this.http.post(environment.updateIpdAppointment,data);
  }
  
  
  

}
