import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from '../../services/appointment.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { merge, startWith, switchMap, catchError, map } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { CampaignService } from '../../services/campaign.service';


@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent {
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  formAction = "add";
	closeResult = '';
  isSubmitted: boolean = false;
  displayedColumns: string[] = ["campaign_name","campaign_group","campaign_date","is_schedule","status","action"];
  exportColumns:any = ["Campaign Name","Campaign Group","Campaign Date","Is Scheduled","Status"];
  exportArrayData:any = []
  subscriber_list:any = []
  btnLoading:boolean = false;
  runLoading:boolean = false;
  id:any = "";
  active = "whatsapp"
  campaign = {
    "campaign_name":"",
    "subscriber_id":"",
    "campaign_source":"",
    "campaign_date":"",
    "campaign_data":{
      template_id:"",
    },
    "is_schedule":false,
    "status":"pending",
    "hospital_id":"",
    "center_id":"",
  }
  constructor(private router:Router, private toast:ToastrService, private api:CampaignService,private modalService: NgbModal, private localStorage:LocalStorageService){
  }
  ngAfterViewInit() {
    this.loadData();
  }
  dateFilter(event:any)
  {
   this.dateQuery.setValue(event)
  }

  searchFilter(query:any){

    // alert(query)
     this.searchQuery.setValue(query);
   }
   open(content:any)
   {
    this.campaign  = {
      "campaign_name":"",
      "subscriber_id":"",
      "campaign_source":"",
      "campaign_date":"",
      "campaign_data":{
        template_id:"",
      },
      "is_schedule":false,
      "status":"pending",
      "hospital_id":"",
      "center_id":"",
    }
    this.modalService.open(content, {  size: 'lg' , scrollable: false });
    this.loadSubscriberList()
   }
   changeTab(type:any)
   {
    this.active = type;
    this.loadData()
    this.campaign  = {
      "campaign_name":"",
      "subscriber_id":"",
      "campaign_source":"",
      "campaign_date":"",
      "campaign_data":{
        template_id:"",
      },
      "is_schedule":false,
      "status":"pending",
      "hospital_id":"",
      "center_id":"",
    }
   }
   export(header:any){
 //console.log(header)
 let excelRowData:any = [];
 this.exportArrayData.forEach((element:any) => {
  // ["Campaign Name","Campaign Group","Campaign Date","Is Scheduled","Status"];
  excelRowData.push({0:element.campaign_name,1:element.subscriberlist?.subscriber_name,2:element.campaign_date,3:element.is_schedule,4:element.status})
});

 let excelData = [];
 let excelHeader = [];
   for (let i = 0; i < excelRowData.length; i++) {
     let element = excelRowData[i];
     let obj:any = {}
     for (let index = 0; index < header.length; index++) {
       let key = header[index]['key'];
       obj[key] = element[key]
     }
     excelData.push(obj)
   }
   for (let index = 0; index < header.length; index++) {
     let value = header[index]['value'];
     excelHeader.push(value)
   }
 //console.log(excelData)
 const wb: XLSX.WorkBook = XLSX.utils.book_new();
 const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([]);
 XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
 XLSX.utils.sheet_add_json(ws,excelData, { origin: 'A2', skipHeader: true });
 XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 let date = new Date();
 let day = date.getDate();
 let month = date.getMonth()+1;
 let year = date.getFullYear();

XLSX.writeFile(wb, "campaign_report"+day+"_"+month+"_"+year+"_"+date.getTime()+".csv");
   }

   loadSubscriberList() {
    let param = {
      where:{hospital_id: this.localStorage.getHospitalId()},
      // startDate:this.dateQuery.value.startDate,
      // endDate:this.dateQuery.value.endDate,
      order:{col:'createdAt',dir:"desc"},
    }
    // if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
    this.api.getAllSubscriberList(param).subscribe({
      next:(res: any)=>{
        console.log("customer data :",res);
        this.subscriber_list = res.rows || {};
        const list = res.rows.reduce((res: any, item: any) => {
          res.push({ subscriber_name: item.subscriber_name, id: item.id });
          return res;
        }, []);
    
      },
      error(err: any) {
        console.log("err : ",err);
      },
    })
  }
  SaveCampaign()
  {
    console.log(this.id)
    if(this.campaign.campaign_name==""){
      this.toast.error("Enter Campaign name")
    }
    else  if(this.campaign.campaign_date==""){
      this.toast.error("Enter Campaign Date")
    }
    else  if(this.campaign.subscriber_id==""){
      this.toast.error("Select Campaign Group")
    }

    else
    {
      if(this.id == "")
      {
        this.btnLoading = true;
        this.campaign.hospital_id = this.localStorage.getHospitalId()
        this.campaign.center_id = this.localStorage.getCenterId()
        this.campaign.campaign_source = this.active;
        this.api.addCampaignList(this.campaign).subscribe({
          next:(res)=>{
            if(res){
              this.toast.success("Campaign created successfully")
              this.btnLoading = false;
              this.loadData();
              this.modalService.dismissAll();
              this.campaign  = {
                "campaign_name":"",
                "subscriber_id":"",
                "campaign_source":"",
                "campaign_date":"",
                "campaign_data":{
                  template_id:"",
                },
                "is_schedule":false,
                "status":"pending",
                "hospital_id":"",
                "center_id":"",
              }
            }
          },
          error:(e)=>{
            this.btnLoading = false;
          }
        })
      }
      else
      {
        this.btnLoading = true;
        this.campaign.hospital_id = this.localStorage.getHospitalId()
        this.campaign.center_id = this.localStorage.getCenterId()
        this.campaign.campaign_source = this.active;
        this.api.updateCampaignList(this.campaign).subscribe({
          next:(res)=>{
            if(res){
              this.toast.success("Campaign Updated successfully")
              this.btnLoading = false;
              this.loadData();
              this.modalService.dismissAll();
              this.id = "";
              this.campaign  = {
                "campaign_name":"",
                "subscriber_id":"",
                "campaign_source":"",
                "campaign_date":"",
                "campaign_data":{
                  template_id:"",
                },
                "is_schedule":false,
                "status":"pending",
                "hospital_id":"",
                "center_id":"",
              }
            }
          },
          error:(e)=>{
            this.btnLoading = false;
          }
        })
      }
      
    }
    

  }
  closeModal()
  {
    this.modalService.dismissAll()
    this.campaign  = {
      "campaign_name":"",
      "subscriber_id":"",
      "campaign_source":"",
      "campaign_date":"",
      "campaign_data":{
        template_id:"",
      },
      "is_schedule":false,
      "status":"pending",
      "hospital_id":"",
      "center_id":"",
    }
  }

  loadData()
  {
    try{
   this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
   merge(this.dateQuery.valueChanges,this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
   switchMap(()=>{
    let param = {
      where:{hospital_id:this.localStorage.getHospitalId(),center_id:this.localStorage.getCenterId(),campaign_source:this.active},
      filter:this.searchQuery.value,
      page:(this.paginator.pageIndex *  this.paginator.pageSize),
      
      limit:this.paginator.pageSize,
      order:[{col:'id',dir:"desc"},{col:'campaign_date',dir:"desc"}],
    }
    if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
    if(this.localStorage.checkFullAccessForTableList(this.localStorage.getRole())){
      param = param
    }else{
      param = {...param,...{user_id:this.localStorage.getUserId()}}
    }
    return  this.api.getAllCampaignWithCount(param)
    .pipe(catchError(() => observableOf(null)));
   }),map((response:any)=>{
    if (response === null) {
      return [];
    }
 
    this.totalRows = response.count;
    return response
   })
   ).subscribe({
    next:(data) =>{
   // console.log(data)
    this.exportArrayData = data.rows;
    this.dataSource = new MatTableDataSource<any>(data.rows);;
    },
    error:(e)=>{
     console.error(e)
    },
    complete:()=>{
     
    }
   })
  }
  catch(e){
   console.error(e)
  }
  }
  edit(content:any,event:any){
    this.modalService.open(content, {  size: 'lg' , scrollable: false });
    this.loadSubscriberList()
    this.campaign = event
    this.id = event.id;
  }
  delete(content:any,event:any){

  }
  run(content:any,event:any){
    this.campaign = event;
   const popup =  this.modalService.open(content, {  size: 'sm' , scrollable: false }).result; 

  }
  runCampaign()
  {
    console.log(this.campaign)
   let camp:any = this.campaign;
    if(this.campaign.campaign_source == 'whatsapp')
    {
      this.runLoading = true
      let param = {
        hospital_id:this.localStorage.getHospitalId(),
        camp_id:camp.id,
        contacts:camp.subscriberlist?.subscriber_phones,
      }
      //console.log(param)

      this.api.runCampaignWhatsapp(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.toast.success("Whatsapp campaign run's successfully")
          this.runLoading = false
          let p = {
            id:camp.id,
            hospital_id:this.localStorage.getHospitalId(),
            status:"completed"
          }
          this.api.updateCampaignList(p).subscribe({
            next:(res)=>{
              if(res)
              {
                this.loadData();
              }
            }
          })
          this.modalService.dismissAll()
        }
      },error:(e)=>{
        this.runLoading = false
      }
      })
    }
    if(this.campaign.campaign_source == 'email')
    {
      this.runLoading = true
      let param = {
        hospital_id:this.localStorage.getHospitalId(),
        camp_id:camp.id,
        emails:camp.subscriberlist?.subscriber_emails,
      }
      //console.log(param)

      this.api.runCampaignEmail(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.toast.success("Email campaign run's successfully")
          this.runLoading = false
          let p = {
            id:camp.id,
            hospital_id:this.localStorage.getHospitalId(),
            status:"completed"
          }
          this.api.updateCampaignList(p).subscribe({
            next:(res)=>{
              if(res)
              {
                this.loadData();
              }
            }
          })
          this.modalService.dismissAll()
        }
      },error:(e)=>{
        this.runLoading = false
      }
      })
    }
    if(this.campaign.campaign_source == 'sms')
    {
      this.runLoading = true
      let param = {
        hospital_id:this.localStorage.getHospitalId(),
        camp_id:camp.id,
        numbers:camp.subscriberlist?.subscriber_phones,
      }
      //console.log(param)

      this.api.runCampaignTextMessage(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.toast.success("SMS campaign run's successfully")
          this.runLoading = false
          let p = {
            id:camp.id,
            hospital_id:this.localStorage.getHospitalId(),
            status:"completed"
          }
          this.api.updateCampaignList(p).subscribe({
            next:(res)=>{
              if(res)
              {
                this.loadData();
              }
            }
          })
          this.modalService.dismissAll()
        }
      },error:(e)=>{
        this.runLoading = false
      }
      })
    }
  }
}


function observableOf(arg0: null): any {
  throw new Error('Function not implemented.');
}

