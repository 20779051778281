import { Component } from '@angular/core';

@Component({
  selector: 'app-analytics-appointments-details',
  templateUrl: './analytics-appointments-details.component.html',
  styleUrls: ['./analytics-appointments-details.component.scss']
})
export class AnalyticsAppointmentsDetailsComponent {

}
