import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IpdchartService } from 'src/app/private/services/ipdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-general-investigation',
  templateUrl: './general-investigation.component.html',
  styleUrls: ['./general-investigation.component.scss']
})
export class GeneralInvestigationComponent {
  @Input() titleOfHead: any = {
    title: 'IPD',
    root: 'General Investigation'
  };
  @Input() patient:any;
  btnLoading:boolean = false;
  general_investigation:any = {
    height:"",
    weight:"",
    bmi:"",
    bmiCategory:"",
    bp:"",
    blood_sugar:"",
    pac:"",
    fasting_blood_sugar:"",
    pulse_rate:"",
    temp:"",
    mgd:"",
    covid_vaccinated:"",
    kco:"",
  }
  id:any;
  current_patient_id:any;
  ipd_app_id:any;
  layoutLoading:boolean = false;
  currentTab:any;
  constructor(private toast:ToastrService,public route:ActivatedRoute,private ipdChartData:IpdchartService, private templateService:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.ipd_app_id = params['ipd_app_id']
      })
  }

  calculateBMI() {
    if (this.general_investigation.weight && this.general_investigation.height) {
      const heightInMeters = this.general_investigation.height / 100;
      this.general_investigation.bmi = (this.general_investigation.weight / (heightInMeters * heightInMeters)).toFixed(2);
      this. determineBMICategory();
    } else {
      this.general_investigation.bmi = "";
    }
  }
  determineBMICategory() {
    if (this.general_investigation.bmi < 18.5) {
      this.general_investigation.bmiCategory = 'Underweight';
    } else if (this.general_investigation.bmi < 24.9) {
      this.general_investigation.bmiCategory = 'Normal weight';
    } else if (this.general_investigation.bmi < 29.9) {
      this.general_investigation.bmiCategory = 'Overweight';
    } else {
      this.general_investigation.bmiCategory = 'Obese';
    }
  }

  ngAfterViewInit()
  {
    this.getPatientProcedureData();
  }
  getPatientProcedureData()
  {
    // console.log(this.app_data)

   let param = {
    where:{
   hospital_id:this.localStorage.getHospitalId(),
    center_id:this.localStorage.getCenterId(),
    patient_id:this.current_patient_id,
    },
    attributes:['id','ipd_app_id','hospital_id','patient_id','center_id','observations']
  }
  this.ipdChartData.getAllIpdChartData(param).subscribe({
    next:(res)=>{
     if(res)
     {
      // this.count = res.filter((item:any)=>{return item.investigations}).length;
     //  this.investigationListData = res.filter((item:any)=>{return item.app_id != this.ipd_app.appointment.app_id && item.investigations });
       let existingObject  = res.find((item:any)=>{return item.ipd_app_id == this.ipd_app_id })
       if(existingObject!=undefined)
       {
         this.id =  existingObject.id || undefined;
         if(existingObject.observations){
         this.currentTab = existingObject;
         this.general_investigation = existingObject.observations;
         }
       }
       this.layoutLoading = true;

     }

    },
    error:(e)=>{

    }
  })
  }
  SaveGeneralInvestigationData()
  {
    if(this.id)
    {
     this.btnLoading = true;
     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       id:this.id,
       ipd_app_id:this.ipd_app_id,
       observations:this.general_investigation,
       }
      this.ipdChartData.updateIpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnLoading = false;
           this.toast.success("Data Saved successfully...")
         }
       },error:(e)=>{
         this.btnLoading = false;
       }
      })
    }
    else{
      this.btnLoading = true;

     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       patient_id:this.current_patient_id,
       ipd_app_id:this.ipd_app_id,
       observations:this.general_investigation,
       }

      this.ipdChartData.createIpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnLoading = false;
           this.toast.success("Data Saved successfully...")

         }
       },error:(e)=>{
         this.btnLoading = false;
       }
      })
    }
  }
}
