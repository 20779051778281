import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private http: HttpClient) { }

  getAllPrimaryCheckupTemplate(data:any): Observable<any> {
    return this.http.post(environment.getAllPrimaryCheckupTemplate,data);
  }

  updatePrimaryCheckupTemplate(data:any): Observable<any> {
    return this.http.post(environment.updatePrimaryCheckupTemplate,data);
  }
  getDiagnosisTemplate(data:any): Observable<any> {
    return this.http.post(environment.getDiagnosisTemplate,data);
  }
  getMedicinesDiagnosisTemplate(data:any): Observable<any> {
    return this.http.post(environment.getMedicinesDiagnosisTemplate,data);
  }
  
  getDynamicTemplateService(data:any,url:any): Observable<any> {
    return this.http.post(url,data);
  }
  
  getProcedurePackageList(data:any): Observable<any> {
    return this.http.post(environment.getProcedurePackageList,data);
  }
  updateProcedurePackage(data:any): Observable<any> {
    return this.http.post(environment.updateProcedurePackage,data);
  }
  createProcedurePackage(data:any): Observable<any> {
    return this.http.post(environment.createProcedurePackage,data);
  }
  getProcedurePackages(data:any): Observable<any> {
    return this.http.post(environment.getProcedurePackages,data);
  }
  
  
  
  
 
  
}
