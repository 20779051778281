import { Component } from '@angular/core';
import moment from 'moment';
import { AppointmentService } from 'src/app/private/services/appointment.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss']
})
export class DataImportComponent {

  uploadType:any = ""
  appointments:any = [];
  constructor(private appointmentService:AppointmentService,private localStorage:LocalStorageService){

  }
  
  onAppointmentFileChange(args: any)
  {
    const target: DataTransfer = <DataTransfer>(args.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
 // Data will be logged in array format containing objects
      data.forEach((element:any) => {
        var name = element.Name // try "Paul", "Paul Steve"
        var first_name = name.split(' ')[0]
        var last_name = name.substring(first_name.length).trim()
        this.appointments.push({
          fname:first_name,
          lname:last_name,
          email:element.Email,
          mobile:element.Phone,
          user_id:element.UserId,
          dept_id:element.DeptId,
          appointment_date:element.Date,
          appointment_type:element['Appointment Type'].toLowerCase(),
          appointment_time:element.Time,
          status:element['Appointment Status'].toLowerCase(),
          check_in_type:"new",
          appointment_source:"webapp"
        
        })
      });
     // console.log(data); 
    };
  }
  upload()
  {
    if(this.uploadType == 'appointment'){
      let param = {
          hospital_id:this.localStorage.getHospitalId(),
          center_id:this.localStorage.getCenterId(),
          appointments:this.appointments,

      }
      console.log(param)
      this.appointmentService.importAppointments(param).subscribe({
        next:(res)=>{
          console.log(res)
        },
        error:(e)=>{}
      })
    }
  }
}

export class Appointment {
  fname: string = "";
  lname: string = "";
  patient_id!:Number;
  prefix: string = "";
  gender: string = "";
  user_id: string = "";
  age: string = "";
  uid_number:string = "";
  abha_number:string = "";
  blood_group:string = "";
  email: string = "";
  mobile: string = "";
  address: string = "";
  check_in_type:string = "new";
  appointment_source:string = "webapp";
  appointment_type:string = "";
  appointment_date:string = "";
  appointment_time:string = "";
  dept_id:string = "";
  client_id:string = ""
  hospital_id:Number = 0
  center_id:Number = 0;
  status:string = "booked";
}
