import { Component, Input, OnInit } from '@angular/core';
import Chart, { Plugin, plugins } from 'chart.js/auto';
@Component({
  selector: 'app-user-graph',
  templateUrl: './user-graph.component.html',
  styleUrls: ['./user-graph.component.scss']
})
export class UserGraphComponent {

  chart: any;
  canvas : any;
  monthArray:any = ["Jan","Feb","March","April","May","June","July","Aug","Sep","Oct","Nov","Dec"]
  @Input() data:any;
  @Input() id:any;
  @Input() Title:any;
  ngAfterViewInit(): void {
    const analysis_daily_opt:any = document.getElementById(this.id);
    const analysis_daily_ctx = analysis_daily_opt.getContext('2d');
    let chartStatus = Chart.getChart(this.id); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    
    }
    const gradient = analysis_daily_ctx.createLinearGradient(0, 0, 0, 240);
    gradient.addColorStop(1, "rgba(8 251 247 / 10%)");
    gradient.addColorStop(0, "rgba(67 204 213)");
    var months = [];
    var values = [];
    console.log(this.data)
    if(this.data)
    {
      for (var key in this.data) {
        let month = this.monthArray[key];
          months.push(month);
          values.push(this.data[key])
      }
      this.chart = new Chart(analysis_daily_ctx, {
        type: 'line', //this denotes tha type of chart
  
        data: {// values on X-Axis
          labels: months,
          
           datasets: [
            {
            
              data:values,
              pointRadius: 1,
              // tension:0.5,
              borderColor:"rgba(67 204 213)",
              borderWidth:1.5,
              backgroundColor:gradient,
              fill: false
            }
            
          
          ]
        },
        options: {
     
          plugins: {
            legend: {
                display: false
            },
        },
          aspectRatio:2.5,
          scales: {
            y: {
              grid: {
                display:true,
              }
            },
            x: {
              grid: {
                display:true,
              }
            }
          }
        }
        
      });

    }


  }
  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
