import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OpdchartService {

  constructor(private http: HttpClient) { }

  createOpdChartData(data:any): Observable<any> {
    return this.http.post(environment.createOpdChartData,data);
  }
  getAllOpdChartData(data:any): Observable<any> {
    return this.http.post(environment.getAllOpdChartData,data);
  }
  updateOpdChartData(data:any): Observable<any> {
    return this.http.post(environment.updateOpdChartData,data);
  }
  createMedicationTemplate(data:any): Observable<any> {
    return this.http.post(environment.createMedicationTemplate,data);
  }
  getMedicationTemplate(data:any): Observable<any> {
    return this.http.post(environment.getMedicationTemplate,data);
  }

  createInvestigationTemplate(data:any): Observable<any> {
    return this.http.post(environment.createInvestigationTemplate,data);
  }
  getInvestigationTemplate(data:any): Observable<any> {
    return this.http.post(environment.getInvestigationTemplate,data);
  }
  getAllPriceMaster(data:any): Observable<any> {
    return this.http.post(environment.getAllPriceMaster,data);
  }
  getAdviceTemplate(data:any): Observable<any> {
    return this.http.post(environment.getAdviceTemplate,data);
  }

  sharePrescription(data:any): Observable<any> {
    return this.http.post(environment.sharePrescription,data);
  }

  
  
  
}
