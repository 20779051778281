import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IpdchartService {

  constructor(private http: HttpClient) { }

  createIpdChartData(data:any): Observable<any> {
    return this.http.post(environment.createIpdChartData,data);
  }
  getAllIpdChartData(data:any): Observable<any> {
    return this.http.post(environment.getAllIpdChartData,data);
  }
  updateIpdChartData(data:any): Observable<any> {
    return this.http.post(environment.updateIpdChartData,data);
  }
  getDischargeDetails(data:any): Observable<any> {
    return this.http.post(environment.getDischargeDetails,data);
  }
  getAllSupply(data:any): Observable<any> {
    return this.http.post(environment.getAllSupply,data);
  }
  
}
