import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { MainComponent } from './private/layout/main/main.component';
import { HomeComponent } from './private/modules/home/home.component';
import { ForgetComponent } from './public/forget/forget.component';
import { ResetComponent } from './public/reset/reset.component';
import { LeadComponent } from './private/modules/lead/lead.component';
import { UsersComponent } from './private/modules/configuration/users/users.component';
import { AuthGuard } from './utils/auth.guard';
import { ScheduleComponent } from './private/modules/configuration/schedule/schedule.component';
import { PriceSettingsComponent } from './private/modules/configuration/price-settings/price-settings.component';
import { EmailSettingsComponent } from './private/modules/configuration/email-settings/email-settings.component';
import { ReportsComponent } from './private/modules/reports/reports.component';
import { ProcedurePackageComponent } from './private/modules/configuration/procedure-package/procedure-package.component';
import { FeedbackComponent } from './private/modules/feedback/feedback.component';
import { DrugInventoryComponent } from './private/modules/configuration/drug-inventory/drug-inventory.component';
import { SupplyInventoryComponent } from './private/modules/configuration/supply-inventory/supply-inventory.component';
import { EquipmentInventoryComponent } from './private/modules/configuration/equipment-inventory/equipment-inventory.component';
import { PricingDashboardComponent } from './private/modules/configuration/pricing-dashboard/pricing-dashboard.component';
import { PharmacyComponent } from './private/modules/pharmacy/pharmacy.component';
import { OpticalsComponent } from './private/modules/opticals/opticals.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: "full",
    title: "TINA"
  },
  {
    path: 'login',
    component: LoginComponent,
    title: "TINA"
  },
  {
    path: 'forget-password',
    component: ForgetComponent,
    title: "Forget Password"
  },
  {
    path: 'reset-password/:type/:token',
    component: ResetComponent,
    title: "Reset Password"
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    children:[{
      path:"",
      data:{
        title:"Manage Leads"
      },
      component:LeadComponent,
      canActivate: [AuthGuard],
    },{
      path: 'home',
      component: HomeComponent,
      title: "Home",
      data:{
        title:"Analytics Dashboard"
      },
      canActivate: [AuthGuard]
     },{
      path: 'leads',
      component: LeadComponent,
      canActivate: [AuthGuard],
      title: "Leads",
      data:{
        title:"Manage Leads"
      }
      
      
     },{
      path: 'manage-campaign',
      loadChildren: () => import('./private/modules/campaign/campaign.module').then(m => m.CampaignModule),
      canActivate: [AuthGuard],
      title: "Campaign",
      data:{
        title:"Manage Campaign"
      }
      
      
     },
     {
      path: 'configuration-users',
      component: UsersComponent,
      title: "Users",
      canActivate: [AuthGuard],
      data:{
        title:"Manage Users"
      }
     },
     {
      path: 'configuration-email',
      component: EmailSettingsComponent,
      canActivate: [AuthGuard],
      title: "Email",
      data:{
        title:"Email Settings"
      }
     },
     {
      path: 'configuration-schedule',
      component: ScheduleComponent,
      canActivate: [AuthGuard],
      title: "Schedule",
      data:{
        title:"Schedule Settings"
      }
     },
     {
      path: 'procedure-packages',
      component: ProcedurePackageComponent,
      canActivate: [AuthGuard],
      title: "Procedure Packages",
      data:{
        title:"Procedure Packages"
      }
     },
     {
      path: 'price-settings',
      component: PriceSettingsComponent,
      canActivate: [AuthGuard],
      title: "Price",
      data:{
        title:"Price Settings"
      }
     },
     {
      path: 'drug-inventory',
      component: DrugInventoryComponent,
      canActivate: [AuthGuard],
      title: "Drug Inventory",
      data:{
        title:"Drug Inventory"
      }
     }
     ,
     {
      path: 'supply-inventory',
      component: SupplyInventoryComponent,
      canActivate: [AuthGuard],
      title: "Supply Inventory",
      data:{
        title:"Supply Inventory"
      }
     },
     {
      path: 'equipment-inventory',
      component: EquipmentInventoryComponent,
      canActivate: [AuthGuard],
      title: "Equipment Inventory",
      data:{
        title:"Equipment Inventory"
      }
     },
     {
      path: 'pricing-dashboard',
      component: PricingDashboardComponent,
      canActivate: [AuthGuard],
      title: "Pricing Dashboard",
      data:{
        title:"Pricing Dashboard"
      }
     }
     , {
      path: 'appointments',
      loadChildren: () => import('./private/modules/appointment/appointment.module').then(m => m.AppointmentModule),
      canActivate: [AuthGuard],
      title: "Appointments",
      data:{
        title:"Manage Appointments"
      }
     },
     {
      path: 'out-patient',
      canActivate: [AuthGuard],
      loadChildren: () => import('./private/modules/opd/opd.module').then(m => m.OpdModule),
      title: "OPD",
      data:{
        title:"Manage Out Patient"
      }
     },
     {
      path: 'in-patient',
      canActivate: [AuthGuard],
      loadChildren: () => import('./private/modules/ipd/ipd.module').then(m => m.IpdModule),
      title: "IPD",
      data:{
        title:"Manage In Patient"
      }
     },
     {
      path: 'pharmacy',
      canActivate: [AuthGuard],
      component: PharmacyComponent,
      title: "Pharmacy",
      data:{
        title:"Pharmacy"
      }
     },
     {
      path: 'optical',
      canActivate: [AuthGuard],
      component: OpticalsComponent,
      title: "Optical",
      data:{
        title:"Optical"
      }
     },
     {
      path: 'reports',
      canActivate: [AuthGuard],
      component: ReportsComponent,
      title: "Reports",
      data:{
        title:"Manage Reports"
      }
     },
     {
      path: 'manage-tickets',
      canActivate: [AuthGuard],
      loadChildren: () => import('./private/modules/manage-tickets/manage-tickets.module').then(m => m.ManageTicketsModule),
      title: "Manage Tickets",
      data:{
        title:"Manage Tickets"
      }
     },
     {
      path: 'manage-feedbacks',
      canActivate: [AuthGuard],
      component:FeedbackComponent,
      title: "Manage Feedbacks",
      data:{
        title:"Manage Feedbacks"
      }
     }


     

  ]
    
  },
  { path: '**', 
  pathMatch: 'full', 
  component: LoginComponent 
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {

  
 }
