import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TicketService {

    constructor(private http: HttpClient) { }
  
    getAllTinaTicket(data:any): Observable<any> {
      return this.http.post(environment.getAllTinaTicket,data);
    }
    getTinaTicketHistory(data:any):Observable<any> {
      return this.http.post(environment.getTinaTicketHistory,data);
    }
    getAllZendeskTicket(data:any): Observable<any> {
        return this.http.post(environment.getAllZendeskTicket,data);
      }
    addTicketData(data:any): Observable<any> {
      return this.http.post(environment.createTicket,data);
    }
    getUser(data:any): Observable<any> {
      return this.http.post(environment.getUsers,data);
    }
    getLead(data:any): Observable<any> {
      return this.http.post(environment.getLead,data);
    }
    updateTicket(data:any): Observable<any> {
      return this.http.post(environment.updateTicket,data);
    }
    updateBulkTicket(data:any): Observable<any> {
      return this.http.post(environment.updateBulkTicket,data);
    }
    updateTicketHistory(data:any): Observable<any> {
      return this.http.post(environment.updateTicketHistory,data);
    }
    createBulkTicketHistory(data:any): Observable<any> {
      return this.http.post(environment.createBulkTicketHistory,data);
    }
    addLeadFollowUp(data:any): Observable<any> {
      return this.http.post(environment.createleadsfollowup,data);
    }
    
    
    
  }