import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JWTTokenServiceService } from 'src/app/public/services/token.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PermissionService {

    permissions:any = [];
    modules:any = [];
    constructor(private localStorage:LocalStorageService,private auth:JWTTokenServiceService) {
    if(this.auth.getToken())
    {
      this.permissions = JSON.parse(this.localStorage.getPermissions()); 
      this.modules =  JSON.parse(JSON.stringify(this.localStorage.getFeatureModules()));

    }
  }
  getfeatureAccess(module_name:any,feature_name:any)
  {
    //console.log(this.permissions)
    let feature  = [];
    let module = [];
    module = this.permissions.filter((item:any)=>{
    return  item.module_name  == module_name;
   })
  if(module.length > 0)
  {
    feature = module[0]['features'].filter((item:any)=>{
      return  item.name  == feature_name && item.active == true;
     })
  }
  
   return (module[0] && feature[0] ) ? true : false;

  } 
 getAccess(module_name:any)
 {
   //console.log("chetan")
  //console.log(this.permissions)
  let result = this.permissions.filter((item:any)=>{
   return  item.module_name  == module_name && item.active == true;
  })
  //console.log(result[0])
  return (result[0]) ? true : false;
  //return true;
 } 
  getFeatureModuleAccess(module_name:any)
  {
   return (this.modules.includes(module_name) ? true : false);
  }
}