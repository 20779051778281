import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subscription, delay, of } from 'rxjs';
import { JWTTokenServiceService } from 'src/app/public/services/token.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],


})
export class MainComponent {
  tokenSubscription = new Subscription()
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  @Output("toggleMenu") toggleMenu: EventEmitter<any> = new EventEmitter();
  constructor(private jwt:JWTTokenServiceService,private router:Router,private localStorage:LocalStorageService)
  {

    // if (jwt.getToken()=="") {
    //    router.navigate(['login']);    
    // }
    // if(jwt.isTokenExpired())
    // {
    //   this.router.navigate(['login']);
    // }
    // console.log(jwt.getExpiryTime())
    /// this.expirationCounter(jwt.getExpiryTime());
    //console.log(jwt.getDecodeToken())
  } 
  

  expirationCounter(timeout:any) {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null).pipe(delay(timeout)).subscribe((expired) => {
      console.log('EXPIRED!!');
      this.logout();
    });
  }

  logout() {
    this.tokenSubscription.unsubscribe();
    this.localStorage.logout();
    this.router.navigate(["/login"]);
  }
  
  

}
