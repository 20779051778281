import { Component, Input } from '@angular/core';
import Chart, { Plugin, plugins } from 'chart.js/auto';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent {

  @Input() data:any;
  @Input() id:any;
  @Input() Title:any;
  color1:string = '#104358';
  color2:string = '#1E73AC';
  ngAfterViewInit()
  {

    if(this.id === 'total_income' || this.id === 'total_income_opto'){
      this.color1 = '#1876CC';
      this.color2 = '#F0950C';
    }

    console.log("gafjnasjfnas",this.data)
    const analysis_daily_opt:any = document.getElementById(this.id);
    const analysis_daily_ctx = analysis_daily_opt.getContext('2d');

    let chartStatus = Chart.getChart(this.id); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    
      
    }
    var _labels = [];
    var values = [];
    var colors = [];
    var _this = this;
    if(this.data)
    {
      console.log("this.data",this.data)
      for (var key in this.data) {

        _labels.push(key);
          values.push(this.data[key])
          colors.push(this.getRandomColorHex())
      }
    }
    let myChart2 = new Chart(analysis_daily_ctx, {
      type: 'doughnut',
       data : {
        
        labels: _labels,
        
        datasets: [{
          label: '',
          data: values,
          backgroundColor: [
            this.color1,
            this.color2,
            '#20A6FF',
            '#74A3C3',
          
          ],
          borderWidth: 1
        }]
      },

      
      plugins:[<Plugin>{
        afterInit: function(chart:any, args:any, options:any) {
        
          // Make sure we're applying the legend to the right chart
          if (chart.canvas.id === _this.id) {
  
            const ul = document.createElement('ul');
           
            chart.data.labels.forEach((label:any, i:any) => {
              ul.style.listStyle = 'none';
              ul.style.display = 'flex';
              ul.style.flexDirection = 'column';
              ul.style.position = 'absolute';
              ul.style.top = '35px';
              ul.style.right = '0px';
              ul.style.alignItems = 'flex-start';
              ul.style.fontSize = '16px';
              ul.style.gap = '12px';
              ul.innerHTML += `
                <li>
                 <div style="display: flex;
                 flex-direction: row;
                 padding: 5px;">
                 <div style="width: 17px;
                 margin-right: 12px;
                 height:19px;
                 margin-top: 5px;padding:5px;background-color: ${ chart.data.datasets[0].backgroundColor[i] }"></div>
           

                 <div style="color:gray; margin-top:4px; display:flex; gap:15px">
                 <div style=" margin-left: 11px;color:#000""> ${ chart.data.datasets[0].data[i] }</div>
                 ${ label }</div>
                 

                 </div>
                
                

                </li>
              `;
            });
             let legend =  <HTMLElement> document.getElementById('legend_'+_this.id);
             legend.innerHTML = ''
            return legend.appendChild(ul)
          }
    
          return;
        }
      }],
      options: {
      
        plugins: {
        
          legend: {
            display: false,
            // position: 'right',
            
          },
          
        },
        responsive: false,
        // display: true,
        scales: {
          
          x: {
            border:{
              display:false
            },
            grid: {
              display: false,
              
            },
            ticks: {
              display: false,
            
            },
          },
          y: {
            border:{
              display:false
            },
            grid: {
              display: false
            },
            ticks: {
              display: false,
            },
          },
        },
        
      },
    })
  }

   getRandomColorHex() {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor.padStart(6, "0");
  }
}
