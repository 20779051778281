import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OpdchartService } from 'src/app/private/services/opdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-operative-treatment-advice',
  templateUrl: './operative-treatment-advice.component.html',
  styleUrls: ['./operative-treatment-advice.component.scss']
})
export class OperativeTreatmentAdviceComponent {

  @Input() app_data:any

  @Input() titleOfHead: any = {
    title: 'OPD',
    root: 'Procedures & Advices'
  };
  loading:boolean = false;
@Input() patient:any;

  btnSaveLoading:boolean = false;
  currentTab:any;
  adviceTemplateList:any = []
  advicesDataList:any = [];
  tagList:any = []
  id:any;
  count:any = 0;
  adviceObject:any = {
    advices:[],
    nextVisit:"",
    surgeries:[],
    counsellor:{},
    remarks:""
  }
  layoutLoading:boolean = false
  current_app_id:any;
  current_patient_id:any
  constructor(public route:ActivatedRoute,private opdChartAPI:OpdchartService, private toast:ToastrService, public dialog: MatDialog,private modalService: NgbModal,private api:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.current_app_id = params['app_id']
      })
  }
  OpenNewAdvice(content:any)
  {
    this.modalService.open(content,  {  size:'xl', centered:true, scrollable: true,ariaLabelledBy: 'modal-basic-title' });
    this.getAdviceTemplates();
  }
  removeAdviceTag(i: any): void {
   // alert()
   this.adviceObject.advices.splice(-1);
 
  }
  setTemplateData(item:any)
  {

  }

  ngAfterViewInit()
  {
    this.getAllAdvices();
  }
  editAdvices(content:any,item:any)
  {
    this.adviceObject = item;
    console.log(item)
    this.modalService.open(content,  {  size:'xl', centered:true, scrollable: true,ariaLabelledBy: 'modal-basic-title' });
    this.getAdviceTemplates();
  }
  getAllAdvices()
  {
   //console.log(this.app_data)
   this.loading = true;
   let param = {
     where:{hospital_id:this.localStorage.getHospitalId(),
     center_id:this.localStorage.getCenterId(),
     patient_id:this.current_patient_id,
     },
     attributes:['id','app_id','hospital_id','patient_id','center_id','advices','createdAt']
   }
   this.opdChartAPI.getAllOpdChartData(param).subscribe({
     next:(res)=>{
      if(res)
      {

      
      this.count = res.filter((item:any)=>{return item.advices}).length;
       this.advicesDataList = res.filter((item:any)=>{return item.app_id != this.app_data.appointment.app_id &&  item.advices})
       let existingObject  = res.filter((item:any)=>{return item.app_id == this.app_data.appointment.app_id})[0]
        if(existingObject!=undefined)
         {
           this.id =  existingObject.id || undefined;
           if(existingObject.advices){
             this.currentTab = existingObject.advices;
             this.currentTab.createdAt = existingObject.createdAt;
           }
         }
         this.layoutLoading = true;
          this.loading = false;
        }
       //console.log(this.currentTab)
     },
     error:(e)=>{
        this.loading = false;
     }
   })
  }
  onKeyUp(event: any): void {
    const inputValue: string = event.target.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeAdviceTag(inputValue);
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Enter') {
        this.addTag(inputValue);
        event.target.value = ""
      }
    }
  }
  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0) {
     // this.selectedValues.push(tag);
     let existingArray = []
     let value:any = tag
      existingArray = this.adviceObject.advices || [];
      this.adviceObject.advices = existingArray.concat(value);
  
    }

  }
   removeDuplicates(arr:any) { 
    return arr.filter(((item:any,index:Number) => arr.indexOf(item) === index)); 
} 
getAdviceTemplates()
{
  let param = {
    where:{
      specialty:"ophthalmology"
    }
  }
  this.opdChartAPI.getAdviceTemplate(param).subscribe({
    next:(res)=>{
      if(res)
      {
        this.adviceTemplateList = res;
      }
    }
  })
}
  saveAdvices()
  {
    if(this.id)
    {
     this.btnSaveLoading = true;
     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       id:this.id,
       app_id:this.app_data.appointment.app_id,
       advices:this.adviceObject,
       }
 
      this.opdChartAPI.updateOpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnSaveLoading = false;
           this.toast.success("Data Saved successfully...")
           this.modalService.dismissAll()
           this.getAllAdvices();
           this.adviceObject =  {
            advices:[],
            nextVisit:"",
            surgeries:[],
            counsellor:{},
            remarks:""
          }
        
         }
       },error:(e)=>{
         this.btnSaveLoading = false;
       }
      })
    }
    else{
      this.btnSaveLoading = true;
     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       patient_id:this.app_data.id,
       app_id:this.app_data.appointment.app_id,
       advices:this.adviceObject,
       }
       
   
      this.opdChartAPI.createOpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnSaveLoading = false;
           this.toast.success("Data Saved successfully...")
           this.modalService.dismissAll()
           this.getAllAdvices();
           this.adviceObject =  {
            advices:[],
            nextVisit:"",
            surgeries:[],
            counsellor:{},
            remarks:""
          }
         
         }
       },error:(e)=>{
         this.btnSaveLoading = false;
       }
      })
    }
  }
}
