import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-lead-time-chart',
  templateUrl: './lead-time-chart.component.html',
  styleUrls: ['./lead-time-chart.component.scss']
})
export class LeadTimeChartComponent {
  @Input() data:any;
  @Input() id:any;
  @Input() Title:any;
  ngAfterViewInit()
  {

    const analysis_daily_opt:any = document.getElementById("canvas__"+this.id);
    const analysis_daily_ctx = analysis_daily_opt.getContext('2d');

    let chartStatus = Chart.getChart(this.id); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    
      
    }
    var _labels = [];
    var values = [];
    if(this.data)
    {
      for (var key in this.data) {

        _labels.push(key);
          values.push(this.data[key])
      }
    }


    let myChart2 = new Chart(analysis_daily_ctx, {
      type: 'line',
       data : {
        labels: _labels,
        datasets: [{
          data: values,
          borderWidth: 3, // Setting the width to 3px
          borderColor: '#F0950C', // Setting the line color
         
      }]
      },
      
      options: {
        animation: {
          duration: 1,
          onComplete: function({ chart }) {
            const ctx = chart.ctx;
        
            chart.config.data.datasets.forEach(function(dataset, i) {
              const meta = chart.getDatasetMeta(i);
        
              meta.data.forEach(function(bar, index) {
                const data:any = dataset.data[index];
                console.log(data)
        
                // ctx.fillText(data, bar.x, bar.y - 5);
              });
            });
          }
        },
        plugins: {
        
          legend: {
              display: false
          },
      },
        responsive: false,
        // display: true,
        
      },
    })
  
  }
}
