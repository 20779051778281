import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  getAllData(data:any): Observable<any> {
    return this.http.post(environment.getAllUsers,data);
  }

  getAllCenter(data:any): Observable<any> {
    return this.http.post(environment.getAllCenter,data);
  }
  getAllDepartment(data:any): Observable<any> {
    return this.http.post(environment.getAllDepartment,data);
  }
  getAllRoles(data:any): Observable<any> {
    return this.http.post(environment.getAllRoles,data);
  }
  createUser(data:any): Observable<any> {
    return this.http.post(environment.createUser,data);
  }
  updateUser(data:any): Observable<any> {
    return this.http.post(environment.updateUser,data);
  }
  getUser(data:any): Observable<any> {
    return this.http.post(environment.getUser,data);
  }
  userModuleDropdownListing(): Observable<any> {
    return this.http.get(environment.userModuleDropdownListing);
  }
  getDoctors(data:any): Observable<any> {
    return this.http.post(environment.getDoctors,data);
  }
  getPatientById(data:any): Observable<any> {
    return this.http.post(environment.getPatientById,data);
  }
  

  
}
