import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanActivateFn, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from './LocalStorageService';
import { AuthService } from '../public/services/auth.service';
import { JWTTokenServiceService } from '../public/services/token.service';

Injectable({
    providedIn: 'root'
  })
  // export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown> {
  
  
  //   constructor( private authService:LocalStorageService,private router: Router) { }
  //   canDeactivate(component: unknown, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot | undefined): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
  //     throw new Error('Method not implemented.');
  //   }
  //   canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
  //     throw new Error('Method not implemented.');
  //   }
  
  //   canActivate(
  //     route: ActivatedRouteSnapshot,
  //     state: RouterStateSnapshot): boolean | Promise<boolean> {
  //       let routeTitle:any = route.routeConfig;
  //       console.log(routeTitle)
  //       var isAuthenticated = this.authService.getDashboardAccess(routeTitle.title);
  //       console.log(isAuthenticated)
  //       if(this.authService.isLoggedIn())
  //       {
       
  //         if (!isAuthenticated) {
  //         //  console.log("dsdfs"+isAuthenticated)
  //           this.router.navigate(['/dashboard/access-restricted']);
  //         }
  //       }
  //       else
  //       {
  //         this.router.navigate(['/login']);
  //       }
       
     
  //     return isAuthenticated;
  // }
   
  // }
  export const AuthGuard: CanActivateFn = (

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

    const router: Router = inject(Router);
    var tokenService:JWTTokenServiceService = inject(JWTTokenServiceService);
    
    if (tokenService.isTokenExpired() == false && tokenService.getToken()) {
      return true;    
    }
    router.navigate(['login'])
    return false;
  
  }