import { Component } from '@angular/core';

@Component({
  selector: 'app-next-patient-details',
  templateUrl: './next-patient-details.component.html',
  styleUrls: ['./next-patient-details.component.scss']
})
export class NextPatientDetailsComponent {

}
