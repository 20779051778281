import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OpdchartService } from 'src/app/private/services/opdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-ophthalmic-examination',
  templateUrl: './ophthalmic-examination.component.html',
  styleUrls: ['./ophthalmic-examination.component.scss']
})
export class OphthalmicExaminationComponent {
  ListData:any = [];
  openForm:boolean = false;
  @Input() titleOfHead: any = {
    title: 'Opd',
    root: 'Ophthalmic Examination'
  };
  @Input() patient:any
  @Input() app_data:any

  count:any = 0;
  spherical_values = [
    'Plain',"-30.00", "-29.75", "-29.50", "-29.25", "-29.00", "-28.75", "-28.50", "-28.25", "-28.00", "-27.75",
    "-27.50", "-27.25", "-27.00", "-26.75", "-26.50", "-26.25", "-26.00", "-25.75", "-25.50", "-25.25",
    "-25.00", "-24.75", "-24.50", "-24.25", "-24.00", "-23.75", "-23.50", "-23.25", "-23.00", "-22.75",
    "-22.50", "-22.25", "-22.00", "-21.75", "-21.50", "-21.25", "-21.00", "-20.75", "-20.50", "-20.25",
    "-20.00", "-19.75", "-19.50", "-19.25", "-19.00", "-18.75", "-18.50", "-18.25", "-18.00", "-17.75",
    "-17.50", "-17.25", "-17.00", "-16.75", "-16.50", "-16.25", "-16.00", "-15.75", "-15.50", "-15.25",
    "-15.00", "-14.75", "-14.50", "-14.25", "-14.00", "-13.75", "-13.50", "-13.25", "-13.00", "-12.75",
    "-12.50", "-12.25", "-12.00", "-11.75", "-11.50", "-11.25", "-11.00", "-10.75", "-10.50", "-10.25",
    "-10.00", "-9.75", "-9.50", "-9.25", "-9.00", "-8.75", "-8.50", "-8.25", "-8.00", "-7.75", "-7.50",
    "-7.25", "-7.00", "-6.75", "-6.50", "-6.25", "-6.00", "-5.75", "-5.50", "-5.25", "-5.00", "-4.75",
    "-4.50", "-4.25", "-4.00", "-3.75", "-3.50", "-3.25", "-3.00", "-2.75", "-2.50", "-2.25", "-2.00",
    "-1.75", "-1.50", "-1.25", "-1.00", "-0.75", "-0.50", "-0.25", "0.00", "+0.25", "+0.50", "+0.75",
    "+1.00", "+1.25", "+1.50", "+1.75", "+2.00", "+2.25", "+2.50", "+2.75", "+3.00", "+3.25", "+3.50",
    "+3.75", "+4.00", "+4.25", "+4.50", "+4.75", "+5.00", "+5.25", "+5.50", "+5.75", "+6.00", "+6.25",
    "+6.50", "+6.75", "+7.00", "+7.25", "+7.50", "+7.75", "+8.00", "+8.25", "+8.50", "+8.75", "+9.00",
    "+9.25", "+9.50", "+9.75", "+10.00", "+10.25", "+10.50", "+10.75", "+11.00", "+11.25", "+11.50",
    "+11.75", "+12.00", "+12.25", "+12.50", "+12.75", "+13.00", "+13.25", "+13.50", "+13.75", "+14.00",
    "+14.25", "+14.50", "+14.75", "+15.00", "+15.25", "+15.50", "+15.75", "+16.00", "+16.25", "+16.50",
    "+16.75", "+17.00", "+17.25", "+17.50", "+17.75", "+18.00", "+18.25", "+18.50", "+18.75", "+19.00",
    "+19.25", "+19.50", "+19.75", "+20.00", "+20.25", "+20.50", "+20.75", "+21.00", "+21.25", "+21.50",
    "+21.75", "+22.00", "+22.25", "+22.50", "+22.75", "+23.00", "+23.25", "+23.50", "+23.75", "+24.00",
    "+24.25", "+24.50", "+24.75", "+25.00", "+25.25", "+25.50", "+25.75", "+26.00", "+26.25", "+26.50",
    "+26.75", "+27.00", "+27.25", "+27.50", "+27.75", "+28.00", "+28.25", "+28.50", "+28.75", "+29.00",
    "+29.25", "+29.50", "+29.75", "+30.00"]
  cylindrical_values = [
    'Plain',"-30.00", "-29.75", "-29.50", "-29.25", "-29.00", "-28.75", "-28.50", "-28.25", "-28.00", "-27.75",
      "-27.50", "-27.25", "-27.00", "-26.75", "-26.50", "-26.25", "-26.00", "-25.75", "-25.50", "-25.25",
      "-25.00", "-24.75", "-24.50", "-24.25", "-24.00", "-23.75", "-23.50", "-23.25", "-23.00", "-22.75",
      "-22.50", "-22.25", "-22.00", "-21.75", "-21.50", "-21.25", "-21.00", "-20.75", "-20.50", "-20.25",
      "-20.00", "-19.75", "-19.50", "-19.25", "-19.00", "-18.75", "-18.50", "-18.25", "-18.00", "-17.75",
      "-17.50", "-17.25", "-17.00", "-16.75", "-16.50", "-16.25", "-16.00", "-15.75", "-15.50", "-15.25",
      "-15.00", "-14.75", "-14.50", "-14.25", "-14.00", "-13.75", "-13.50", "-13.25", "-13.00", "-12.75",
      "-12.50", "-12.25", "-12.00", "-11.75", "-11.50", "-11.25", "-11.00", "-10.75", "-10.50", "-10.25",
      "-10.00", "-9.75", "-9.50", "-9.25", "-9.00", "-8.75", "-8.50", "-8.25", "-8.00", "-7.75", "-7.50",
      "-7.25", "-7.00", "-6.75", "-6.50", "-6.25", "-6.00", "-5.75", "-5.50", "-5.25", "-5.00", "-4.75",
      "-4.50", "-4.25", "-4.00", "-3.75", "-3.50", "-3.25", "-3.00", "-2.75", "-2.50", "-2.25", "-2.00",
      "-1.75", "-1.50", "-1.25", "-1.00", "-0.75", "-0.50", "-0.25", "0.00", "+0.25", "+0.50", "+0.75",
      "+1.00", "+1.25", "+1.50", "+1.75", "+2.00", "+2.25", "+2.50", "+2.75", "+3.00", "+3.25", "+3.50",
      "+3.75", "+4.00", "+4.25", "+4.50", "+4.75", "+5.00", "+5.25", "+5.50", "+5.75", "+6.00", "+6.25",
      "+6.50", "+6.75", "+7.00", "+7.25", "+7.50", "+7.75", "+8.00", "+8.25", "+8.50", "+8.75", "+9.00",
      "+9.25", "+9.50", "+9.75", "+10.00", "+10.25", "+10.50", "+10.75", "+11.00", "+11.25", "+11.50",
      "+11.75", "+12.00", "+12.25", "+12.50", "+12.75", "+13.00", "+13.25", "+13.50", "+13.75", "+14.00",
      "+14.25", "+14.50", "+14.75", "+15.00", "+15.25", "+15.50", "+15.75", "+16.00", "+16.25", "+16.50",
      "+16.75", "+17.00", "+17.25", "+17.50", "+17.75", "+18.00", "+18.25", "+18.50", "+18.75", "+19.00",
      "+19.25", "+19.50", "+19.75", "+20.00", "+20.25", "+20.50", "+20.75", "+21.00", "+21.25", "+21.50",
      "+21.75", "+22.00", "+22.25", "+22.50", "+22.75", "+23.00", "+23.25", "+23.50", "+23.75", "+24.00",
      "+24.25", "+24.50", "+24.75", "+25.00", "+25.25", "+25.50", "+25.75", "+26.00", "+26.25", "+26.50",
      "+26.75", "+27.00", "+27.25", "+27.50", "+27.75", "+28.00", "+28.25", "+28.50", "+28.75", "+29.00",
      "+29.25", "+29.50", "+29.75", "+30.00"]
  ophthalmology_axis = ['Plain',"0°", "15°", "30°", "45°", "60°", "75°", "90°", "105°", "120°", "135°", "150°", "165°", "180°"]
  distance_vision = ['Nill','6/4','6/5','6/6P','6/6','6/9P','6/9','6/12P','6/12','6/18P','6/18','6/24P','6/24','6/36P','6/36','6/60P','6/60']
  near_vision = ['Nill','N5','N5P','N6','N6P','N8','N8P','N10','N10P','N12','N12P','N14','N14P','N18','N18P','N20','N20P']
  conjunctiva = ['Clear','Pterygium','Congestion','Sub Conjunctival Hemorrhage','Pinguecula','Symblepharon','Papillae','Follicles','Conjunctival Tear'] 

  cornea = ["Clear", "Nebular Corneal Opacity", "Macular Corneal Opacity", "Leucomatous Corneal Opacity", "Nebulo Macular Corneal Opacity", "Pk Operated", "Pterygium", "Arcus Senilis", "Corneal Ulcer", "Corneal Tear", "Corneal Tear Operated", "Keratoconus", "Keratoglobus", "Pmcd", "Crocodile Shagreen Corneal Dystrophy", "Stromal Edema", "Striate Keratopathy", "Foreign Body", "Bullous Keratopathy", "Bulleae", "Large Kp’s", "Fine Kp’s", "Epithelial Defect", "Numular Opacity", "Spk", "Sutures Present", "Suture Marks", "Rk Operated", "Prk Operated"];

  anterior_chamber = ["Normal", "Cells +", "Hyphema", "Hypopyon", "Shallow", "Deep", "Aciol", "Quiet", "Vitreous In A/C", "Silicon Oil In A/C", "Cortical Matter In A/C", "Van Herick Grade 1", "Van Herick Grade 2", "Van Herick Grade 3", "Van Herick Grade 4",]

  pupil = ["R/R/R", "Irregular", "Non Reacting", "Rapd", "Eccentric Pupil", "Festooned Pupil", "Anisocoria", "Poorly Dilating", "Traumatic Mydriasis", "Sphincter Tear", "Non Dilating", "Pharmacologically Dilated"]

  iris = ["Normal", "Coloboma", "Anterior Synechiae", "Posterior Synechiae", "Heterochromia Iridium", "Pi Done", "Iritis", "Aniridia", "Adherent Leucoma", "Iridodonesis", "Lisch Nodules", "Neovascularization", "Iridodialysis"]

  lens = ["Clear", "Cataract", "Mature Cataract", "Mature White Cataract", "Brown Cataract", "Hypermature Senile Cataract", "Coca Cola Cataract", "Dislocated Lens", "Dislocated Cataract", "Subluxated Lens", "Subluxated Cataract", "Pseudophakia", "Aphakia", "Psc", "Cc", "Ns 1", "Ns 2", "Ns 3", "Ns 4", "Ns 5", "Posterior Polar Cataract", "Anterior Polar Cataract", "Traumatic Cataract", "Complicated Cataract", "Developemental Cataract", "Congenital Cataract", "Pigments On Lens Capsule", "Pseudoexfoliation", "Pigment Dispersion", "Lens Coloboma", "Lenticonus", "Phacodonesis", "Anterior Capsular Rupture", "Pco"]

  action:any = "";
  currentTab:any;
  btnSaveLoading:boolean= false;
  selectedAutoRefractionModel:any =  {};
  selectedRefractionModel:any = {}
  selectedAutoRefractionPopup:boolean = false;
  ophthalmic_examination_list:any = [];
  ophthalmic_examination:any = {
    auto_refraction:{
      right:{
        undilated:{
          spl:"",
          cyl:"",
          axis:"",
          vision:"",
        },
        dilated:{
          spl:"",
          cyl:"",
          axis:"",
          vision:"",
        },
        subjective:{
          spl:"",
          cyl:"",
          axis:"",
        },
        cycloplegic:{
          spl:"",
          cyl:"",
          axis:"",
        },
        
    
      },
      left:{
        undilated:{
          spl:"",
          cyl:"",
          axis:"",
        },
        dilated:{
          spl:"",
          cyl:"",
          axis:"",
        },
        subjective:{
          spl:"",
          cyl:"",
          axis:"",
        },
        cycloplegic:{
          spl:"",
          cyl:"",
          axis:"",
        },
        
    
      },
    },
    vision_acuity:{
      right:{
        dist:{
          unaided:"",
          with_glass:"",
          with_pinhole:"",
          pgp:"",
        },
        near:{
          unaided:"",
          with_glass:"",
          with_pinhole:"",
          pgp:"",
        }
      },
      left:{
        dist:{
          unaided:"",
          with_glass:"",
          with_pinhole:"",
          pgp:"",
        },
        near:{
          unaided:"",
          with_glass:"",
          with_pinhole:"",
          pgp:"",
        }
      },
      
    },
    refraction:{
      right:{
        dist:{
          spl:"",
          cyl:"",
          axis:"",
          add:"",
        },
        near:{
          spl:"",
          cyl:"",
          axis:"",
          add:"",
        }
      },
      left:{
        dist:{
          spl:"",
          cyl:"",
          axis:"",
          add:"",
        },
        near:{
          spl:"",
          cyl:"",
          axis:"",
          add:"",
        }
      }
    },
    fundus_examination:{
      right:{
        cup_to_disc:"",
        vitreous:"",
        optic_nerve	:"",
        macula:"",
        vassals:"",
        periphery:"",
      },
      left:{
        cup_to_disc:"",
        vitreous:"",
        optic_nerve	:"",
        macula:"",
        vassals:"",
        periphery:"",
      }
    },
    anterior_segment:{
      right:{
        lids_and_adnexa:"",
        conjucative:"",
        anterior_chamber:"",
        cornea:"",
        iris:"",
        pupil:"",
        lens:"",
      },
      left:{
        lids_and_adnexa:"",
        conjucative:"",
        anterior_chamber:"",
        cornea:"",
        iris:"",
        pupil:"",
        lens:"",
      }
    },
    iop:{
      right:{
        method:"",
        iop_time:"",
        iop:"",
        mm_of_hg:"",
        cct:"",
      },
      left:{
        method:"",
        iop_time:"",
        iop:"",
        mm_of_hg:"",
        cct:"",
      }
    }
  
  }
  range_templates:any = {
    spherical_range:[],
    cylindrical_range:[],
    axis_range:[],
  }
  reading_unit:any = "+";
  id:any;
  layoutLoading:boolean = false;
  currentDate:any;
  current_app_id:any;
  current_patient_id:any
  constructor(public route:ActivatedRoute,private opdChartAPI:OpdchartService, private toast:ToastrService, public dialog: MatDialog,private modalService: NgbModal,private api:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.current_app_id = params['app_id']
      })
  }
toggleUnit(){
  this.reading_unit = (this.reading_unit == '+' ? '-' : '+')
}

ngAfterViewInit()
{
  this.getPatientOphthalmicData();
}
  createReadingTemplate()
  {
    var num = 0.00;
    for(let i=0;i<8;i++)
    {
      let temp = []
      for(let j=0;j<=15;j++)
      {
        temp.push(num);
        num= num + 0.25
      }
    this.range_templates.spherical_range.push(temp)
    this.range_templates.cylindrical_range.push(temp)

    }

  let numAxis = 0;

  for(let j=0;j<5;j++)
  {
    let tempAxis = []
    for(let k=0;k<10;k++)
    {
      numAxis= numAxis + 5
      tempAxis.push(numAxis);

    }
   
    this.range_templates.axis_range.push(tempAxis)

   }
  
  }

  saveOphthalmicExamination()
  {
 // alert(this.id)
 //console.log(this.removeParentIfChildIsNull(this.ophthalmic_examination))
   if(this.id)
   {
    this.btnSaveLoading = true;
    let param = {
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      id:this.id,
      app_id:this.app_data.appointment.app_id,
      ophthalmic_examinations:this.ophthalmic_examination,
      }


     this.opdChartAPI.updateOpdChartData(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.btnSaveLoading = false;
          this.toast.success("Data Saved successfully...")
          this.modalService.dismissAll()
          this.getPatientOphthalmicData();
          this.ophthalmic_examination =  {
                    auto_refraction:{
                      right:{
                        undilated:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        dilated:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        subjective:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        cycloplegic:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        
                    
                      },
                      left:{
                        undilated:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        dilated:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        subjective:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        cycloplegic:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        
                    
                      },
                    },
                    vision_acuity:{
                      right:{
                        dist:{
                          unaided:"",
                          with_glass:"",
                          with_pinhole:"",
                          pgp:"",
                        },
                        near:{
                          unaided:"",
                          with_glass:"",
                          with_pinhole:"",
                          pgp:"",
                        }
                      },
                      left:{
                        dist:{
                          unaided:"",
                          with_glass:"",
                          with_pinhole:"",
                          pgp:"",
                        },
                        near:{
                          unaided:"",
                          with_glass:"",
                          with_pinhole:"",
                          pgp:"",
                        }
                      },
                      
                    },
                    refraction:{
                      right:{
                        dist:{
                          spl:"",
                          cyl:"",
                          axis:"",
                          add:"",
                        },
                        near:{
                          spl:"",
                          cyl:"",
                          axis:"",
                          add:"",
                        }
                      },
                      left:{
                        dist:{
                          spl:"",
                          cyl:"",
                          axis:"",
                          add:"",
                        },
                        near:{
                          spl:"",
                          cyl:"",
                          axis:"",
                          add:"",
                        }
                      }
                    },
                    fundus_examination:{
                      right:{
                        cup_to_disc:"",
                        vitreous:"",
                        optic_nerve	:"",
                        macula:"",
                        vassals:"",
                        periphery:"",
                      },
                      left:{
                        cup_to_disc:"",
                        vitreous:"",
                        optic_nerve	:"",
                        macula:"",
                        vassals:"",
                        periphery:"",
                      }
                    },
                    anterior_segment:{
                      right:{
                        lids_and_adnexa:"",
                        conjucative:"",
                        anterior_chamber:"",
                        cornea:"",
                        iris:"",
                        pupil:"",
                        lens:"",
                      },
                      left:{
                        lids_and_adnexa:"",
                        conjucative:"",
                        anterior_chamber:"",
                        cornea:"",
                        iris:"",
                        pupil:"",
                        lens:"",
                      }
                    },
                    iop:{
                      right:{
                        method:"",
                        iop_time:"",
                        iop:"",
                        mm_of_hg:"",
                        cct:"",
                      },
                      left:{
                        method:"",
                        iop_time:"",
                        iop:"",
                        mm_of_hg:"",
                        cct:"",
                      }
                    }
                  
                  }
        }
      },error:(e)=>{
        this.btnSaveLoading = false;
      }
     })
   }
   else{
    this.btnSaveLoading = true;
    let param = {
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      patient_id:this.app_data.id,
      app_id:this.app_data.appointment.app_id,
      ophthalmic_examinations:this.ophthalmic_examination,
      }
      
  
     this.opdChartAPI.createOpdChartData(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.btnSaveLoading = false;
          this.toast.success("Data Saved successfully...")
          this.modalService.dismissAll()
          this.getPatientOphthalmicData();
          this.ophthalmic_examination =  {
                    auto_refraction:{
                      right:{
                        undilated:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        dilated:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        subjective:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        cycloplegic:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        
                    
                      },
                      left:{
                        undilated:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        dilated:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        subjective:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        cycloplegic:{
                          spl:"",
                          cyl:"",
                          axis:"",
                        },
                        
                    
                      },
                    },
                    vision_acuity:{
                      right:{
                        dist:{
                          unaided:"",
                          with_glass:"",
                          with_pinhole:"",
                          pgp:"",
                        },
                        near:{
                          unaided:"",
                          with_glass:"",
                          with_pinhole:"",
                          pgp:"",
                        }
                      },
                      left:{
                        dist:{
                          unaided:"",
                          with_glass:"",
                          with_pinhole:"",
                          pgp:"",
                        },
                        near:{
                          unaided:"",
                          with_glass:"",
                          with_pinhole:"",
                          pgp:"",
                        }
                      },
                      
                    },
                    refraction:{
                      right:{
                        dist:{
                          spl:"",
                          cyl:"",
                          axis:"",
                          add:"",
                        },
                        near:{
                          spl:"",
                          cyl:"",
                          axis:"",
                          add:"",
                        }
                      },
                      left:{
                        dist:{
                          spl:"",
                          cyl:"",
                          axis:"",
                          add:"",
                        },
                        near:{
                          spl:"",
                          cyl:"",
                          axis:"",
                          add:"",
                        }
                      }
                    },
                    fundus_examination:{
                      right:{
                        cup_to_disc:"",
                        vitreous:"",
                        optic_nerve	:"",
                        macula:"",
                        vassals:"",
                        periphery:"",
                      },
                      left:{
                        cup_to_disc:"",
                        vitreous:"",
                        optic_nerve	:"",
                        macula:"",
                        vassals:"",
                        periphery:"",
                      }
                    },
                    anterior_segment:{
                      right:{
                        lids_and_adnexa:"",
                        conjucative:"",
                        anterior_chamber:"",
                        cornea:"",
                        iris:"",
                        pupil:"",
                        lens:"",
                      },
                      left:{
                        lids_and_adnexa:"",
                        conjucative:"",
                        anterior_chamber:"",
                        cornea:"",
                        iris:"",
                        pupil:"",
                        lens:"",
                      }
                    },
                    iop:{
                      right:{
                        method:"",
                        iop_time:"",
                        iop:"",
                        mm_of_hg:"",
                        cct:"",
                      },
                      left:{
                        method:"",
                        iop_time:"",
                        iop:"",
                        mm_of_hg:"",
                        cct:"",
                      }
                    }
                  
                  }
        }
      },error:(e)=>{
        this.btnSaveLoading = false;
      }
     })
   }
  
  }
  removeParentIfChildIsNull(data:any) {
    for (const key in data) {
        if (data[key] === null) {
            delete data[key];
        } else if (typeof data[key] === 'object') {
            // Recursively check child objects
            this.removeParentIfChildIsNull(data[key]);
        }
    }
}
  
  OpenAutoRefractionTemplate(eye:any,group:any,parameter:any,template:any,title:any)
  {
    this.selectedAutoRefractionPopup = true;
    this.selectedRefractionModel = {}
    this.selectedAutoRefractionModel =  {eye:eye,group:group,parameter:parameter,template:template,title:title};
  }
  OpenVisionAcuityTemplate(eye:any,group:any,parameter:any)
  {
  }
  OpenRefractionTemplate(eye:any,group:any,parameter:any)
  {
    this.selectedAutoRefractionModel  = {}
    this.selectedRefractionModel = {eye:eye,group:group,parameter:parameter};
  }

  OpenAnteriorSegmentTemplate(eye:any,group:any,parameter:any)
  {

  }
  OpenFundusExaminationTemplate(eye:any,group:any,parameter:any)
  {

  }
  OpenIOPTemplate(eye:any,group:any,parameter:any)
  {

  }
  addReadings(value: any)
  {
    if(this.selectedAutoRefractionModel['eye'] !=undefined)
    {
     // console.log(this.selectedAutoRefractionModel)
      this.ophthalmic_examination.auto_refraction[this.selectedAutoRefractionModel.eye][this.selectedAutoRefractionModel.group][this.selectedAutoRefractionModel.parameter] = value;
    }
    if(this.selectedRefractionModel['eye'] !=undefined)
    {
      this.ophthalmic_examination.refraction[this.selectedRefractionModel.eye][this.selectedRefractionModel.group][this.selectedRefractionModel.parameter] = value;

    }

  }

  getPatientOphthalmicData()
  {
  // console.log(this.app_data)
   let param = {
     where:{
    hospital_id:this.localStorage.getHospitalId(),
     center_id:this.localStorage.getCenterId(),
     patient_id:this.current_patient_id,
     },
     attributes:['id','app_id','hospital_id','patient_id','center_id','ophthalmic_examinations','createdAt']
   }
   this.opdChartAPI.getAllOpdChartData(param).subscribe({
     next:(res)=>{
      if(res)
      {
        this.count = res.filter((item:any)=>{return item.ophthalmic_examinations }).length;
        this.ophthalmic_examination_list = res.filter((item:any)=>{return item.app_id != this.app_data.appointment.app_id && item.ophthalmic_examinations });
        let existingObject  = res.filter((item:any)=>{return item.app_id == this.app_data.appointment.app_id })[0]
        if(existingObject!=undefined)
        {
          this.id =  existingObject.id || undefined;
          if(existingObject.ophthalmic_examinations){
          this.currentTab = existingObject.ophthalmic_examinations;
          this.currentDate = existingObject.createdAt || "";
         // console.log(this.currentTab)
          
          }
        }
         this.layoutLoading = true
      }
  
      // this.ophthalmic_examination_list = res.filter((item:any)=>{return item.app_id != this.app_data.appointment.app_id})
     },
     error:(e)=>{
 
     }
   })
  }
 OpenNewCheckup(content:any)
 {
    this.modalService.open(content,  { fullscreen:true, size:'xl', scrollable: true,ariaLabelledBy: 'modal-basic-title' });
    this.action = "add";
    setTimeout(() => {
      this.openForm = true
      this.createReadingTemplate()
    }, 500);

 }
 edit(content:any,item:any)
 {
  this.modalService.open(content,  {  fullscreen:true, size:'xl', scrollable: true,ariaLabelledBy: 'modal-basic-title' });
  this.action = "edit";
  setTimeout(() => {
    this.openForm = true
    this.createReadingTemplate();
    //console.log(item)
    this.ophthalmic_examination = item
  }, 500);

 
 }
 replaceWithSpace(value:any)
 {
  return value.replaceAll("_"," ")
 }

}
