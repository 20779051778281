import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private http: HttpClient) { }

  getAllPriceMasterWithCount(data:any): Observable<any> {
    return this.http.post(environment.getAllPriceMasterWithCount,data);
  }
  getAllPriceCategory(): Observable<any> {
    return this.http.get(environment.getAllPriceCategory);
  }
  createPriceMaster(data:any): Observable<any> {
    return this.http.post(environment.createPriceMaster,data);
  }
  getPriceMaster(data:any): Observable<any> {
    return this.http.post(environment.getPriceMaster,data);
  }
  UpdatePriceMaster(data:any): Observable<any> {
    return this.http.post(environment.updatePriceMaster,data);
  }
  getPatientBySearch(data:any): Observable<any> {
    return this.http.post(environment.getPatientBySearch,data);
  }
  getDiagnosisTemplate(data:any): Observable<any> {
    return this.http.post(environment.getDiagnosisTemplate,data);
  }
  getAllPriceMaster(data:any): Observable<any> {
    return this.http.post(environment.getAllPriceMaster,data);
  }
}
