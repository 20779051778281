import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-feedback-widget',
  templateUrl: './feedback-widget.component.html',
  styleUrls: ['./feedback-widget.component.scss']
})
export class FeedbackWidgetComponent {
  @Input() data:any;
  @Input() id:any;
  @Input() Title:any;
}
