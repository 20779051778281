import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent {
  @Input() data: any;
  @Input() id: any;
  @Input() Title: any;
  @Input() colors: any;
  
  ngAfterViewInit() {
    console.log(this.colors)

    // || '#74A3C3' || '#469BD3'  || '#1369A3' || '#104358' 
    this.colors = this.colors || ['#104358', '#1369A3', '#469BD3', '#74A3C3']

    const analysis_daily_opt: any = document.getElementById("canvas_" + this.id);
    const analysis_daily_ctx = analysis_daily_opt.getContext('2d');

    let chartStatus = Chart.getChart(this.id); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();


    }
    var _labels = [];
    var values = [];
    if (this.data) {
      for (var key in this.data) {

        _labels.push(key);
        values.push(this.data[key])
      }
    }


    let myChart2 = new Chart(analysis_daily_ctx, {
      type: 'bar',
      data: {
        labels: _labels,
        datasets: [{
          data: values,
          backgroundColor: [
            this.colors[0] ,
            this.colors[1] ,
            this.colors[2],
            this.colors[3] ,

          ],
          borderWidth: 1
        }]
      },

      options: {
        animation: {
          duration: 1,
          onComplete: function ({ chart }) {
            const ctx = chart.ctx;

            chart.config.data.datasets.forEach(function (dataset, i) {
              const meta = chart.getDatasetMeta(i);

              meta.data.forEach(function (bar, index) {
                const data: any = dataset.data[index];
                console.log(data)

                ctx.fillText(data, bar.x, bar.y - 5);
              });
            });
          }
        },
        plugins: {

          legend: {
            display: false
          },
        },
        responsive: false,
        // display: true,
        scales: {

          x: {
            border: {
              display: false
            },
            grid: {
              display: false,

            },
            ticks: {
              display: true,

            },
          },
          y: {
            border: {
              display: false
            },
            grid: {
              display: false
            },
            ticks: {
              display: true,
            },
          },
        },
      },
    })

  }

}
