import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import {
  merge,
  startWith,
  switchMap,
  catchError,
  map,
  Subject,
  debounceTime,
} from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { InventoryService } from 'src/app/private/services/inventory.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { SupplyService } from 'src/app/private/services/supply.service';

@Component({
  selector: 'app-supply-inventory',
  templateUrl: './supply-inventory.component.html',
  styleUrls: ['./supply-inventory.component.scss'],
})
export class SupplyInventoryComponent {
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  formAction = 'add';
  closeResult = '';
  isSubmitted: boolean = false;
  supplyTypeList: any = [
    "Medications",
    "Dressings and Bandages",
    "Disposable Gloves",
    "Disposable Masks",
    "Disposable Gowns and Aprons",
    "Disposable Caps and Shoe Covers",
    "Catheters and Tubings",
    "IV Fluids",
    "Surgical Supplies",
    "Diagnostic Kits",
    "Oxygen Supplies",
    "Nutritional Supplements",
    "Sanitizers and Disinfectants",
    "Sterile Water",
    "Incontinence Products",
    "Ophthalmic Medications",
    "Eye Patches",
    "Ophthalmic Instruments",
    "Eye Shields",
    "Artificial Tears",
    "Contact Lens Solutions",
    "Tonometry Supplies",
    "Eye Lubricants"
]
;
  displayedColumns: string[] = [
    'name',
    'manufacturer',
    // 'composition',
    'type',
    'retail_price',
    'stock',
    'ava_stock',
    'strength',
    'expiry_date',
    'status',
    'action',
  ];
  exportColumns: any = [
    'Name',
    'manufacturer',
    // 'composition',
    'Type',
    'Retail Price',
    'stock',
    'Available Stock',
    'strength',
    'Expiry Date',
    'Status',
  ];
  exportArrayData: any = [];
  supplyObject: SupplyVal = new SupplyVal();
  taxList: any = [{ id: 1, name: 'Service Tax 14' }];
  units: any = [
    { id: 1, name: 'mg' },
    { id: 2, name: 'g' },
    { id: 3, name: 'mL' },
    { id: 4, name: 'L' },
    { id: 5, name: 'tablet' },
    { id: 6, name: 'capsule' },
    { id: 7, name: 'drop' },
    { id: 8, name: 'unit' },
    { id: 9, name: 'puff' },
    { id: 10, name: 'patch' },
    { id: 11, name: 'spray' },
    { id: 12, name: 'piece' },
  ];
  manufactures: any = [
    { id: 1, name: 'Pfizer' },
    { id: 2, name: 'Novartis' },
    { id: 3, name: 'Merck & Co.' },
    { id: 4, name: 'Johnson & Johnson' },
    { id: 5, name: 'Roche' },
    { id: 6, name: 'Sanofi' },
    { id: 7, name: 'GlaxoSmithKline' },
    { id: 8, name: 'AstraZeneca' },
    { id: 9, name: 'AbbVie' },
    { id: 10, name: 'Bayer' },
    { id: 11, name: 'Eli Lilly and Company' },
    { id: 12, name: 'Bristol-Myers Squibb' },
    { id: 13, name: 'Gilead Sciences' },
    { id: 14, name: 'Takeda Pharmaceutical Company' },
    { id: 15, name: 'Amgen' },
    { id: 16, name: 'Boehringer Ingelheim' },
    { id: 17, name: 'Mylan' },
    { id: 18, name: 'Daiichi Sankyo' },
    { id: 19, name: 'Perrigo' },
    { id: 20, name: 'Sun Pharmaceutical' },
  ];

  filteredMedicines: any = [];
  tempMedicine: any = [];
  searchLoading = false;

  drugSearch = new Subject<string>();
  constructor(
    private router: Router,
    private toast: ToastrService,
    private api: SupplyService,
    private modalService: NgbModal,
    private localStorage: LocalStorageService,
    private offcanvasService: NgbOffcanvas
  ) {

    this.drugSearch.pipe(debounceTime(300)).subscribe((value) => {
      if (typeof value == 'string') {
        this.filteredMedicines = this.tempMedicine.filter((item: any) =>
          item.medicine_name.toLowerCase().includes(value.toLowerCase())
        );
      }
    });
  }
  displayWith(value: any) {
    return value?.medicine_name;
  }

  ngAfterViewInit() {
    this.loadData();
  }
  dateFilter(event: any) {
    this.dateQuery.setValue(event);
  }

  // load Initial table data from based on source type
  loadData() {
    try {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(
        this.dateQuery.valueChanges,
        this.searchQuery.valueChanges,
        this.sort.sortChange,
        this.paginator.page
      )
        .pipe(
          startWith({}),
          switchMap(() => {
            let param = {
              filter: this.searchQuery.value,
              page: this.paginator.pageIndex * this.paginator.pageSize,
              limit: this.paginator.pageSize,
              // order: [{ col: 'supply_id', dir: 'desc' }],
            };
            if (this.dateQuery.value) {
              param = { ...param, ...this.dateQuery.value };
            }
            if (
              this.localStorage.checkFullAccessForTableList(
                this.localStorage.getRole()
              )
            ) {
              param = param;
            } else {
              param = {
                ...param,
                ...{ user_id: this.localStorage.getUserId() },
              };
            }
            return this.api
              .getAllSupplyWithCount(param)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            if (response === null) {
              return [];
            }

            this.totalRows = response.count;
            return response;
          })
        )
        .subscribe({
          next: (data) => {
            // console.log(data)
            this.exportArrayData = data.rows;
            this.dataSource = new MatTableDataSource<any>(data.rows);
          },
          error: (e) => {
            console.error(e);
          },
          complete: () => {},
        });
    } catch (e) {
      console.error(e);
    }
  }
  searchFilter(query: any) {
    // alert(query)
    this.searchQuery.setValue(query);
  }


  open(content: any) {
    this.supplyObject = new SupplyVal();
    this.formAction = 'add';
    this.modalService.open(content, { size: 'xl', scrollable: true });
  }

  edit(content: any, element: any) {
    this.formAction = 'edit';
    this.supplyObject = element;
    this.modalService.open(content, { size: 'xl', scrollable: true });
  }

  deleteDrug(element: any) {
    const data = {
      supply_id: element.supply_id,
    }
    this.api.deleteSupply(data).subscribe(
      (data) => {
        if (data) {
          this.toast.success('Supply deleted successfully');
          this.loadData();
        }
      },
      (error) => {
        this.toast.error('Failed to delete supply');
      }
    );
  }

  save() {
    this.isLoading = true;
    this.supplyObject.available_quantity = this.supplyObject.quantity;
    this.api.addSupply(this.supplyObject).subscribe(
      (data) => {
        if (data) {
          this.isLoading = false;
          this.toast.success('Supply added successfully');
          this.modalService.dismissAll();
          this.loadData();
         
        }
      },
      (error) => {
        this.isLoading = false;
        this.toast.error('Failed to add supply');
      }
    );
  }

  update() {
    this.isLoading = true;
    this.supplyObject.available_quantity = this.supplyObject.quantity;
    this.api.updateSupply(this.supplyObject).subscribe(
      (data) => {
        if (data) {
          this.isLoading = false;
          this.toast.success('Supply updated successfully');
          this.modalService.dismissAll();
          this.loadData();
        }
      },
      (error) => {
        this.isLoading = false;
        this.toast.error('Failed to update supply');
      }
    );
  }

  export(header: any) {
    //console.log(header)
    let excelRowData: any = [];
    this.exportArrayData.forEach((element: any) => {
      excelRowData.push({
        0: element.name,
        1: element.email,
        2: element.mobile,
        3: element.rating,
        4: element.feedback,
        5: element.date_added,
      });
    });

    let excelData = [];
    let excelHeader = [];
    for (let i = 0; i < excelRowData.length; i++) {
      let element = excelRowData[i];
      let obj: any = {};
      for (let index = 0; index < header.length; index++) {
        let key = header[index]['key'];
        obj[key] = element[key];
      }
      excelData.push(obj);
    }
    for (let index = 0; index < header.length; index++) {
      let value = header[index]['value'];
      excelHeader.push(value);
    }
    //console.log(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
    XLSX.utils.sheet_add_json(ws, excelData, {
      origin: 'A2',
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    XLSX.writeFile(
      wb,
      'Out_Patient_' +
        day +
        '_' +
        month +
        '_' +
        year +
        '_' +
        date.getTime() +
        '.csv'
    );
  }
}
function observableOf(arg0: null): any {
  throw new Error('Function not implemented.');
}

export class SupplyVal {
  supply_name: string;
  category: string;
  composition: string;
  manufacturer: string;
  batch_number: string;
  expiration_date: string;
  quantity: number;
  retail_price: string;
  unit_price: string;
  stocking_unit: string;
  tax: any;
  strength: any;
  available_quantity: number;
  instruction: string;
  status: number;
  hospital_id: number;
  center_id: number;
  constructor() {
    this.supply_name = '';
    this.category = '';
    this.composition = '';
    this.manufacturer = '';
    this.batch_number = '';
    this.expiration_date = '';
    this.quantity = 0;
    this.retail_price = '';
    this.unit_price = '';
    this.stocking_unit = '';
    this.tax = {};
    this.strength = { power: '', unit: '' };
    this.available_quantity = 0;
    this.instruction = '';
    this.status = 0;
    this.hospital_id = 0;
    this.center_id = 0;
  }
}
