import { Component } from '@angular/core';

@Component({
  selector: 'app-opticals',
  templateUrl: './opticals.component.html',
  styleUrls: ['./opticals.component.scss']
})
export class OpticalsComponent {

}
