import { Component } from '@angular/core';

@Component({
  selector: 'app-equipment-inventory',
  templateUrl: './equipment-inventory.component.html',
  styleUrls: ['./equipment-inventory.component.scss']
})
export class EquipmentInventoryComponent {

}
