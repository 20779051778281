import { Component, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PricingDashboardService } from 'src/app/private/services/pricing-dashboard.service';

declare var Razorpay: any;

@Component({
  selector: 'app-pricing-dashboard',
  templateUrl: './pricing-dashboard.component.html',
  styleUrls: ['./pricing-dashboard.component.scss'],
})
export class PricingDashboardComponent {
  serviceData: any = [];
  selectedDuration: any;
  cartArray: any = [];
  selectionValue: any = [];
  loading: Boolean = false;
  totalAmount: any = 0;

  constructor(
    private api: PricingDashboardService,
    private offcanvasService: NgbOffcanvas,
    private toast: ToastrService
  ) {
    this.getAddOnService();
  }

  ngOnInit(): void {}

  getAddOnService() {
    const payload = {
      status: true,
    };
    this.loading = true;
    this.api.getAddOnService(payload).subscribe({
      next: (response) => {
        this.loading = false;
        this.serviceData = response;
        this.serviceData.map((val: any, ind: any) => {
          this.selectionValue.push({ selectedValue: {} });
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  isAlready(ind: any) {
    if (
      this.cartArray.find(
        (element: any) =>
          element['service'] === this.selectionValue[ind]['service']
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  updateCart(ind: any) {
    if (this.isAlready(ind)) {
      const index = this.cartArray.findIndex(
        (val: any) => val['service'] === this.selectionValue[ind]['service']
      );
      this.cartArray[index] = this.selectionValue[ind];
    }
  }

  removeFromCart(ind: any) {
    const index = this.cartArray.findIndex(
      (val: any) => val['service'] === this.selectionValue[ind]['service']
    );
    this.selectionValue[ind] = { selectedValue: {} };
    this.cartArray.splice(index, 1);
  }

  addToCart(ind: any) {
    if (this.isAlready(ind)) {
      const index = this.cartArray.findIndex(
        (val: any) => val['service'] === this.selectionValue[ind]['service']
      );
      this.cartArray[index] = this.selectionValue[ind];
    } else {
      this.cartArray.push(this.selectionValue[ind]);
    }
  }

  OpenCart(content: TemplateRef<any>) {
    this.totalAmount = 0;
    this.cartArray.map((val: any) => {
      this.totalAmount += parseInt(val['selectedValue']['price']);
    });
    this.offcanvasService.open(content, { position: 'end' });
  }

  payNow() {
    const toaster = this.toast;
    const off = this.offcanvasService;
    const RazorpayOptions = {
      description: 'Sample Razorpay demo',
      currency: 'INR',
      amount: 100 * this.totalAmount, // Amount in paisa (1000 INR)
      name: 'Rudhram',
      key: 'rzp_test_yXSy4v6XGiTsdg', // Your Razorpay Key Id
      image: 'https://tina.gravitasai.com/emr/assets/img/mainlogo.png',
      prefill: {
        name: 'Rudhram Saraswat',
        email: 'rudhram@gmail.com',
        contact: '9587909621', // Use 'contact' instead of 'phone'
      },
      handler: function (response: any) {
        console.log('done');
        console.log(response);
        off.dismiss('Cross click');
        toaster.success('Payment Successful', 'Success', {
          timeOut: 3000,
        });
        resetVal();
      },
      theme: {
        color: '#6466e3',
      },
      modal: {
        ondismiss: () => {
          console.log('dismissed');
        },
      },
    };

    const resetVal = () => {
      this.cartArray = [];
      this.selectionValue.map((val: any, ind: any) => {
        this.selectionValue[ind] = { selectedValue: {} };
      });
    };

    const pay = Razorpay.open(RazorpayOptions);
    pay.on('payment.failed', function (response: any) {
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
    });
  }
}
