import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(

    private zone: NgZone
  ) {}

  handleError(error: any) {
    this.zone.run(() =>
    {
    console.error(error || 'Undefined client error')
     } );
  }
}