import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IpdchartService } from 'src/app/private/services/ipdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';


@Component({
  selector: 'app-ocular-investigation',
  templateUrl: './ocular-investigation.component.html',
  styleUrls: ['./ocular-investigation.component.scss']
})
export class OcularInvestigationComponent {
  @Input() titleOfHead: any = {
    title: 'IPD',
    root: 'Ocular Investigation'
  };
  @Input() patient:any;
  btnLoading:boolean = false;
  ocular_investigation:any = {
    vision:{re:"",le:""},
    refraction:{re:"",le:""},
    lids_and_adnexa:{re:"",le:""},
    lacrimal_syringing:{re:"",le:""},
    cornea_conjunctiva:{re:"",le:""},
    anterior_chamber:{re:"",le:""},
    irish:{re:"",le:""},
    pupil:{re:"",le:""},
    lense:{re:"",le:""},
    iop:{re:"",le:""},
    fundus_orthoptics:{re:"",le:""},
    iol_power:{re:"",le:""},
  }
  id:any;
  current_patient_id:any;
  ipd_app_id:any;
  layoutLoading:boolean = false;
  currentTab:any;
  constructor( private toast:ToastrService, public route:ActivatedRoute,private ipdChartData:IpdchartService, private templateService:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.ipd_app_id = params['ipd_app_id']
      })
  }
  ngAfterViewInit()
  {
    this.getPatientProcedureData();
  }
  getPatientProcedureData()
  {
    // console.log(this.app_data)

   let param = {
    where:{
   hospital_id:this.localStorage.getHospitalId(),
    center_id:this.localStorage.getCenterId(),
    patient_id:this.current_patient_id,
    },
    attributes:['id','ipd_app_id','hospital_id','patient_id','center_id','ocular_investigation']
  }
  this.ipdChartData.getAllIpdChartData(param).subscribe({
    next:(res)=>{
     if(res)
     {
      // this.count = res.filter((item:any)=>{return item.investigations}).length;
     //  this.investigationListData = res.filter((item:any)=>{return item.app_id != this.ipd_app.appointment.app_id && item.investigations });
       let existingObject  = res.find((item:any)=>{return item.ipd_app_id == this.ipd_app_id })
       if(existingObject!=undefined)
       {
         this.id =  existingObject.id || undefined;
         if(existingObject.ocular_investigation){
         this.currentTab = existingObject;
         this.ocular_investigation = existingObject.ocular_investigation;
         }
       }
       this.layoutLoading = true;

     }

    },
    error:(e)=>{

    }
  })
  }
  SaveOcularInvestigationData()
  {
    if(this.id)
    {
     this.btnLoading = true;
     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       id:this.id,
       ipd_app_id:this.ipd_app_id,
       ocular_investigation:this.ocular_investigation,
       }
      this.ipdChartData.updateIpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnLoading = false;
           this.toast.success("Data Saved successfully...")
         }
       },error:(e)=>{
         this.btnLoading = false;
       }
      })
    }
    else{
      this.btnLoading = true;

     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       patient_id:this.current_patient_id,
       ipd_app_id:this.ipd_app_id,
       ocular_investigation:this.ocular_investigation,
       }

      this.ipdChartData.createIpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnLoading = false;
           this.toast.success("Data Saved successfully...")

         }
       },error:(e)=>{
         this.btnLoading = false;
       }
      })
    }
  }
}
