import { Component } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas'
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IpdchartService } from 'src/app/private/services/ipdchart.service';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
@Component({
  selector: 'app-discharge-summery',
  templateUrl: './discharge-summery.component.html',
  styleUrls: ['./discharge-summery.component.scss']
})
export class DischargeSummeryComponent {

  current_patient_id:any;
  ipd_app_id:any;
  btnSaveLoading:boolean = false;
  dischargeDetailObject:any;
  constructor(private toast:ToastrService,public route:ActivatedRoute,private ipdChartData:IpdchartService, private templateService:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.ipd_app_id = params['ipd_app_id']
      })
  }

  ngAfterViewInit()
  {
    this.getDischargeDetail();
  }
  getDischargeDetail()
  {

    let param = {
   
      hospital_id:this.localStorage.getHospitalId(),
      center_id:this.localStorage.getCenterId(),
      patient_id:this.current_patient_id,
      id:this.ipd_app_id,
      }
     this.ipdChartData.getDischargeDetails(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.dischargeDetailObject = res;
        }
      },
      error:(e)=>{

      }
     })
    
  }
  async getImageFromCanvas(page:any)
  {
    const canvas = await html2canvas(page);
    console.log(canvas);
    return   canvas.toDataURL("image/png", 1.0);;
  }
  getDimensionPage(doc:any,image:any)
  {
    let bufferX = 5;
    const bufferY = 5;
    const imgProps = (<any>doc).getImageProperties(image);
    const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferY;
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    const type = "PNG";
    const compression = 'FAST';
    return {image,type, bufferX, bufferY, pdfWidth, pdfHeight, undefined, compression}
  }
  async printPage()
  {
    this.btnSaveLoading = true;
    const discharge_summery = document.getElementById('discharge_summery') as HTMLElement;
    const treatment_plan = document.getElementById('treatment_plan') as HTMLElement;
    const doc = new jsPDF();
     var discharge_summery_image:any  = await this.getImageFromCanvas(discharge_summery);
     var treatment_plan_image:any  = await this.getImageFromCanvas(treatment_plan);
      const param:any = this.getDimensionPage(doc,discharge_summery_image);
      const paramTreatment:any = this.getDimensionPage(doc,treatment_plan_image);
      console.log(param.pdfHeight);
      doc.addImage(param.image,param.type, param.bufferX, param.bufferY, param.pdfWidth, param.pdfHeight, undefined,param.compression);
      
      doc.addPage();
      doc.addImage(paramTreatment.image,paramTreatment.type, paramTreatment.bufferX, paramTreatment.bufferY, paramTreatment.pdfWidth, paramTreatment.pdfHeight, undefined,paramTreatment.compression);
      var pdfUrl = URL.createObjectURL(doc.output('blob'));
      window.open(pdfUrl, "_blank")?.print();
      this.btnSaveLoading = false;
  

   // doc.addPage();


 
  }
  exportAllToPDF(pages: HTMLElement) {

    
 
    // doc.html(pages, {
    //   callback: (doc: jsPDF) => {
    //     var pdfUrl = URL.createObjectURL(doc.output('blob'));
    //     let newWindow = window.open(pdfUrl, "_blank")?.print();
       
        
    //   },x: 10,
    //   y: 10
    // });
  }
}
