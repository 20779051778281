import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyComponent } from './component/body/body.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { ToolbarComponent } from './component/toolbar/toolbar.component';
import { HeaderComponent } from './component/header/header.component';
import { RouterModule } from '@angular/router';
import {CustomRangePanelComponent, DateFilterComponent } from './ui/date-filter/date-filter.component';
import { HomeComponent } from '../modules/home/home.component';
import { LeadComponent } from '../modules/lead/lead.component';
import { UserGraphComponent } from '../modules/components/user-graph/user-graph.component';
import { PopularTopicGraphComponent } from '../modules/components/popular-topic-graph/popular-topic-graph.component';
import { UsersComponent } from '../modules/configuration/users/users.component';
import { ScheduleComponent } from '../modules/configuration/schedule/schedule.component';
import { PriceSettingsComponent } from '../modules/configuration/price-settings/price-settings.component';
import { AppointmentComponent } from '../modules/appointment/appointment.component';
import { materialModules } from './module';
import { OpdComponent } from '../modules/opd/opd.component';
import { CreateAppointmentComponent } from '../modules/components/create-appointment/create-appointment.component';
import { AppointmentDetailComponent } from '../modules/components/appointment-detail/appointment-detail.component';
import { OpdDashboardComponent } from '../modules/components/opd-dashboard/opd-dashboard.component';
import { PatientComponent } from '../modules/components/patient/patient.component';
import { OpdBillingComponent } from '../modules/components/opd-billing/opd-billing.component';
import { EmailSettingsComponent } from '../modules/configuration/email-settings/email-settings.component';
import { ReportsComponent } from '../modules/reports/reports.component';
import { BillingStatementTableComponent } from '../modules/components/billing-statement-table/billing-statement-table.component';
import { PrimaryCheckupComponent } from '../modules/components/primary-checkup/primary-checkup.component';
import { OphthalmicExaminationComponent } from '../modules/components/ophthalmic-examination/ophthalmic-examination.component';
import { MedicationsComponent } from '../modules/components/medications/medications.component';
import { InvestigationsComponent } from '../modules/components/investigations/investigations.component';
import { PrescriptionsComponent } from '../modules/components/prescriptions/prescriptions.component';
import { OperativeTreatmentAdviceComponent } from '../modules/components/operative-treatment-advice/operative-treatment-advice.component';
import { IpdComponent } from '../modules/ipd/ipd.component';
import { SearchPatientComponent } from '../modules/components/search-patient/search-patient.component';
import { MultiTagInputComponent } from '../modules/components/multi-tag-input/multi-tag-input.component';
import { ProcedureSearchInputComponent } from '../modules/components/procedure-search-input/procedure-search-input.component';
import { IpdDashboardComponent } from '../modules/components/ipd-dashboard/ipd-dashboard.component';
import { IpdAdmissionDetailComponent } from '../modules/components/ipd-admission-detail/ipd-admission-detail.component';
import { DataImportComponent } from '../modules/components/data-import/data-import.component';
import { ClickOutsideDirective } from '../directives/clickOutside.directive';
import { IpdProceduresComponent } from '../modules/components/ipd-procedures/ipd-procedures.component';
import { DischargeMedicationComponent } from '../modules/components/discharge-medication/discharge-medication.component';
import { GeneralInvestigationComponent } from '../modules/components/general-investigation/general-investigation.component';
import { IpdBillingComponent } from '../modules/components/ipd-billing/ipd-billing.component';
import { OcularInvestigationComponent } from '../modules/components/ocular-investigation/ocular-investigation.component';
import { MedicationDosageComponent } from '../modules/components/medication-dosage/medication-dosage.component';
import { DischargeSummeryComponent } from '../modules/components/discharge-summery/discharge-summery.component';
import { IpdFollowUpComponent } from '../modules/components/ipd-follow-up/ipd-follow-up.component';
import { NumberToWordsPipe } from '../pipes/number-to-words.pipe';
import { ProcedurePackageComponent } from '../modules/configuration/procedure-package/procedure-package.component';
import { CampaignComponent } from '../modules/campaign/campaign.component';
import { WhatsappCampaignComponent } from '../modules/components/whatsapp-campaign/whatsapp-campaign.component';
import { SubscriberListComponent } from '../modules/components/subscriber-list/subscriber-list.component';
import { ManageTicketsComponent } from '../modules/manage-tickets/manage-tickets.component';
import { IpdReportComponent } from '../modules/components/ipd-report/ipd-report.component';
import { FeedbackComponent } from '../modules/feedback/feedback.component';
import { DrugInventoryComponent } from '../modules/configuration/drug-inventory/drug-inventory.component';
import { SupplyInventoryComponent } from '../modules/configuration/supply-inventory/supply-inventory.component';
import { EquipmentInventoryComponent } from '../modules/configuration/equipment-inventory/equipment-inventory.component';
import { ChartWithCircleComponent } from '../modules/components/chart-with-circle/chart-with-circle.component';
import { PieChartComponent } from '../modules/components/pie-chart/pie-chart.component';
import { BarChartComponent } from '../modules/components/bar-chart/bar-chart.component';
import {LeadTimeChartComponent} from '../modules/components/lead-time-chart/lead-time-chart.component';
import { HorizontalBarChartComponent } from '../modules/components/horizontal-bar-chart/horizontal-bar-chart.component';
import { RatingBarChartComponent } from '../modules/components/rating-bar-chart/rating-bar-chart.component';
import { FeedbackWidgetComponent } from '../modules/components/feedback-widget/feedback-widget.component';
import { PopulerTimingGraphComponent } from '../modules/components/populer-timing-graph/populer-timing-graph.component';
import { HeaderInfoComponent } from './component/header-info/header-info.component';
import { AnalyticsAppointmentsDetailsComponent } from '../modules/components/analytics-appointments-details/analytics-appointments-details.component';
import { NextPatientDetailsComponent } from '../modules/components/doctor-tab/next-patient-details/next-patient-details.component';
import { AppointmentRequestComponent } from '../modules/components/doctor-tab/appointment-request/appointment-request.component';
import { PatientReviewGraphComponent } from '../modules/components/doctor-tab/patient-review-graph/patient-review-graph.component';
import { InvestigationTypeChartComponent } from '../modules/components/investigation-type-chart/investigation-type-chart.component';
import { BillingItemsComponent } from '../modules/components/billing-items/billing-items.component';
import { PricingDashboardComponent } from '../modules/configuration/pricing-dashboard/pricing-dashboard.component';
import { OpticalsComponent } from '../modules/opticals/opticals.component';
import { PharmacyComponent } from '../modules/pharmacy/pharmacy.component';
import { CreditNotesStatmentTableComponent } from '../modules/components/credit-notes-statment-table/credit-notes-statment-table.component';
import { OpdReceiptComponent } from '../templates/opd-receipt/opd-receipt.component';


@NgModule({
  declarations: [
    HeaderComponent,
    BodyComponent,
    SidebarComponent,
    ToolbarComponent,
    DateFilterComponent,
    DataImportComponent,
    CustomRangePanelComponent,
    HomeComponent,
    LeadComponent,
    UserGraphComponent,
    PopularTopicGraphComponent,
    UsersComponent,
    ScheduleComponent,
    PriceSettingsComponent,
    AppointmentComponent,
    OpdComponent,
    CreateAppointmentComponent,
    PatientComponent,
    OpdDashboardComponent,
    AppointmentDetailComponent,
    OpdBillingComponent,
    EmailSettingsComponent,
    ReportsComponent,
    BillingStatementTableComponent,
    PrimaryCheckupComponent,
    OphthalmicExaminationComponent,
    MedicationsComponent,
    InvestigationsComponent,
    PrescriptionsComponent,
    OperativeTreatmentAdviceComponent,
    IpdComponent,
    SearchPatientComponent,
    MultiTagInputComponent,
    ProcedureSearchInputComponent,
    IpdDashboardComponent,
    IpdAdmissionDetailComponent,
    ClickOutsideDirective,
    IpdProceduresComponent,
    GeneralInvestigationComponent,
    OcularInvestigationComponent,
    DischargeMedicationComponent,
    IpdFollowUpComponent,
    IpdBillingComponent,
    MedicationDosageComponent,
    DischargeSummeryComponent,
    NumberToWordsPipe,
    ProcedurePackageComponent,
    CampaignComponent,
    WhatsappCampaignComponent,
    SubscriberListComponent,
    ManageTicketsComponent,
    IpdReportComponent,
    FeedbackComponent,
    DrugInventoryComponent,
    SupplyInventoryComponent,
    EquipmentInventoryComponent,
    ChartWithCircleComponent,
    PieChartComponent,
    BarChartComponent,
    HorizontalBarChartComponent,
    RatingBarChartComponent,
    FeedbackWidgetComponent,
    PopulerTimingGraphComponent,
    HeaderInfoComponent,
    LeadTimeChartComponent,
    AnalyticsAppointmentsDetailsComponent,
    NextPatientDetailsComponent,
    AppointmentRequestComponent,
    PatientReviewGraphComponent,
    InvestigationTypeChartComponent,
    PricingDashboardComponent,
    OpticalsComponent,
    PharmacyComponent,
    BillingItemsComponent,
    CreditNotesStatmentTableComponent,
    OpdReceiptComponent,
  ],
  imports: [
    CommonModule,
    RouterModule, 
    ...materialModules,
    

  ]
  ,
  exports:[
    HeaderComponent,
    BodyComponent,
    SidebarComponent,
    ToolbarComponent,
    
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
