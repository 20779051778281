import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IpdAppointmentService } from 'src/app/private/services/ipdappointment.service';
import { UsersService } from 'src/app/private/services/users.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ipd-admission-detail',
  templateUrl: './ipd-admission-detail.component.html',
  styleUrls: ['./ipd-admission-detail.component.scss']
})
export class IpdAdmissionDetailComponent {
  @Input() patient: any;
  titleOfHead: any = {
    title: 'IPD',
    root: 'Admission Details'
  }
  loading:boolean = false;
  ipdAppointment:IpdAppointment =  new IpdAppointment();
  @ViewChild("ipdAppointmentForm")
  ipdAppointmentForm!: NgForm;
  @ViewChild('SubmitForm')
  SubmitForm!: ElementRef;
  diagnosisValues:any = [];
  admissionValues:any = [];
  procedureValues:any = [];
  doctorList:any = [];
  btnLoading:boolean = false;
  isSubmitted:boolean = false;
  doctorInchargeObject:any = {};
  doctorInchargeid:any = "";
  diagnosisTemplateURL:any = environment.getDiagnosisTemplate;
  admissionTemplateURL:any = environment.getAdmissionTemplate;
  procedureListURL:any = environment.getAllPriceMaster;
  @Input() ipd_app_id:any
  Hide:boolean = false;
  constructor(private user:UsersService,private router:Router, private toast:ToastrService, private api:IpdAppointmentService,private modalService: NgbModal, private localStorage:LocalStorageService)
  {

  }
  changeStatus()
  {
    this.Hide = false
  }
  hidePopup()
  {
    this.Hide = true
  }

  ngAfterViewInit()
  {
    this.getAdmissionDetails();
    this.loadDoctors();
  }
  getAdmissionValues(data:any){this.admissionValues = data}
  getDiagnosisValues(data:any){this.diagnosisValues = data}
  getProcedureValues(data:any){this.procedureValues = data}
  loadDoctors()
  {
    let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       roles:['Doctor', "Ophthalmologist"]
    }
    this.user.getDoctors(param).subscribe({
      next:(res)=>{
         this.doctorList = res;
      },
      error:(e)=>{
        console.log(e)
      }
    })
  }
  onDoctorChange(event:any)
  {
   let id = event.target.value;
   if(id)
   {
    let selectedDoctor = this.doctorList.filter((item:any)=>item.user_id = id);
    this.doctorInchargeObject = selectedDoctor[0]
   }
  }
  getAdmissionDetails()
  {
    let param = {
      where:
      {
        id:this.ipd_app_id,
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
      }
    }
    this.api.getIPDAppointmentDetails(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.ipdAppointment = res;
          this.loading = true;
          this.admissionValues = this.ipdAppointment.reason_for_admission;
          this.diagnosisValues = this.ipdAppointment.diagnosis;
          this.doctorInchargeObject = this.ipdAppointment.doctor_incharge;
          let user:any = this.ipdAppointment.doctor_incharge;
          this.doctorInchargeid = user.user_id;
        }
      },
      error:(e)=>{

      }
    })
  }
  updateIpdAppointment(isValid:boolean)
  {
    this.isSubmitted = true;
    if(isValid)
    {
      this.btnLoading = true;
      this.ipdAppointment.reason_for_admission = this.admissionValues
      this.ipdAppointment.diagnosis = this.diagnosisValues
      this.ipdAppointment.doctor_incharge = this.doctorInchargeObject
      console.log(this.ipdAppointment)
      this.api.updateIpdAppointment(this.ipdAppointment).subscribe({
        next:(res)=>{
          if(res)
          {
            this.toast.success("Data Updated Successfully");
            this.btnLoading = false;
          }
        },
        error:(e)=>{
          console.log(e)
          this.btnLoading = false;
        }
      })

    }

  }


}
export class IpdAppointment {
  id!: Number;
  ipd_number:string = "";
  patient_id:Number = 0;
  date_of_admission:string = "";
  time_of_admission:string = "";
  reason_for_admission:[] = [];
  diagnosis:[] = [];
  room_no:string = "";
  room_type:string = "";
  doctor_incharge:[] = [];
  significant_finding:string = "";
  lama:string = "";
  mlc:string = "";

}
