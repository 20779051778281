import { getInvoiceFooterData, getInvoiceHeaderData } from "./CommanStorage";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const Prescription =  (data:any,action:any = 'print') =>  {
    console.log(data)

    let medname:any = [];
    let dosage:any = [];
    let duration:any = [];
    let qty:any = [];
    let investigations:any = [];
    let primaryCheckups:any = [];
    let advices:any = [];
    let followUp:any = ""
    let ophthalmic_examination = data?.prescription?.ophthalmic_examinations ;

    if(data?.prescription?.primary_checkups!=null)
    {
    Object.entries(data?.prescription?.primary_checkups).forEach(entry => {
      const [key, value] = entry;
      let str = key.replaceAll("_"," ");
      let name =  str.charAt(0).toUpperCase() + str.slice(1);
      if(value!="")
      {
        primaryCheckups.push({margin:[0,2,0,2],text:[
          {text:name+" : "+value,fontSize: 10,style: '',},
          
        ]})
      }
   

    });
  }

    
    if(data?.prescription?.advices!=null)
    {
      followUp =  data?.prescription?.advices?.nextVisit;
      data?.prescription?.advices.advices.forEach((element:any,index:any) => {
        let i = index + 1;
        advices.push({margin:[0,2,0,2],text:element,fontSize: 10})
      })
    }

    if(data?.prescription?.investigations!=null)
    {
      data?.prescription?.investigations.forEach((element:any,index:any) => {
        let i = index + 1;
        investigations.push({margin:[0,2,0,2],text:element.investigation_name,fontSize: 10})
      })
    }

    if(data?.prescription?.medications!=null)
    {
      data?.prescription?.medications.forEach((element:any,index:any) => {
      

              let i = index + 1;
              medname.push(
                { margin:[0,2,0,2],text:[
                  {text:i+".",fontSize: 10},
                  {text:element.drug_name.medicine_name.concat("\n"),fontSize: 10,bold: true},
                  {margin:[0,2,0,2],text:element.drug_name.composition,fontSize: 8}
                ]}
                
                );
                dosage.push(
                  { margin:[0,7,0,7],text: element.duration_count +" "+ element.duration, alignment: 'left',fontSize: 10}
                  
                  );

                  duration.push(
                    { margin:[0,7,0,7],text: element.frequency, alignment: 'left',fontSize: 10}
                    
                    );

                    qty.push(
                      { margin:[0,7,0,7],text: "("+element.quantity+")", alignment: 'left',fontSize: 10}
                      
                      );
            
          
      
      });
  }
    let docDefinition:any = {
      // watermark: { text: 'ANAND HOSPITAL & EYE CENTER', color: 'blue', opacity: 0.1, bold: true, italics: false },
      pageMargins: [40, 125, 40, 40],
      header:(localStorage.getItem('enablePrintLayout')=="true") ?  {
        columns: [
           
            // [
            //   { text: "Dr Omkar Bhave",fontSize: 12,bold:true,TextColor:"#069" },
            //   { text: "MBBS,MD,MS|Reg no:2031554",fontSize: 10  },
            //   { text: "Mob No. 8149382548",fontSize: 10  }
            // ],
            [{
              image:localStorage.getItem('getBase64HeaderPrescription') || "",
              width: "600",
              height:"120",
              alignment: 'center',
          }],

            // [
              
            //   { text: "Care Clinic",fontSize: 12,bold:true,TextColor:"#069",alignment: 'right' },
            //   { text: "Kothurud Pune - 431001",fontSize: 10 ,alignment: 'right' },
            //   { text: "Mob No. 8149382548",fontSize: 10,alignment: 'right'  },
            //   { text: "Closed:Sunday",fontSize: 10 ,alignment: 'right' }
            // ]
           
        ]
    } : {},
      content: [
   
        {
          margin: [0, 15,0, 5]  ,
          columns: [
            [
              
             
              {
                text: data?.patientInfo.pId,
                alignment: 'left',
                bold:true,
                fontSize: 10,
              },
              {
                text:  data?.patientInfo.fname+" "+data?.patientInfo.lname+" "+"("+data?.patientInfo.gender+") / "+" "+data?.patientInfo.age+"Y",
            
                fontSize: 11,
              },
              { text: data?.patientInfo.email,fontSize: 10  },
              { text: data?.patientInfo.mobile,fontSize: 10  },
              { text: data?.patientInfo.address,fontSize: 10 },
        
              // { margin: [0, 5,0, 5]  , text: "Weight(KG):70  | Height(cms):165 | BP:120/20 mmHg",fontSize: 10,  bold:true },
            ],
            [
              {
                text: "Consultant : "+data?.patientInfo?.appointment?.user?.prefix +"."+data?.patientInfo?.appointment?.user?.fname +" "+data?.patientInfo?.appointment?.user?.lname,
                alignment: 'right',
                fontSize: 10 ,
                bold:true
              },
              {
                text: `Date: ${new Date().toLocaleString()}`,
                alignment: 'right',
                fontSize: 10 ,
                bold:true
              },
            
              
            ]
          ]
        },
      ],
      footer: (localStorage.getItem('enablePrintLayout')=="true") ? {
            
        columns: [
          [{
            image:localStorage.getItem('getBase64FooterPrescription') || "",
            width: "600",
            alignment: 'center',
        }],
           
        ]
    } : {},
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 5,0, 5]          
        },
        ul: {
          fontSize: 10,
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black',
          border:[false,true,false,true]
        }
      }
    };

//Primary Examinations
if(data.print_option.includes('primary_checkups') && data?.prescription?.primary_checkups!=null)
{
  docDefinition.content.push({
    text: 'Primary Checkups',
    style: 'sectionHeader',
    fontSize: 11, 
  },
  {
    fontSize: 10, 
    ol: primaryCheckups,
},
); 
}

  //Auto Refraction

  //Auto Refraction Table 
  if(data.print_option.includes('auto_refraction') && ophthalmic_examination !=null)
  {
     docDefinition.content.push({margin: [0, 5,0, 5] ,
      
  columns:[
     [
  
      {
        text: 'Auto Refraction',
        style: 'sectionHeader',
        fontSize: 11, 
      },
      {
        table: {

      body: [
[
              {},
           {text: 'Right Eye', fontSize: 10 ,style: 'tableHeader', colSpan: 3, alignment: 'center'},
           {},
           {},
           
           {text: 'Left Eye', fontSize: 10 , style: 'tableHeader',colSpan: 3, alignment: 'center'},
           {},
           {},
          ],
          [
              '',
              {text: 'Sph', fontSize: 10 , alignment: 'center'},
              {text: 'Cyl', fontSize: 10 , alignment: 'center'},
              {text: 'Axis', fontSize: 10 , alignment: 'center'},
              {text: 'Sph', fontSize: 10 , alignment: 'center'},
              {text: 'Cyl', fontSize: 10 , alignment: 'center'},
              {text: 'Axis', fontSize: 10 , alignment: 'center'},
         
          ],
          [
            {text: 'Undilated', fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.right.undilated.spl, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.right.undilated.cyl, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.right.undilated.axis, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.left.undilated.spl, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.left.undilated.cyl, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.left.undilated.axis, fontSize: 10 , alignment: 'center'},
          ],
          [
            {text: 'Dilated', fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.auto_refraction.right.dilated.spl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.auto_refraction.right.dilated.cyl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.auto_refraction.right.dilated.axis, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.auto_refraction.left.dilated.spl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.auto_refraction.left.dilated.cyl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.auto_refraction.left.dilated.axis, fontSize: 10 , alignment: 'center'},
        ],
      
        
        ]
    }
      }
     ],
     [
      {
        text: 'Auto Refraction',
        style: 'sectionHeader',
        fontSize: 11, 
      },
    
      {
        table: {

      body: [
[
              {},
           {text: 'Right Eye', fontSize: 10 ,style: 'tableHeader', colSpan: 3, alignment: 'center'},
           {},
           {},
           
           {text: 'Left Eye', fontSize: 10 , style: 'tableHeader',colSpan: 3, alignment: 'center'},
           {},
           {},
          ],
          [
              '',
              {text: 'Sph', fontSize: 10 , alignment: 'center'},
              {text: 'Cyl', fontSize: 10 , alignment: 'center'},
              {text: 'Axis', fontSize: 10 , alignment: 'center'},
              {text: 'Sph', fontSize: 10 , alignment: 'center'},
              {text: 'Cyl', fontSize: 10 , alignment: 'center'},
              {text: 'Axis', fontSize: 10 , alignment: 'center'},
         
          ],
          
        [
          {text: 'Subjective	', fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.right.subjective.spl, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.right.subjective.cyl, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.right.subjective.axis, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.left.subjective.spl, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.left.subjective.cyl, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.auto_refraction.left.subjective.axis, fontSize: 10 , alignment: 'center'},
      ],
      [
        {text: 'Cycloplegic	', fontSize: 10 , alignment: 'center'},
          {text: ophthalmic_examination.auto_refraction.right.cycloplegic.spl, fontSize: 10 , alignment: 'center'},
          {text: ophthalmic_examination.auto_refraction.right.cycloplegic.cyl, fontSize: 10 , alignment: 'center'},
          {text: ophthalmic_examination.auto_refraction.right.cycloplegic.axis, fontSize: 10 , alignment: 'center'},
          {text: ophthalmic_examination.auto_refraction.left.cycloplegic.spl, fontSize: 10 , alignment: 'center'},
          {text: ophthalmic_examination.auto_refraction.left.cycloplegic.cyl, fontSize: 10 , alignment: 'center'},
          {text: ophthalmic_examination.auto_refraction.left.cycloplegic.axis, fontSize: 10 , alignment: 'center'},
    ],

        ]
    }
      }
     ],
    
    ]})
  }

  //Vision Acuity 

  if(data.print_option.includes('vision_acuity') && ophthalmic_examination !=null)
  {
    docDefinition.content.push(
      {margin: [0, 5,0, 5] ,
             
       columns:[
         [
           {
             text: 'Vision Acuity',
             style: 'sectionHeader',
             fontSize: 11, 
           },
           {
      
     
             table: {
         
               body: [
                
                  [
                          
                   {text: '', fontSize: 10 , alignment: 'center',bold:true},
                   {text: 'RE', fontSize: 10 , alignment: 'center',bold:true},
                   {text: 'LE', fontSize: 10 , alignment: 'center',bold:true},
                   {text: 'RE', fontSize: 10 , alignment: 'center',bold:true},
                   {text: 'LE', fontSize: 10 , alignment: 'center',bold:true},
              
               ],
               [
             
                   {text: 'Unaided', fontSize: 10 , alignment: 'center'},
                   {text: ophthalmic_examination.vision_acuity.right.dist.unaided, fontSize: 10 , alignment: 'center'},
                   {text: ophthalmic_examination.vision_acuity.left.dist.unaided, fontSize: 10 , alignment: 'center'},
                   {text: ophthalmic_examination.vision_acuity.right.near.unaided, fontSize: 10 , alignment: 'center'},
                   {text: ophthalmic_examination.vision_acuity.left.near.unaided, fontSize: 10 , alignment: 'center'},
                 
              
               ],
               [
             
                 {text: 'With Glass', fontSize: 10 , alignment: 'center'},
                 {text: ophthalmic_examination.vision_acuity.right.dist.with_glass, fontSize: 10 , alignment: 'center'},
                 {text: ophthalmic_examination.vision_acuity.left.dist.with_glass, fontSize: 10 , alignment: 'center'},
                 {text: ophthalmic_examination.vision_acuity.right.near.with_glass, fontSize: 10 , alignment: 'center'},
                 {text: ophthalmic_examination.vision_acuity.left.near.with_glass, fontSize: 10 , alignment: 'center'},
               
            
             ],
     
             [
             
               {text: 'With Pinhole', fontSize: 10 , alignment: 'center'},
               {text: ophthalmic_examination.vision_acuity.right.dist.with_pinhole, fontSize: 10 , alignment: 'center'},
               {text: ophthalmic_examination.vision_acuity.left.dist.with_pinhole, fontSize: 10 , alignment: 'center'},
               {text: ophthalmic_examination.vision_acuity.right.near.with_pinhole, fontSize: 10 , alignment: 'center'},
               {text: ophthalmic_examination.vision_acuity.left.near.with_pinhole, fontSize: 10 , alignment: 'center'},
             
          
           ],
           [
             
            {text: 'PGP', fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.vision_acuity.right.dist.pgp, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.vision_acuity.left.dist.pgp, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.vision_acuity.right.near.pgp, fontSize: 10 , alignment: 'center'},
            {text: ophthalmic_examination.vision_acuity.left.near.pgp, fontSize: 10 , alignment: 'center'},
          
       
        ],
                          
             
                         ]
                     }
         }
           
          ]
        
       ]
     },)
  }

    //Refraction Table 
    if(data.print_option.includes('refraction') && ophthalmic_examination !=null)
    {
       docDefinition.content.push({margin: [0, 5,0, 5] ,
        
    columns:[
       [
    
        {
          text: 'Refraction (Glass RX)',
          style: 'sectionHeader',
          fontSize: 11, 
        },
        {
          table: {
  
        body: [
  [
                {},
             {text: 'Right Eye', fontSize: 10 ,style: 'tableHeader', colSpan: 4, alignment: 'center'},
             {},
             {},
             {},
             
             {text: 'Left Eye', fontSize: 10 , style: 'tableHeader',colSpan: 4, alignment: 'center'},
             {},
             {},
             {},
            ],
            [
                '',
                {text: 'Sph', fontSize: 10 , alignment: 'center'},
                {text: 'Cyl', fontSize: 10 , alignment: 'center'},
                {text: 'Axis', fontSize: 10 , alignment: 'center'},
                {text: 'Add', fontSize: 10 , alignment: 'center'},
                {text: 'Sph', fontSize: 10 , alignment: 'center'},
                {text: 'Cyl', fontSize: 10 , alignment: 'center'},
                {text: 'Axis', fontSize: 10 , alignment: 'center'},
                {text: 'Add', fontSize: 10 , alignment: 'center'},
           
            ],
            [
              {text: 'Dist', fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.right.dist.spl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.right.dist.cyl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.right.dist.axis, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.right.dist.add, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.left.dist.spl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.left.dist.cyl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.left.dist.axis, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.left.dist.add, fontSize: 10 , alignment: 'center'},
            ],
            [
              {text: 'Near', fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.right.near.spl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.right.near.cyl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.right.near.axis, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.right.near.add, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.left.near.spl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.left.near.cyl, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.left.near.axis, fontSize: 10 , alignment: 'center'},
              {text: ophthalmic_examination.refraction.left.near.add, fontSize: 10 , alignment: 'center'},
          ],
        
          
          ]
      }
        }
       ],
      
      
      ]})
    }
  
 
    //Anterior Segment
  if(data.print_option.includes('anterior_segment') && ophthalmic_examination !=null)
  {
    docDefinition.content.push( {margin: [0, 10,0, 5] ,
      
      columns:[
        [
          {
            text: 'Anterior Segment',
            style: 'sectionHeader',
            fontSize: 11, 
          },
          {
     
    
            table: {
        
              body: [
              
                          [
                            {text: '', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                            {text: 'Lids & Adnexa', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                            {text: 'Conjucative', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                            {text: 'Anterior Chamber', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                            {text: 'Cornea', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                            {text: 'Iris', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                            {text: 'Pupil', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                            {text: 'Lens', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
    
                   
                          ],
                          [
                            {text: "RE" , fontSize: 10 , alignment: 'center'},
                              {text: ophthalmic_examination.anterior_segment.right.lids_and_adnexa , fontSize: 10 , alignment: 'center'},
                              {text: ophthalmic_examination.anterior_segment.right.conjucative , fontSize: 10 , alignment: 'center'},
                              {text: ophthalmic_examination.anterior_segment.right.anterior_chamber , fontSize: 10 , alignment: 'center'},
                              {text: ophthalmic_examination.anterior_segment.right.cornea , fontSize: 10 , alignment: 'center'},
                              {text: ophthalmic_examination.anterior_segment.right.iris , fontSize: 10 , alignment: 'center'},
                              {text: ophthalmic_examination.anterior_segment.right.pupil , fontSize: 10 , alignment: 'center'},
                              {text: ophthalmic_examination.anterior_segment.right.lens , fontSize: 10 , alignment: 'center'},

    
                          ],
                          [
                            {text: "LE" , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.anterior_segment.left.lids_and_adnexa , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.anterior_segment.left.conjucative , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.anterior_segment.left.anterior_chamber , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.anterior_segment.left.cornea , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.anterior_segment.left.iris , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.anterior_segment.left.pupil , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.anterior_segment.left.lens , fontSize: 10 , alignment: 'center'},

               
                          ],
                         
            
                        ]
                    }
        }
          
         ]
       
      ]
    })
  }

  //Fundus Examinations
  
  if(data.print_option.includes('fundus_examination') && ophthalmic_examination !=null)
  {
    docDefinition.content.push({margin: [0, 10,0, 5],
           
     columns:[
       [
         {
           text: 'Fundus Examinations',
           style: 'sectionHeader',
           fontSize: 11, 
         },
         {
    
   
           table: {
       
             body: [
             
                         [
                           {text: '', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                           {text: 'Cup to Disc', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                           {text: 'Vitreous', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                           {text: 'Optic Nerve', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                           {text: 'Macula', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                           {text: 'Vassals', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                           {text: 'Periphery', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
   
                  
                         ],
                         [
                          {text: "RE" , fontSize: 10 , alignment: 'center'},
                        
                            {text: ophthalmic_examination.fundus_examination.right.cup_to_disc , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.fundus_examination.right.vitreous , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.fundus_examination.right.optic_nerve , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.fundus_examination.right.macula , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.fundus_examination.right.vassals , fontSize: 10 , alignment: 'center'},
                            {text: ophthalmic_examination.fundus_examination.right.periphery , fontSize: 10 , alignment: 'center'},
             
                        ],
                         [
                           {text: "LE" , fontSize: 10 , alignment: 'center'},
                             
                             {text: ophthalmic_examination.fundus_examination.left.cup_to_disc , fontSize: 10 , alignment: 'center'},
                             {text: ophthalmic_examination.fundus_examination.left.vitreous , fontSize: 10 , alignment: 'center'},
                             {text: ophthalmic_examination.fundus_examination.left.optic_nerve , fontSize: 10 , alignment: 'center'},
                             {text: ophthalmic_examination.fundus_examination.left.macula , fontSize: 10 , alignment: 'center'},
                             {text: ophthalmic_examination.fundus_examination.left.vassals , fontSize: 10 , alignment: 'center'},
                             {text: ophthalmic_examination.fundus_examination.left.periphery , fontSize: 10 , alignment: 'center'},
   
                         ],
                        
                        
           
                       ]
                   }
       }
         
        ]
      
     ]})
  }
 

  
    //IOP
    if(data.print_option.includes('iop') && ophthalmic_examination !=null)
    {
      docDefinition.content.push( {margin: [0, 10,0, 5] ,
        
        columns:[
          [
            {
              text: 'IOP',
              style: 'sectionHeader',
              fontSize: 11, 
            },
            {
       
      
              table: {
          
                body: [
                
                            [
                              {text: '', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                              {text: 'Method of IOP	', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                              {text: 'IOP Time	', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                              {text: 'IOP', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                              {text: 'MM OF HG', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                              {text: 'CCT', fontSize: 10 ,style: 'tableHeader', alignment: 'center'},
                           
      
                     
                            ],
                            [
                              {text: "RE" , fontSize: 10 , alignment: 'center'},
                                {text: ophthalmic_examination.iop.right.method , fontSize: 10 , alignment: 'center'},
                                {text: ophthalmic_examination.iop.right.iop_time , fontSize: 10 , alignment: 'center'},
                                {text: ophthalmic_examination.iop.right.iop , fontSize: 10 , alignment: 'center'},
                                {text: ophthalmic_examination.iop.right.mm_of_hg , fontSize: 10 , alignment: 'center'},
                                {text: ophthalmic_examination.iop.right.cct , fontSize: 10 , alignment: 'center'},
                             
  
      
                            ],
                            [
                              {text: "LE" , fontSize: 10 , alignment: 'center'},
                              {text: ophthalmic_examination.iop.left.method , fontSize: 10 , alignment: 'center'},
                                {text: ophthalmic_examination.iop.left.iop_time , fontSize: 10 , alignment: 'center'},
                                {text: ophthalmic_examination.iop.left.iop , fontSize: 10 , alignment: 'center'},
                                {text: ophthalmic_examination.iop.left.mm_of_hg , fontSize: 10 , alignment: 'center'},
                                {text: ophthalmic_examination.iop.left.cct , fontSize: 10 , alignment: 'center'},
  
                 
                            ],
                           
              
                          ]
                      }
          }
            
           ]
         
        ]
      })
    }
 
 
 if(data.print_option.includes('investigations') && data?.prescription?.investigations!=null)
 {
  docDefinition.content.push(  
  {
    fontSize: 11, 
    margin: [0, 5,0, 5] ,
    text: 'Investigations',
    style: 'sectionHeader'
  },
 
  {
    margin: [0, 0,0, 10] ,
    fontSize: 10,
    ol: investigations,
  },
  )
}

if(data?.prescription?.medications!=null)
{
 docDefinition.content.push(  
  {
    fontSize: 11, 
    margin: [0, 10,0, 5] ,
    text: 'Rx',
    style: 'sectionHeader'
  },
  {
    layout: 'noBorders',
    margin: [0, 2,0, 2] ,
    fontSize: 10, 
    table: {
      headerRows: 1,
      widths: ['*', 50,50,50],
      heights: function (row:any) {
        return (row + 1) * 10;
      },
      body: [
        [
            medname,
            duration,
            dosage,
            qty
         
        ],
       
        
      ]
    }
  },
 )
}

if(data.print_option.includes('advices') && data?.prescription?.advices!=null)
{
 docDefinition.content.push(  
  {
    fontSize: 11, 
    margin: [0, 5,0, 5] ,
    text: 'Advice Given',
    style: 'sectionHeader'
  },
 
  {
    margin: [0, 0,0, 10] ,
    fontSize: 10,
    ul: advices,
  },
  {
    fontSize: 11, 
    margin: [0, 5,0, 5] ,
    text: 'Next Visit: '+followUp,
    style: 'sectionHeader'
  }
 )
}

  

  
 
//  {
//   alignment: 'right',
//   columns:[
//     [
//       { text:data.doctor,bold:true, fontSize: 10,},
//       { text:'MBBS,MD,MS ',bold:true  ,fontSize: 10,}
//     ]
//   ],
// }

if(localStorage.getItem('enablePrintLayout') == "false")
{
  delete  docDefinition.content.header;
  delete  docDefinition.content.footer;
}


    if(action==='download'){
      pdfMake.createPdf(docDefinition).download();
    }else if(action === 'print'){
      pdfMake.createPdf(docDefinition).print();      
    }else{
      pdfMake.createPdf(docDefinition).open();      
    }
  
  }
  
  export default Prescription;