import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { JWTTokenServiceService } from 'src/app/public/services/token.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent {

  constructor(private jwt:JWTTokenServiceService,private router:Router,private localStorage:LocalStorageService)
  {
  //   if (jwt.getToken()=="") {
  //     router.navigate(['login']);
  //  }
  }

}
