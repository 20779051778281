import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { merge, startWith, switchMap, catchError, map } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { FeedbackService } from '../../services/feedback.service';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  dateQuery = new FormControl();
  formAction = "add";
	closeResult = '';
  isSubmitted: boolean = false;
  displayedColumns: string[] = ["name","email","mobile","date","rating","feedback"];
  exportColumns:any = ["name","email","mobile","date","rating","feedback"];
  exportArrayData:any = []

  constructor(private router:Router, private toast:ToastrService, private api:FeedbackService,private modalService: NgbModal, private localStorage:LocalStorageService){
  }
  ngAfterViewInit() {
    this.loadData();
  }
  dateFilter(event:any)
  {
   this.dateQuery.setValue(event)
  }
  
    // load Initial table data from based on source type
    loadData()
    {
      try{
     this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
     merge(this.dateQuery.valueChanges,this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
     switchMap(()=>{
      let param = {
        where:{hospital_id:this.localStorage.getHospitalId(),center_id:this.localStorage.getCenterId()},
        filter:this.searchQuery.value,
        page:(this.paginator.pageIndex *  this.paginator.pageSize),
        status:'check_in',
        limit:this.paginator.pageSize,
        order:[{col:'date_added',dir:"desc"}],
      }
      if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
      if(this.localStorage.checkFullAccessForTableList(this.localStorage.getRole())){
        param = param
      }else{
        param = {...param,...{user_id:this.localStorage.getUserId()}}
      }
      return  this.api.getAllFeedbacksWithCount(param)
      .pipe(catchError(() => observableOf(null)));
     }),map((response:any)=>{
      if (response === null) {
        return [];
      }
   
      this.totalRows = response.count;
      return response
     })
     ).subscribe({
      next:(data) =>{
     // console.log(data)
      this.exportArrayData = data.rows;
      this.dataSource = new MatTableDataSource<any>(data.rows);;
      },
      error:(e)=>{
       console.error(e)
      },
      complete:()=>{
       
      }
     })
    }
    catch(e){
     console.error(e)
    }
    }
  searchFilter(query:any){

   // alert(query)
    this.searchQuery.setValue(query);
  }
  export(header:any)
  {
    //console.log(header)
    let excelRowData:any = [];
    this.exportArrayData.forEach((element:any) => {
  
     excelRowData.push({0:element.name,1:element.email,2:element.mobile,3:element.rating,4:element.feedback,5:element.date_added})
   });

    let excelData = [];
    let excelHeader = [];
      for (let i = 0; i < excelRowData.length; i++) {
        let element = excelRowData[i];
        let obj:any = {}
        for (let index = 0; index < header.length; index++) {
          let key = header[index]['key'];
          obj[key] = element[key]
        }
        excelData.push(obj)
      }
      for (let index = 0; index < header.length; index++) {
        let value = header[index]['value'];
        excelHeader.push(value)
      }
    //console.log(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
    XLSX.utils.sheet_add_json(ws,excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();

  XLSX.writeFile(wb, "Out_Patient_"+day+"_"+month+"_"+year+"_"+date.getTime()+".csv");
  }
}
function observableOf(arg0: null): any {
  throw new Error('Function not implemented.');
}

