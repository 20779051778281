import { filter } from 'rxjs';
import { Component, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from '../../services/appointment.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import { merge, startWith, switchMap, catchError, map } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import moment from 'moment';
@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent {
  isLoading = false;
  btnLoading = false;
  topBarTitle:string = "Appointments";
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  appointment:Appointment =  new Appointment();
  @ViewChild(MatSort)
  sort!: MatSort;
  searchQuery = new FormControl();
  isSubmittedresCheduled:boolean = false;
  dateQuery = new FormControl();
  formAction = "add";
	closeResult = '';
  DateList:any = [];
  TimeList:any = [];
  current_id_hover:any;
  paymentDetail:any = {
    payment_status:"",
    remarks:"",
    service:"",
    pincode:"",
  }
  @ViewChild("reappointmentForm")
  reappointmentForm!: NgForm;
  isSubmitted: boolean = false;
  exportArrayData:any = [];
  isOnlinePayment:boolean = false;
  displayedColumns: string[] = ["pId","info","opd","name","doctor_name","date","time","check_in_type","type","source","payment","status","action"];
  exportColumns:any = [];
  topPosition: any;
  leftPosition: any;
  hoveredRow: any;
  departmentListDp:any = []
  DoctorDpList:any = []
  selectedItem:any;
  filtersObject:any = {};
  timeZone:any = Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Calcutta";
  constructor(private router:Router, private toast:ToastrService, private api:AppointmentService,private modalService: NgbModal, private localStorage:LocalStorageService){
    // removing doctor for hospital wise
    if(this.localStorage.getHospitalWise() == "true") {
      this.displayedColumns = ["pId","info","opd","name","mobile","date","time","check_in_type","type","source","payment","status","action"];
    }
  }
  ngAfterViewInit() {
    console.log("tabl column : ",this.displayedColumns);
    this.loadData();
    this.loadDepartments()
    
  }
  formatTo12Hour(time: string): string {
    if(this.localStorage.isTwelveHourFormat()) {
      return moment(time, 'HH:mm').format('hh:mm A');
    }
    else {
      return time;
    }
    
  }
  loadDepartments()
  {
    let param = {
      where:{
        hospital_id:this.localStorage.getHospitalId(),
        // center_id:this.localStorage.getCenterId(),
        isPublic:true,
      }
      
    }

   this.api.getDepartments(param).subscribe({
    next:(response)=>{
     // console.log(response)
     if(response && response.length > 0){
      this.departmentListDp = response
  this.loadDoctorsByDepartments(this.departmentListDp[0].dept_id)
     }
    
    },
    error:(e)=>{

    }
   })
  }
  loadDoctorsByDepartments(dept_id:any)
  {
  
    let param = {
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        dept_id:dept_id,
    }
    if(this.localStorage.getIgnoreCenterCheck()=="true"){
      delete param.center_id;
    }
    this.api.getUserByDepartmentSchedule(param).subscribe({
      next:(response)=>{
       console.log(response)
        this.DoctorDpList = response
      
      },
      error:(e)=>{

      }
     })
  }
  showInfo(row:any,event:any)
  {
    this.hoveredRow = row;
    this.topPosition =  event.clientY;
    this.leftPosition = event.clientX;
    this.current_id_hover = row.appointment.app_id
  }
  onMouseMove(row:any,event: MouseEvent): void {
    this.current_id_hover =  row.appointment.app_id;
  }
  openDialog(element:any)
  {

  }
  hideInfo()
  {
    this.current_id_hover = "";
  }
  checkInOPD(item:any)
  {
    console.log("items : ",item);
    if(item.pId=='N/A' || item.appointment.opd_number=='N/A' || item.appointment.opd_number==null  )
    {
     
      let param = {
        hospital_id:this.localStorage.getHospitalId(),
        center_id:this.localStorage.getCenterId(),
        patient_id:item.id,
        app_id:item.appointment.app_id,
        pId:item.pId,
        opd_number:item.appointment.opd_number,
        payment_amount: item.appointment.payment_amount,
        payment_status: item.appointment.payment_status,
        details: item.appointment.details,
        status:"check_in",
      }
     this.btnLoading = true;
     console.log("param : ",param)
      this.api.checkInOpdPatientData(param).subscribe({
        next:(res)=>{
          if(res)
          {
          console.log("ress : ",res);
            this.toast.success('Patient Checked IN OPD')
            setTimeout(() => {
              this.btnLoading = false;
              this.modalService.dismissAll();
              this.router.navigate(['main/appointments/record/'+item.id+'/'+item.appointment.app_id])
            }, 500);
          
          }
        },
        error:(e)=>{
          this.btnLoading = false;
        }
      })
    }
    else
    {
     // alert('else')
     this.router.navigate(['main/appointments/record/'+item.id+'/'+item.appointment.app_id])
    }
   
  }
  customSearchFilter(event:any){
    this.filtersObject = event;
    this.loadData();
  }
  dateFilter(event:any)
  {
   this.dateQuery.setValue(event)
  }
  
    // load Initial table data from based on source type
    loadData()
    {
      try{
     this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
     merge(this.dateQuery.valueChanges,this.searchQuery.valueChanges, this.sort.sortChange, this.paginator.page).pipe(startWith({}),
     switchMap(()=>{
      let param = {
        where:{hospital_id:this.localStorage.getHospitalId(),center_id:this.localStorage.getCenterId()},
        filter:this.searchQuery.value,
        custom_filter:this.filtersObject,
        page:(this.paginator.pageIndex *  this.paginator.pageSize),
        limit:this.paginator.pageSize,
        order:[{col:'app_id',dir:"desc"},{col:'appointment_date',dir:"desc"},{col:'appointment_time',dir:"desc"}],
        
      }
      if(this.dateQuery.value){param = {...param,...this.dateQuery.value}}
      if(this.localStorage.checkFullAccessForTableList(this.localStorage.getRole())){
        param = param
      }else{
        param = {...param,...{user_id:this.localStorage.getUserId()}}
      }
     
      //console.log(this.localStorage.getRole())
      return  this.api.getAllAppointmentWithCount(param)
      .pipe(catchError(() => observableOf(null)));
     }),map((response:any)=>{
      if (response === null) {
        return [];
      }
   
      this.totalRows = response.count;
      return response
     })
     ).subscribe({
      next:(data) =>{
     // console.log(data)
    
      this.exportArrayData = data.rows;
      this.dataSource = new MatTableDataSource<any>(data.rows);
      if(!this.localStorage.checkFeatures('opd')){
        this.displayedColumns = this.displayedColumns.filter(item=> item!='opd')
      }
      if(!this.localStorage.checkFeatures('online_payment')){
        this.displayedColumns = this.displayedColumns.filter(item=> item!='payment')
        this.exportColumns = ["pId","OPD No","Name","Mobile","Email","Doctor","Date","Time","Check In","Visit","Source","Status"];
      }
      else
      {
        this.isOnlinePayment = true;
        if(this.isOnlinePayment)
        {
          this.exportColumns = ["pId","OPD No","Name","Mobile","Email","Doctor","Date","Time","Check In","Visit","Source","Payment","Amount","Status"];
        } else {
          this.exportColumns = ["pId","OPD No","Name","Mobile","Email","Doctor","Date","Time","Check In","Visit","Source","Status"];
        }
      }
      // removing doctor for hospital wise
      if(this.localStorage.getHospitalWise() == "true") {
        this.exportColumns = this.exportColumns.filter((item:any) => item!='Doctor');
      }
      },
      error:(e)=>{
       console.error(e)
      },
      complete:()=>{
       
      }
     })
    }
    catch(e){
     console.error(e)
    }
    }
  searchFilter(query:any){

   // alert(query)
    this.searchQuery.setValue(query);
  }
  open(content:any){
  
    this.modalService.open(content, { size:'lg', scrollable: false}).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);

  }
   getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
  gotoPatient(item:any)
  {
    this.router.navigate(['main/appointments/record/'+item.id+'/'+item.appointment.app_id])
  }

  
  sourceImage = [{
    name:"webapp",
    img:"create.png"
  },
  {
    name:"tina",
    img:"tina-social.png"
  },
  {
    name:"whatsapp",
    img:"logos_whatsapp-icon.png"
  },
  {
    name:"twitter",
    img:"logos_twitter.png"
  },
  {
    name:"facebook",
    img:"logos_facebook.png"
  },
  {
    name:"instagram",
    img:"instagram-icon.png"
  },
  {
    name:"youtube",
    img:"logos_youtube-icon.png"
  },
  {
    name:"messenger",
    img:"logos_messenger.png"
  }]
  

  getsourceImage(source:any)
  {
    let img:any ="create.png";
  
    let tempImage:any =  this.sourceImage.find((item:any) => {
     return item.name === source;
    })
   //console.log(tempImage)
    if(tempImage)
    {
       img = tempImage.img
    }else
    {
      img = "create.png"
    }
    return img;

  }

  async openReschedule(content:any,item:any)
  {

   if(item.appointment.appointment_type!="walk_in" || item.appointment.appointment_type!="emergency"  )
   {
    this.appointment = item.appointment;
    const popup = this.modalService.open(content,  { windowClass:'modal modal-huge', scrollable: false,ariaLabelledBy: 'modal-basic-title', centered: true }).result;  
      await this.loadDates(item)
      if(this.DateList.length > 0)
      {
      
        
        this.loadTimes(item)
        let default_time = item?.appointment?.appointment_time;
        this.appointment.appointment_time = "";
        this.appointment.appointment_date = "";
        this.appointment.appointment_date =  item.appointment.appointment_date
        popup.then((result) => {  
          if (result === 'yes') {  
            //console.log(default_time)
            this.appointment.appointment_time = default_time;
          }  

        }, (reason) => { });  
      }
      else{
        this.toast.error("No dates for reschedule")
      }

   }
   else
   {
   
    this.toast.error("Reschedule is not available ")
   }


  
  }

  async loadDates(item:any)
  {
    this.DateList = []
    let param = {
      where: {
        hospital_id:this.localStorage.getHospitalId(),
        user_id:this.appointment.user_id,
        center_id:this.localStorage.getCenterId(),
        dept_id:item.appointment.user.dept_id,
      },
      appointment_type:item.appointment.appointment_type,
      timezone: this.localStorage.getTimeZone(),
      dateFormat: this.localStorage.getDateFormat()
  }
  if(this.localStorage.getIgnoreCenterCheck()=="true"){
    delete param.where.center_id;
  }
  const p = () => new Promise(resolve=>{
    this.api.getAvailableDays(param).subscribe({
      next:(response)=>{
         //console.log(response)
         if(response.length > 0 )
         {
          this.DateList = response;
         
          resolve(this.DateList);
    
         }
         else
         {
          this.toast.error("No Dates Available")
         }
    
       
      },
      error:(e)=>{

      }
    })
  })

  await p();
     
  }

  loadTimes(item:any)
  {
    this.TimeList = []
    let param = {
      where: {
        hospital_id:this.localStorage.getHospitalId(),
        user_id:item.appointment.user_id,
        center_id:this.localStorage.getCenterId(),
        dept_id:item.appointment.user.dept_id,
      },
      appointment_type:item.appointment.appointment_type,
      appointment_date:item.appointment.appointment_date,
      timezone:  this.localStorage.getTimeZone(),
      dateFormat: this.localStorage.getDateFormat()
  }
  //console.log(param)
  if(this.localStorage.getIgnoreCenterCheck()=="true"){
    delete param.where.center_id;
  }
      this.api.getSlots(param).subscribe({
        next:(response)=>{
           console.log(response)

           if(response.length > 0 )
           {
            this.TimeList = response;
           }
           else
           {
            this.toast.error("No Time Slots Available")
           }
      
        },
        error:(e)=>{

        }
      })
  }
  updateReschedule(isValid:any)
  {
    this.isSubmittedresCheduled = true;
    let param = {
      patient_id:this.appointment.patient_id,
      app_id:this.appointment.app_id,
      hospital_id:this.localStorage.getHospitalId(),
      appointment_date:this.appointment.appointment_date,
      appointment_time:this.appointment.appointment_time,
      status:"rescheduled"
    } 
    //console.log(param)
    if(this.appointment.appointment_date!="" && this.appointment.appointment_time !="")
    {
      this.btnLoading = true;
      this.api.updateAppointment(param).subscribe({
        next:(res)=>{
          this.modalService.dismissAll();
          this.toast.success("Appointment Rescheduled Successfully")
          this.loadData()
          this.btnLoading = false;
        },
        error:(e)=>{
          this.btnLoading = false;
        }
      })
     // console.log(this.appointment)
    }
    else
    {
      this.toast.error("Invalid Data")
    }
  
  }
 
  loadSlots(event:any)
  {

    const date = event.target.value;
    if(date!="")
    {
      //console.log(this.appointment)
      let param = {
        where: {
          hospital_id:this.localStorage.getHospitalId(),
          user_id:this.appointment.user_id,
          center_id:this.localStorage.getCenterId(),
          dept_id:this.appointment.user.dept_id,
        },
        appointment_type:this.appointment.appointment_type,
        appointment_date:date,
        timezone:  this.localStorage.getTimeZone(),
        dateFormat: this.localStorage.getDateFormat()
    }
    if(this.localStorage.getIgnoreCenterCheck()=="true"){
      delete param.where.center_id;
    }
    //console.log(param)
        this.api.getSlots(param).subscribe({
          next:(response)=>{
             //console.log(response)
             if(response.length > 0 )
             {
              this.TimeList = response;
             }
             else
             {
              this.toast.error("No Time Slots Available")
             }
          },
          error:(e)=>{
  
          }
        })
    }
    
    
  }

  cancelAppointmentfn(content:any,item:any)
  {
    this.selectedItem = item;
    this.modalService.open(content,  { windowClass:'modal modal-huge', scrollable: false,ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {  
      if (result === 'yes') {  
      
      }  
    }, (reason) => {  

    });  
  }
  fnCancelAppointment()
  {
    let param = {
      patient_id:this.selectedItem .appointment.patient_id,
      app_id:this.selectedItem .appointment.app_id,
      hospital_id:this.localStorage.getHospitalId(),
      status:"cancelled",
    }
    this.btnLoading = true;
    this.api.updateAppointment(param).subscribe({
      next:(res)=>{
        this.modalService.dismissAll();
        this.btnLoading = false;
        this.toast.success("Appointment Cancelled Successfully")
        this.loadData()
      },
      
      error:(e)=>{
   this.btnLoading = false;
      }
    })
  }

  checkOut(content:any,item:any)
  {
    this.modalService.open(content,  { windowClass:'modal modal-huge', scrollable: false,ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {  
      if (result === 'yes') {  
        let param = {
          patient_id:item.appointment.patient_id,
          app_id:item.appointment.app_id,
          hospital_id:this.localStorage.getHospitalId(),
          status:'check_out',
        }
        this.api.updateAppointment(param).subscribe({
          next:(res)=>{
            this.modalService.dismissAll();
            this.loadData()
            this.toast.success("Appointment Updated Successfully")
          },
          error:(e)=>{
    
          }
        })
      }  
    }, (reason) => {  

    });  
  }
   toNormalCapitalization(text:any) {
    return text.toLowerCase().replace(/(?:^|\s)\S/g, function(a:any) { return a.toUpperCase(); });
}
  completeAppointmentfn(content:any,item:any)
  {
  
    this.selectedItem = item;
    if(this.isOnlinePayment){
      if(this.selectedItem.appointment.details!=null)
      {
        this.paymentDetail.payment_status = this.selectedItem?.appointment?.payment_status  || this.paymentDetail.payment_status 
        this.paymentDetail.remarks = this.selectedItem?.appointment?.details?.remarks  || this.paymentDetail.remarks
        this.paymentDetail.service = this.selectedItem?.appointment?.details?.service  || this.paymentDetail.service
        this.paymentDetail.pincode = this.selectedItem?.appointment?.details?.pincode  || this.paymentDetail.pincode
      }
    }
    this.modalService.open(content,  { windowClass:'modal modal-huge', scrollable: false,ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {},(reason) => {});  

    // //console.log(this.selectedItem)
    // if(this.isOnlinePayment){
    //   if(this.selectedItem?.appointment?.details.payment_status=="succeeded"){
    //     console.log(this.selectedItem)
    //    // this.checkInOPD(this.selectedItem);
    //   }
    //   else{
    //     this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {},(reason) => {});  
    //   }
    // }
    // else
    // {
    //    this.modalService.open(content,  { size:'sm', scrollable: false,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {},(reason) => {});  
    // }
  }

  fnClickCheckin()
{

    if(this.isOnlinePayment)
    {
     // this.paymentDetail = this.selectedItem?.appointment?.details.payment_status!="" || this.paymentDetail
      if(this.paymentDetail.payment_status=="succeeded" || this.paymentDetail.payment_status=="pending" || this.paymentDetail.payment_status=="failed"){
        //this.checkInOPD(this.selectedItem);
       // console.log(this.paymentDetail.remarks)
        this.selectedItem.appointment.details = {
          remarks: this.paymentDetail.remarks,
          service: this.paymentDetail.service,
          pincode: this.paymentDetail.pincode,
        };
        this.selectedItem.appointment.payment_status = this.paymentDetail.payment_status;
      //  console.log(this.selectedItem)
        this.checkInOPD(this.selectedItem);
      }
      else{
        this.toast.error("Payment not done for this appointment")
      }
    }
    else
    {
      this.checkInOPD(this.selectedItem);
    }
  }
  onFileChange(args: any)
  {
    const target: DataTransfer = <DataTransfer>(args.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      //console.log(data); // Data will be logged in array format containing objects
    };
  }
  export(header:any)
  {
    //console.log(header)
    let excelRowData:any = [];
    this.exportArrayData.forEach((element:any) => {
     let status = "";
     if( element.appointment.status=="booked")
     {
       status = element.appointment.status.replaceAll('booked','Booked')
     }
     else if( element.appointment.status=="check_in" )
     {
       status = element.appointment.status.replaceAll('check_in','Checked In')
     }
     else if( element.appointment.status=="rescheduled" )
     {
       status = element.appointment.status.replaceAll('rescheduled','Rescheduled')
     }
     else if( element.appointment.status=="cancelled")
     {
       status = element.appointment.status.replaceAll('cancelled','Cancelled')
     }
     else if( element.appointment.status=="no_show")
     {
       status = element.appointment.status.replaceAll('no_show','No Show')
     }
     
     if(this.isOnlinePayment)
     {
     excelRowData.push({0:element.pId,1:element.appointment.opd_number,2:element.fname+" "+element.lname,3:element.mobile,4:element.email,5:element.appointment.user.fname+" "+element.appointment.user.lname,6:element.appointment.appointment_date,7:element.appointment.appointment_time,8:element.appointment.check_in_type,9:element.appointment.appointment_type,10:element.appointment.appointment_source,11:element.appointment?.details?.payment_status || "",12:element.appointment?.details?.amount || "",13:status})
     } else
     {
      excelRowData.push({0:element.pId,1:element.appointment.opd_number,2:element.fname+" "+element.lname,3:element.mobile,4:element.email,5:element.appointment.user.fname+" "+element.appointment.user.lname,6:element.appointment.appointment_date,7:element.appointment.appointment_time,8:element.appointment.check_in_type,9:element.appointment.appointment_type,10:element.appointment.appointment_source,11:status})
     }

   });

    let excelData = [];
    let excelHeader = [];
      for (let i = 0; i < excelRowData.length; i++) {
        let element = excelRowData[i];
        let obj:any = {}
        for (let index = 0; index < header.length; index++) {
          let key = header[index]['key'];
          obj[key] = element[key]
        }
        excelData.push(obj)
      }
      for (let index = 0; index < header.length; index++) {
        let value = header[index]['value'];
        excelHeader.push(value)
      }
    //console.log(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [excelHeader]);
    XLSX.utils.sheet_add_json(ws,excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();

  XLSX.writeFile(wb, "Appointments_data_"+day+"_"+month+"_"+year+"_"+date.getTime()+".csv");
  }

  noShowAppointmentfn(content:any,item:any)
  {
    this.modalService.open(content,  { windowClass:'modal modal-huge', scrollable: false,ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {  
      if (result === 'yes') {  
        let param = {
          patient_id:item.appointment.patient_id,
          app_id:item.appointment.app_id,
          hospital_id:this.localStorage.getHospitalId(),
          status:"no-show",
        }
        this.api.updateAppointment(param).subscribe({
          next:(res)=>{
            this.modalService.dismissAll();
            this.toast.success("Appointment Updated Successfully")
            this.loadData()
          },
          
          error:(e)=>{
    
          }
        })
      }  
    }, (reason) => {  

    });  
  }
}
function observableOf(arg0: null): any {
  //throw new Error('Function not implemented.');
}

export class Appointment {
  app_id!: Number;
  user!:any;
  appointment_source:string = "webapp";
  appointment_type:string = "";
  appointment_date:string = "";
  appointment_time:string = "";
  status:string = "booked";
  user_id:string = "";
  patient_id:Number = 0;
  dept_id:string = "";
  client_id:string = ""
  check_in_type:string = "follow-up";
  opd_number:string = ""
  hospital_id:Number = 0
  center_id:Number = 0
}