import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TemplateService } from 'src/app/private/services/template.service';
import { LocalStorageService } from 'src/app/utils/LocalStorageService';
import db from '../../../../../db/medicine_db.json';
import { Subject, debounceTime } from 'rxjs';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { IpdchartService } from 'src/app/private/services/ipdchart.service';

@Component({
  selector: 'app-discharge-medication',
  templateUrl: './discharge-medication.component.html',
  styleUrls: ['./discharge-medication.component.scss']
})
export class DischargeMedicationComponent {
  @Input() titleOfHead: any = {
    title: 'IPD',
    root: 'Discharge Medication'
  };
  @Input() patient:any;
  @Input() app_data:any
  id:any;
  medicineDb:any = [];
  btnSaveLoading:boolean = false;
  medicationListData:any = []
  count:any = 0;
  drugList:any = [];
  tempDrugList:any = []
  templateDugList:any = [];
  templateName:any = ""
  templateSaveButton:boolean =false
  filteredMedicines:any = []
  tempMedicine:any = [];
  searchLoading = false;
  printButtonLoading:boolean = false;
  printPrescriptionObject:any;
  drugSearch = new Subject<string>();
  print_option:any = ['primary_checkups','medications'];
  diagnosisTemplateList:any = []
  currentTab:any;
  templateDiagnosis:any = [];

  medicineArrayObject:any = {
    diagnosis:[],
  }
  medicineObject:any = {
    drug_type:"",
    drug_name:"",
    dosage:[]
  }


  layoutLoading:boolean = false;
  current_app_id:any;
  current_patient_id:any
  selectOrgan:any = "";
  tempName:any = "";
  temp_dosage:any = [];
  clearArray:boolean = false;

  @ViewChild('myCanvas')
  canvas!: ElementRef<HTMLCanvasElement>;
  context!: CanvasRenderingContext2D;

  constructor(public route:ActivatedRoute,private ipdChartData:IpdchartService, private toast:ToastrService, public dialog: MatDialog,private modalService: NgbModal,private api:TemplateService,private localStorage:LocalStorageService)
  {
    this.route.params.subscribe(params =>
      {
        this.current_patient_id = params['id']
        this.current_app_id = params['ipd_app_id']
      })
   this.medicineDb = db;

  this.drugSearch.pipe(
    debounceTime(300)
).subscribe(value => {
  if(typeof value == "string")
  {
    //console.log(value.toLowerCase())
    this.filteredMedicines =  this.tempMedicine.filter(
      (item:any) => item.medicine_name.toLowerCase().includes(value.toLowerCase())
    )
  }


})

  }



 getDosageValue(data:any)
 {
  console.log(data)
  this.temp_dosage = data;
 }
  ngAfterViewInit()
{

  this.getPatientMedicationData();
}

getDosageInWord(item:any)
{
  console.log(item)

}
addDiagnosis(item:any,organ:any)
{

  let existingArray = []
  let value:any = organ+" "+item
  this.tempName += item+",";
  this.templateName = this.removeLastComma(this.tempName);
  this.templateDiagnosis.push(item);
   existingArray = this.medicineArrayObject.diagnosis || [];
   this.medicineArrayObject.diagnosis = existingArray.concat(value);

   //console.log( this.medicineArrayObject.diagnosis)
   this.getDosageInWord(this.medicineArrayObject.diagnosis)

   this.getDiagnosisMedicineTemplate();

}
removeLastComma(str:any) {
  return str.replace(/,\s*$/, "");
}

selectOrganFn(event:any)
{
  this.selectOrgan = event.target.value;
}
onKeyUp(event: any): void {
  const inputValue: string = event.target.value;
  if (event.code === 'Backspace' && !inputValue) {
    this.removeTag();
    return;
  } else {
    if (event.code === 'Comma' || event.code === 'Enter') {
      this.addDiagnosis(inputValue,"");
      event.target.value = ""
    }
  }
}
removeTag(): void {
  this.medicineArrayObject.diagnosis.splice(-1);
  this.templateDiagnosis.splice(-1)
  this.templateName = "";
  this.templateDiagnosis.forEach((element:any) => {
    this.tempName += element+",";
  });
  this.templateName = this.removeLastComma(this.tempName);
}

  displayWith(value: any) {
    return value?.medicine_name;
  }

  OpenNewMedication(content:any)
  {
    this.getMedicationTemplate()
    this.modalService.open(content,  {  size:'xl', scrollable: false,ariaLabelledBy: 'modal-basic-title' });

  }
  clearData()
  {
    this.modalService.dismissAll();
    this.medicineObject = {
      drug_type:"",
      drug_name:"",
      dosage:[]
    }
  }
  fnOnDrugSearch(event:any)
  {
    this.drugSearch.next(event);
  }
  copyTemplate(item:any)
  {
    this.drugList =  [...this.drugList , ...item.data] ;

  }
  addDrug()
  {

  if(this.medicineObject.drug_type=="")
  {
   this.toast.error("Select Drug Type")
  }
  else if(this.medicineObject.drug_name=="")
  {
   this.toast.error("Select Drug Name")
  }
  else
  {
    console.log(this.medicineObject.drug_name)
    if(typeof this.medicineObject.drug_name == 'string'){
      this.medicineObject.drug_name = {medicine_name: this.medicineObject.drug_name,composition: ""}}

      if(this.medicineObject.drug_type==="CAPSULE" || this.medicineObject.drug_type==="TABLET" || this.medicineObject.drug_type==="INJECTION")
      {

      // let repetition = (this.medicineObject.frequency.match(/1/g) || []).length || 0;
      // let days = this.countOccurrences(this.medicineObject.duration)
      // let qty = repetition * days * this.medicineObject.duration_count;
      // this.medicineObject.quantity = qty;
      }


     console.log(this.temp_dosage)
     this.medicineObject.dosage = this.temp_dosage;

    this.drugList.push(this.medicineObject)

    this.medicineObject = {
     drug_type:this.medicineObject.drug_type,
     drug_name:"",
     dosage:[]
   }
   this.clearArray = true;
    setTimeout(() => {
      this.clearArray = false;
    }, 100);

   //this.temp_dosage = [];
  }

  }
   countOccurrences(str:any) {
    let count = 0;
    if(str === "day(s)"){
      count = 1
    }
    else if(str === "weeks(s)"){
      count = 7
    }
    else if(str === "month(s)"){
      count = moment().daysInMonth()
    }
    return count;
  }
  FilterByDrugType(obj:any)
{
  let value = obj.target.value;
  this.filteredMedicines = [];
  this.filteredMedicines = this.medicineDb.filter(
    (item:any) => item.medicine_name.toLowerCase().match(value.toLowerCase())
  );
  this.tempMedicine = [];
  this.tempMedicine =  this.filteredMedicines;

}

  removeDrug(i:any)
  {
    this.drugList.splice(i, 1)
    this.clearArray = true;
    setTimeout(() => {
      this.clearArray = false;
    }, 100);
    
  }
  saveMedication()
  {
    if(this.id)
    {
     this.btnSaveLoading = true;
     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       ipd_app_id:this.current_app_id,
       id:this.id,
       discharge_medication:this.drugList,
       }


      this.ipdChartData.updateIpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnSaveLoading = false;
           this.toast.success("Data Saved successfully...")
          this.clearData()
         }
       },error:(e)=>{
         this.btnSaveLoading = false;
       }
      })
    }
    else{
      this.btnSaveLoading = true;
     let param = {
       hospital_id:this.localStorage.getHospitalId(),
       center_id:this.localStorage.getCenterId(),
       patient_id:this.current_patient_id,
       ipd_app_id:this.current_app_id,
       discharge_medication:this.drugList,
       }


      this.ipdChartData.updateIpdChartData(param).subscribe({
       next:(res)=>{
         if(res)
         {
           this.btnSaveLoading = false;
           this.toast.success("Data Saved successfully...")
           this.clearData()

         }
       },error:(e)=>{
         this.btnSaveLoading = false;
       }
      })
    }

  }
  getMedicationTemplate()
  {
    let param = {
      where:{
      specialty: this.localStorage.getSpecialty(),
      }
    }
    // this.opdChartAPI.getMedicationTemplate(param).subscribe({
    //   next:(res)=>{
    //     if(res)
    //     {
    //       this.templateDugList = res;
    //     }
    //   },
    //   error:(e)=>{

    //   }
    //  })
  }
  SaveMedicationTemplate()
  {
     if(this.templateName)
     {
      let param = {
        title:this.templateName,
        data:this.drugList,
        diagnosis:this.templateDiagnosis,
        hospital_id:this.localStorage.getHospitalId(),
        specialty: this.localStorage.getSpecialty(),
      }
      this.templateSaveButton = true;
        //  this.opdChartAPI.createMedicationTemplate(param).subscribe({
        //   next:(res)=>{
        //     if(res)
        //     {
        //       //this.toast.success("Template Added Successfully")
        //       this.templateSaveButton = false;
        //       //this.  getMedicationTemplate()
        //     }
        //   },
        //   error:(e)=>{
        //     this.templateSaveButton = false;
        //   }
        //  })
     }
     else
     {
      //this.toast.error("Template should not blank")
     }

  }

  getDiagnosisTemplate()
  {

    let param = {
      where:{
        specialty:this.localStorage.getSpecialty()
      }
    }
    this.api.getDiagnosisTemplate(param).subscribe({
      next:(res)=>{
        if(res)
        {
          this.diagnosisTemplateList = res;
        }
      },
      error:(e)=>{

      }
    })
  }

  getPatientMedicationData()
  {
  // console.log(this.app_data)
   let param = {
     where:{
    hospital_id:this.localStorage.getHospitalId(),
     center_id:this.localStorage.getCenterId(),
     patient_id:this.current_patient_id,
     },
     attributes:['id','ipd_app_id','hospital_id','patient_id','center_id','discharge_medication']
   }
   this.ipdChartData.getAllIpdChartData(param).subscribe({
     next:(res)=>{
      if(res)
      {
        //this.count = res.filter((item:any)=>{return item.medications}).length;
        //this.medicationListData = res.filter((item:any)=>{return item.ipd_app_id != this.app_data.ipdappointment.ipd_app_id && item.discharge_medication });
        let existingObject  = res.find((item:any)=>{return item.ipd_app_id == this.current_app_id})
        if(existingObject!=undefined)
        {
          this.id =  existingObject.id || undefined;
          if(existingObject.discharge_medication){
             this.currentTab = existingObject;
             this.drugList = existingObject.discharge_medication;
          }
        }

        this.layoutLoading = true;

      }

     },
     error:(e)=>{

     }
   })
  }
  getDiagnosisMedicineTemplate()
  {
    let param = {
      hospital_id:this.localStorage.getHospitalId(),
      diagnosis:this.templateDiagnosis
    }
    this.api.getMedicinesDiagnosisTemplate(param).subscribe({
      next:(res) =>
      {
        if(res)
        {
          this.drugList = res.data;
        }
      }
    })
  }


}
