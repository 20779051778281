import { Input,Component } from '@angular/core';

@Component({
  selector: 'app-header-info',
  templateUrl: './header-info.component.html',
  styleUrls: ['./header-info.component.scss']
})
export class HeaderInfoComponent {
  @Input() patient: any;
  @Input() titleOfHead: any;

  redirectRouterLink: string = '';

  ngOnInit() {

    if(this.titleOfHead.title == 'OPD'){
      this.redirectRouterLink = 'out-patient';
    }else if(this.titleOfHead.title == 'IPD'){
      this.redirectRouterLink = 'in-patient';
    }
    else if(this.titleOfHead.title == 'Appointments'){
      this.redirectRouterLink = 'appointments';
    }
  }

}
